import React, { useEffect, useState, useCallback, useMemo } from 'react'
import Select from "react-select"
import { Link } from "react-router-dom";
import makeAnimated from "react-select/animated"
import { Card, Col, Label, Row, Input, Modal, Form, ModalBody, ModalHeader, FormFeedback } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../components/Common/withRouter'
import {
  getPermissons as onGetPermissons,
  getRoles as onGetRoles,
  addRole as onAddRole,
  updateRole as onUpdateRole,
  deleteRole as onDeleteRole,
  searchRole as onSearchRole
} from "../../../../slice/thunks"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import ActionButton from '../../../../components/Global/ActionButton'
import * as Yup from "yup"
import { useFormik } from 'formik'
import DeleteModal from '../../../../components/Global/DeleteModal'
import TableContainer from '../../../../components/Common/TableContainer'
import GlobalLoader from '../../../../components/Common/GlobalLoader';

const Roles = ({t = (key) => key, isActive = true}) => {
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      roles: setting.roles,
      permissions: setting.permissions,
      role: setting.role,
      role_success: setting.role_success,
      error: setting.error
    })
  )
  const { roles, permissions, role, role_success, error } = useSelector(SettingsProperties)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentRole, setCurrentRole] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [permissionsOptions, setPermissionOptions] = useState([])
  const [selectedPermissions, setselectedPermissions] = useState(null)
  const permission = JSON.parse(localStorage.getItem("perm"))
  const [isLoading, setIsLoading] = useState(true)
  const [errorPerm, setErrorPerm] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      roleName: (currentRole && currentRole.roleName) || '',
      permissions: (currentRole && currentRole.permissions) || ''
    },
    validationSchema: Yup.object({
      roleName: Yup.string().required(t("you_need_provide_name"))
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateRoleForm = {
          id: currentRole && currentRole.id,
          roleName: values.roleName,
          permissions: selectedPermissions.map((permission)=> permission.value)
        }
        const id = currentRole.id

        dispatch(onUpdateRole({ data: updateRoleForm, id: id }))

      } else {
        const newRoleForm = {
          roleName: values.roleName,
          permissions: selectedPermissions.map((permission)=> permission.value)
        }

        dispatch(onAddRole(newRoleForm))
      }
    },
  })

  const loadRoles = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetRoles({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetRoles())
    }
  },[dispatch]) 

  const searchRoles = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onSearchRole({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onSearchRole({term: term, page: 1, size: 10}))
    }
  },[dispatch])

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false)
      validation.resetForm()
      setCurrentRole(null)
      setErrorPerm(false)
    } else {
      setModal(true);
    }
  }, [modal, validation])

  useEffect(() =>{
    if (isActive) {
      dispatch(onGetPermissons())
      loadRoles(1, 10)
    }
  },[dispatch, isActive, loadRoles])

  useEffect(() => {
    if (roles && roles.page) {
      setIsLoading(false)
    }
  },[roles])

  useEffect(() =>{
    if (permissions && permissions.length > 0) {
      let permitions_options = []
      const array_groupes = []
      permissions.map((permission) =>( 
        array_groupes.includes(permission.groupName) ? null : array_groupes.push(permission.groupName)
      ))

      for (const groupe of array_groupes) {
        const permission_list = permissions.filter((row) => row.groupName === groupe).map( permission => {
          return { label: permission.permissionName, value: permission.permissionRef }
        })
        let rowGroupe = { label: groupe, options: permission_list}
        permitions_options.push(rowGroupe);
      }
      setPermissionOptions(permitions_options)
    }
  },[permissions])

  useEffect(() => {
    if (isActive) {
      if (role_success && !error) {
        setIsEdit(false)
        validation.resetForm()
        setCurrentRole(null)
        setselectedPermissions(null)
        setModal(false)
        loadRoles()
      }
    }
  }, [role_success, error, role])

  useEffect(() => {
    if (selectedPermissions && selectedPermissions.length > 0) {
      setErrorPerm(false)
    }
  },[selectedPermissions])

  const onClickDelete = (role) => {
    setCurrentRole(role);
    setDeleteModal(true);
  }

  const handleSelectRole = useCallback((arg) => {
    const selectedRole = arg
    setCurrentRole({
      id: selectedRole.id,
      roleName: selectedRole.roleName,
      permissions: selectedRole.permissions,
    })

    const rolePermission = selectedRole.permissions.map(permission => {
      return { label: permission.permissionName, value: permission.permissionRef }
    })
    
    setselectedPermissions(rolePermission)
    setIsEdit(true);
    toggle();
  },[toggle])

  const handleOpen = () => {
    setIsEdit(false)
    setselectedPermissions()
    toggle()
  }

  function handleSelectPermissions(selectedPermissions) {
    setselectedPermissions(selectedPermissions)
  }

  function handleDeleteRole() {
    if (currentRole) {
      dispatch(onDeleteRole({ data: currentRole.roleName, id: currentRole.id }))
      dispatch(onGetRoles())
      setDeleteModal(false)
    }
  }

  function capitalizeName(name) {
    if (!name) return '';
    return name
        .split(' ') 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
        .join(' '); 
  }

  const columns = useMemo(
    () => [
      {
        header: t("role"),
        accessorKey: "roleName",
        style: "text-start button",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{capitalizeName(cell.row.original.roleName)}</span>
        }
      },
      {
        header: t("action"),
        style: "text-end",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                  {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("UPDATE_ROLES")) ?
                    <li className="list-inline-item edit" title="Edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => { const roleData = cellProps.row.original; handleSelectRole(roleData); }}
                      >

                        <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                      </Link>
                    </li>:<></>
                  }
                  {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("DELETE_ROLES")) ?
                    <li className="list-inline-item" title="Remove">
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => { const roleData = cellProps.row.original; onClickDelete(roleData); }}
                      >
                        <img src="../assets/images/delete.png" alt=""></img>
                      </Link>
                    </li>:<></>
                  }
                </ul>
            </div>
          );
        },
      },
    ],
    [handleSelectRole, t]
  );

  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <DeleteModal  show={deleteModal} onDeleteClick={handleDeleteRole} onCloseClick={() => setDeleteModal(false)}/>
          <Card>
            <div className="head d-flex justify-content-end p-2">
              {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("ADD_ROLES")) ?
                <ActionButton title={t("create_new_role")} img="../assets/images/user.png" action={handleOpen} />
                :<></>
              }
            </div>
            <div className="card-body pt-3">
              <div>
                <TableContainer
                  columns={columns}
                  data={((roles && roles.items) || [])}
                  isGlobalFilter={true}
                  customPageSize={10}
                  divClass="table-card-ward"
                  tableClass="align-middle table-sm"
                  theadClass="thead-light table-light"
                  tableSize={ roles && roles.size }
                  tablePage={ roles && roles.page }
                  tableTotal= { roles && roles.total_items }
                  tableTotalPage= { roles && roles.total_page }
                  onNextPage= {loadRoles}
                  onSearch= {searchRoles}
                  SearchPlaceholder={t("search_for_roles")}
                />
              </div>
            </div>

            <Modal id='showModal' size='md' isOpen={modal || false} toggle={toggle || (() => {})} backdrop={'static'} centered>
              <ModalHeader className="bg-light p-3" toggle={toggle || (() => {})}>
                {!!isEdit ? t("edit_role") : t("create_new_role")}
              </ModalHeader>
              <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}>
                <ModalBody>
                  <input type="hidden" id="id-field" />
                  <Row>
                    <Col xl={12} sm={12}>
                      <div className="mb-3">
                        <Label
                          htmlFor="roleName-field"
                          className="form-label"
                        >
                          {t("roleName")}<span className='text-danger'>*</span>
                        </Label>
                        <Input
                          name="roleName"
                          id="roleName-field"
                          className="form-control"
                          autoComplete="off" 
                          placeholder={t("enter_role_name")}
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.roleName || ""}
                          invalid={ validation.touched.roleName && validation.errors.roleName }
                        />
                        {validation.touched.roleName && validation.errors.roleName ? (
                          <FormFeedback type="invalid">{validation.errors.roleName}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl={12} sm={12}>
                      <div className='mb-3'>
                      <Label
                          htmlFor="permissions-field"
                          className="form-label"
                        >
                          {t("permissions")}<span className='text-danger'>*</span>
                        </Label>
                        <Select
                          name='permissions'
                          id='permissions-field'
                          autoComplete="off" 
                          value={selectedPermissions}
                          isMulti={true || false}
                          isClearable={true || false}
                          onBlur={validation.handleBlur}
                          onChange={(e) => {
                              handleSelectPermissions(e);
                          }}
                          options={permissionsOptions}
                          closeMenuOnSelect={true || false}
                          components={animatedComponents}
                        />
                        { errorPerm ?
                          <span className='text-danger' style={{ fontSize: 13 }}>{t("you_need_select_permission")}</span>
                          :<></>
                        }
                      </div>
                    </Col>
                  </Row>
                </ModalBody>
                <div className='p-3'>
                  <Row className="align-items-center">
                    <div className="col-sm">
                      <span className='text-danger align-middle'>* : required fields</span>
                    </div>
                    <div className="col-sm-auto">
                      <div className="hstack gap-2 justify-content-end">
                        <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); setCurrentRole(null); validation.resetForm(); setIsEdit(false); setErrorPerm(false) }}> {t("close")} </button>
                        <button type="submit" className="btn btn-success" onClick={() => { selectedPermissions && selectedPermissions.length > 0 ? setErrorPerm(false) : setErrorPerm(true) }}> {!!isEdit ? t("update") : t("save")} </button>
                      </div>
                    </div>
                  </Row>
                </div>
              </Form>
            </Modal>
          </Card>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Roles))