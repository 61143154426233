import { useCallback, useEffect, useMemo, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import withRouter from "../../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { getGuarantors, addGuarantor, updateGuarantor } from "../../../helpers/backend_helper";
import { Col, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup"
import { toast } from "react-toastify";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import SinpleActionButton from "../../../components/Global/SinpleActionButton";


const Guarantor = (props) => {

    const [guarantors, setGurantors] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [guarantor, setGuarantor] = useState(null);
    const [message, setMessage] = useState(null);
    const [success, setSuccess] = useState(false);

    const loadGuarantors = useCallback((page, size) => {
        if(page && size) {
            getGuarantors({page: page, size: size}, setGurantors)
        } else {
            getGuarantors({page: 1, size: 10}, setGurantors)
        }

    },[setGurantors]);

    const form = useFormik(({
        enableReinitialize: true,
        initialValues: {
            fullName: guarantor?.fullName,
            phone: guarantor?.phone,
            position: guarantor?.position,
            address: guarantor?.address,
        },
        validationSchema: Yup.object({
            fullName: Yup.string().min(3, 'Name must have at least 3 characters').required('Name is required'),
            phone: Yup.string().matches(/\d{9,}/,'at least 9 digits are allowed').required('Phone number is required'),
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    }));

    const alert = useCallback((message, status) => {
        if(status) {
            toast.success(message, { autoClose: 5000 });
        } else {
            toast.error(message, { autoClose: 5000 })
        }
    }, [])

    const handleSubmit = useCallback((values) => {
        const params = {
            fullName: values.fullName,
            phone: values.phone,
            address: values.address,
            position: values.position,
        };

        if(guarantor) {
            updateGuarantor(guarantor.id, params, setMessage, setSuccess);
        } else {
            addGuarantor(params, setMessage, setSuccess);
        }
    },[guarantor, setMessage, setSuccess]);


    const handleOpenModal = useCallback(() => {
        if(openModal === true){
            setGuarantor(null);
            form.resetForm();
;        }
        setOpenModal(!openModal)
    }, [openModal, form]);

    const selectGuarantor = useCallback((d) => {
        setGuarantor(d);
        handleOpenModal();
    },[setGuarantor, handleOpenModal]);

    useEffect(() => {
        if(message) {
            alert(message, success);
            setMessage(null);
        }

        if(success) {
            setSuccess(false);
            setMessage(null);
            setOpenModal(false);
            form.resetForm();
            loadGuarantors();
        }
    },[alert, message, success, form, loadGuarantors, setSuccess, setMessage]);


    useEffect(() => {
        loadGuarantors()
     },[loadGuarantors])

    function capitalizeName(name) {
        if (!name) return '';
        return name
            .split(' ') 
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
            .join(' '); 
    }

    const columns = useMemo(
        () => [
          {
            header: props.t("guarantor_full_name"),
            enableColumnFilter: false,
            accessorKey: "fullName",
            cell: (cell) => {
                return <span>{capitalizeName(cell.row.original.fullName)}</span>
            }
          },
          {
              header: props.t("guarantor_phone"),
              enableColumnFilter: false,
              accessorKey: "phone",
          },
          {
            header: props.t("guarantor_position"),
            accessorKey: "position",
            enableColumnFilter: false,
          },
          {
            header: props.t("guarantor_address"),
            accessorKey: "address",
            enableColumnFilter: false,
          },
          {
            header: props.t("action"),
            enableColumnFilter: false,
            cell: (cellProps) => {
                const data = cellProps.row.original;
                return <span>
                    {
                        <ul className="list-inline hstack mb-0">
                            <li className="list-inline-item edit">
                                <Link to='#' className="text-danger d-inline-block remove-item-btn" onClick={() => {selectGuarantor(data);}}>
                                    <img src="../assets/images/edit.png" alt="edit" />
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to="#" className="text-danger d-inline-block remove-item-btn" onClick={() => {selectGuarantor(data);}}>
                                    <img src="../assets/images/delete.png" alt="delete" />
                                </Link>
                            </li>
                        </ul>
                    }
                </span>
            }
          }
        ],
        [props, selectGuarantor]);

    return (
        <>
            <div className='patients-list py-4 px-2'>
                <div className='patient-bill-and-discharge-sec'>
                        <div className="container">
                                <div className="row list-patients d-flex justify-content-between">
                                    <div className="col-lg-6">
                                        <BreadCrumb title={props.t("hospital_staff")} pageTitle={props.t("settings")} />
                                    </div>
                                    <div className="col-sm-auto new-mode-btn">
                                        <SinpleActionButton title={props.t("add_person")} img="../assets/images/user.png" action={handleOpenModal} />
                                    </div>
                                </div>

                            <div className="payment-mode-lists">
                                <div className="container">
                                    <div className="row justify-content-between">
                                        <div className="card table-card">
                                            <TableContainer
                                                columns={columns}
                                                data={((guarantors && guarantors.items) || [])}
                                                isGlobalFilter={true}
                                                customPageSize={10}
                                                divClass="table-responsive"
                                                tableClass="table"
                                                theadClass="table-light"
                                                tdClass="td"
                                                tableSize={guarantors && guarantors.size}
                                                tablePage={guarantors && guarantors.page}
                                                tableTotal={guarantors && guarantors.total_items }
                                                tableTotalPage={guarantors && guarantors.total_page }
                                                onNextPage= {loadGuarantors}
                                                onSearch= {() => {}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <Modal isOpen={openModal} id="guarantor-modal" toggle={openModal} backdrop={'static'} size='sm' centered>
                <ModalHeader>
                    {!guarantor ? 'Add guarantor' : 'Edit guarantor'}
                </ModalHeader>
                <ModalBody>
                    <form className="guarantor-from" onSubmit={(e) => { e.preventDefault(); return false;}}>
                        <Row>
                            <Col xl={12} sm={12} className="form-group">
                                <Label className="form-label">First name : <span className='text-danger'> * </span></Label>
                                <Input className="form-control" name="fullName" autoComplete={false} required
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    value={form.values.fullName || ""}
                                    invalid={
                                        form.touched.fullName && form.errors.fullName ? true : false
                                    }
                                />
                                {form.errors.fullName ? (
                                    <FormFeedback type="invalid">{form.errors.fullName}</FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} sm={12} className="form-group">
                                <Label className="form-label">Phone number : <span className='text-danger'> * </span></Label>
                                <Input className="form-control" name="phone"  autoComplete={false} required
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    value={form.values.phone || ""}
                                    invalid={
                                        form.touched.phone && form.errors.phone ? true : false
                                    }
                                />
                                {form.touched.phone && form.errors.phone ? (
                                    <FormFeedback type="invalid">{form.errors.phone}</FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} sm={12} className="form-group">
                                <Label className="form-label">Address : </Label>
                                <Input className="form-control" name="address"  autoComplete={false}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    value={form.values.address || ""}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} sm={12} className="form-group">
                                <Label className="form-label">Position : </Label>
                                <Input className="form-control" name="position"  autoComplete={false}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    value={form.values.position || ""}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <div className='p-3'>
                                <div className="col-sm-auto">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="button" className="btn btn-sm btn-secondary" onClick={() => { handleOpenModal() }}> {props.t("close")} </button>
                                        <button type="button" className="btn btn-sm btn-success" onClick={() => {form.handleSubmit()}}>
                                            {props.t("save")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
};


export default withRouter(withTranslation()(Guarantor))