import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import { useSelector, useDispatch } from "react-redux"
import moment from 'moment'
import * as Yup from "yup"
import { useFormik } from 'formik'
import Select from "react-select"
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'

import {
    getHospitalisationList as onGetHospitalisationList,
    leaveBed as onLeaveBed
  } from "../../../slice/thunks"
  import TableContainer from '../../../components/Common/TableContainer'
import GlobalLoader from '../../../components/Common/GlobalLoader'

const AdmitPatients = (props) => {
    const dispatch = useDispatch()
    const selectLayoutState = (state) => state.Outputs;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (output) =>({
        hospitalisationList: output.hospitalisationList,
        bed_leave_success: output.bed_leave_success,
        error: output.error
        })
    )
    const { hospitalisationList, bed_leave_success, error } = useSelector(SettingsProperties)
    const [currentPatient, setCurrentPatient] = useState(null)
    const [modal, setModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
  

    const loadHospitalisationList = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
        dispatch(onGetHospitalisationList({page: pageNumber, size: pageSize}))
        } else {
        dispatch(onGetHospitalisationList())
        }
    })

    const searchPatients = useCallback((term, pageNumber, pageSize) => {
        
        if (pageNumber && pageSize) {
          dispatch(onGetHospitalisationList({term: term, page: pageNumber, size: pageSize}))
        } else {
          dispatch(onGetHospitalisationList({term: term, page: 1, size: 10}))
        }
    })

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setCurrentPatient(null);
            validation.resetForm()
        } else {
            setModal(true);
        }
    }, [modal])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            releaseDate: (currentPatient && currentPatient.releaseDate) || '',
        },
        validationSchema: Yup.object({
            releaseDate: Yup.string().required(props.t("you_need_provide_date")),
        }),
        onSubmit: (values) => {
            const leaveForm = {
                releaseDate: values.releaseDate,
            }
            const id = currentPatient.id
            dispatch(onLeaveBed({id: id, data: leaveForm}))
        },
    })

    useEffect(() =>{
        if (props.isActive) {
            loadHospitalisationList()
        }
    },[props.isActive])

    useEffect(() => {
        if (hospitalisationList && hospitalisationList.page) {
          setIsLoading(false)
        }
    },[hospitalisationList])

    useEffect(() => {
        if (bed_leave_success && !error) {
            toggle()
            loadHospitalisationList()
        }
    }, [bed_leave_success, hospitalisationList])

    const handleSelectPatient = useCallback((arg) => {
        setCurrentPatient(arg)
        toggle()
    },[toggle])

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function capitalizeName(name) {
        if (!name) return '';
        return name
            .split(' ') 
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
            .join(' '); 
    }

    const columns = useMemo(
        () => [
        {
            header: props.t("patient_id"),
            accessorKey: "id",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
            return <span>
                {(cell.row.original.patient !== null )? "#"+cell.row.original.patient.patientRef : ''}
            </span>
            }
        },
        {
            header: props.t("patient_name"),
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>
                {capitalizeName((cell.row.original.patient !== null )? cell.row.original.patient.firstName+" "+cell.row.original.patient.lastName : '')} 
                </span>
            }
        },
        {
            header: props.t("ward"),
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
            return <span>
                {(cell.row.original.bed !== null )? cell.row.original.bed.wards.wardsName : ''}
            </span>
            }
        },
        {
            header: props.t("bed_no"),
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
            return <span>
                {(cell.row.original.bed !== null )? cell.row.original.bed.bedNumber : ''} 
            </span>
            }
        },
        {
            header: props.t("type_of_patient"),
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>
                {(cell.row.original.patient !== null )? cell.row.original.patient.patientType.typeName : ''} 
                </span>
            }
        },
        {
            header: props.t("date"),
            accessorKey: "entryDate",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return (
                    <span>
                        { moment(cell.row.original.entryDate).format('DD/MM/YYYY') }
                    </span>
                    )
            }
        },
        {
            header: props.t("admit_patient"),
            style: "button",
            cell: (cellProps) => {
            return (
                <div className="d-flex">
                    <ul className="list-inline hstack mb-0">
                    <li className="list-inline-item edit" title="Edit">
                        <>
                            <Link 
                                to="#" 
                                className="btn save-del btn-danger " style={{fontSize: "11px" }}
                                onClick={() => { const admin = cellProps.row.original; handleSelectPatient(admin); }}
                            >
                            {props.t("leave_bed") }
                            </Link>
                        </>
                    </li>
                    </ul>
                </div>
            );
            },
        },
        ],
        [handleSelectPatient]
    );

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div>
                    <div className="patient-bill-and-discharge-main-sec p-3">
                        <TableContainer
                            columns={columns}
                            data={((hospitalisationList && hospitalisationList.items) || [])}
                            isGlobalFilter={true}
                            customPageSize={10}
                            divClass="table-card-ward"
                            tableClass="align-middle"
                            theadClass="thead-light table-light"
                            tableSize={ hospitalisationList && hospitalisationList.size }
                            tablePage={ hospitalisationList && hospitalisationList.page }
                            tableTotal= { hospitalisationList && hospitalisationList.total_items }
                            tableTotalPage= { hospitalisationList && hospitalisationList.total_page }
                            onNextPage= {loadHospitalisationList}
                            onSearch= {searchPatients}
                            SearchPlaceholder={props.t("search_name_phone_id")}
                        />
                    </div>

                    <Modal id="showModal" size='sm' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                        <ModalHeader className='bg-light p-3' toggle={toggle}>
                            {props.t("patient_leave_bed")}
                        </ModalHeader>
                        <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <ModalBody>
                                <Row>
                                    <Col xl={12} sm={12}>
                                        <div className='mb-2 mt-1'>
                                            <Label htmlFor="releaseDate-field" className="form-label">
                                                {props.t("release_date")}<span className='text-danger'>*</span>
                                            </Label><br/>
                                            <Input 
                                                name="releaseDate" id='releaseDate-field' className="form-control" type="date"
                                                validate={{ required: { value: true } }} max={getTodayDate()} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.releaseDate || ""}
                                                autoComplete="off"releaseDate
                                                invalid={
                                                    validation.touched.releaseDate && validation.errors.releaseDate ? true : false
                                                } 
                                            />
                                            {validation.touched.releaseDate && validation.errors.releaseDate ? (
                                                <FormFeedback type="invalid">{validation.errors.releaseDate}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <div className='p-3'>
                                {/* <Row className="align-items-center">
                                <div className="col-sm">
                                    <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                                </div> */}
                                <div className="col-sm-auto">
                                    <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { toggle() }}> {props.t("close")} </button>
                                    <button type="submit" className="btn btn-success"> {props.t("save")}</button>
                                    </div>
                                </div>
                                {/* </Row> */}
                            </div>
                        </Form>
                    </Modal>
                </div>
            </>
        }

    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AdmitPatients))
