import { Link, useParams } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import { useReactToPrint } from 'react-to-print';
import moment from 'moment'
import {  Row, Col } from 'reactstrap'
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
    getAccountSummaryDetails as onGetAccountSummaryDetails,
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"
import TableContainerBase from "../../../components/Common/TableContainerBase"

const SummaryDetails = (props) => {
  const { id } = useParams()
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        summaryDetail: setting.summaryDetail,
      })
  );
  const { summaryDetail } = useSelector(SettingsProperties);
  const [isLoading, setIsLoading] = useState(true);

  const loadSummaryDetails = useCallback(() => {
    dispatch(onGetAccountSummaryDetails(id))
  })

  const printFn = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Account summary',
    copyStyles: true,
  });

  useEffect(() =>{
    loadSummaryDetails(id)
  },[dispatch])

  const handlePrint = useCallback(() => {
    printFn();
  }, [printFn]);

  useEffect(() => {
    if (summaryDetail) {
        setIsLoading(false)
    }
  },[summaryDetail])

  const columns = useMemo(
    () => [
      {
        header: props.t("no"),
        style: "cat",
        enableColumnFilter: false,
        cell: (cellProps) => {
          return <span>
              { cellProps.row.index + 1 }
          </span>;
        },
      },
      {
        header: props.t("date"),
        style: "cat",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{moment(cell.row.original.createdAt).format('DD / MM/ YYYY')}</span>
        }
      },
      {
        header: props.t("time"),
        style: "cat",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{moment(cell.row.original.createdAt).format('HH:mm')}</span>
        }
      },
      {
        header: props.t("patient"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.patient}</span>
        }
      },
      {
        header: props.t("guarantor"),
        accessorKey: "guarantor",
        style: "cat",
        enableColumnFilter: false,
      },
      {
        header: props.t("paid"),
        accessorKey: "paid",
        style: "type",
        enableColumnFilter: false,
      },
      {
        header: props.t("amount"),
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.amount}</span>
        }
      },
    ],
    [summaryDetail]
  );
  
  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="patients-list-content">
                    <div className="patients-list px-2 pb-5 ">
                        <div className="container-fluid ">
                            <div className="heading-parent-sec ">
                                <div className="heading-child-one-sec pt-4">
                                  <h6>
                                    <Link to={"/cashier/account-summary"}>
                                      <i className="fa-solid fa-chevron-left"></i>
                                      {props.t("summary_view_discharge")}
                                    </Link>
                                  </h6>
                                </div>
                                <div className="heading-child-two-sec pt-4">
                                    <SinpleActionButton title={props.t("print")} ico="fa-print" action={handlePrint} />
                                </div>
                            </div>
                            
                            <div className="card border-0 mx-2" ref={componentRef}>
                                  <Row className="m-3 hiden-print">
                                    <Col xl={6} sm={6}>
                                      <Row>
                                        {summaryDetail && summaryDetail.length > 0 ?
                                          <>
                                            <div>
                                              <p>{props.t("reg_no")}</p>
                                              <p>{props.t("hospital_name")}</p>
                                            </div>
                                            <h6><u>{props.t("full_name")}</u> : {summaryDetail[0].guarantor}</h6>
                                          </>
                                          : null
                                        }
                                      </Row>
                                    </Col>
                                    <Col xl={6} sm={6}></Col>
                                  </Row>
                                <div className="p-2">
                                  <TableContainerBase
                                    columns={columns}
                                    data={((summaryDetail && summaryDetail) || [])}
                                    isGlobalFilter={false}
                                    isAddUserList={false}
                                    customPageSize={10}
                                    divClass="card table-card table-responsive p-0"
                                    tableClass="table align-middle"
                                    theadClass="table-light text-muted"
                                    isCustomerFilter={false}
                                    SearchPlaceholder={props.t("search")}
                                  />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(SummaryDetails))
