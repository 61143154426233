import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import Select from "react-select"
import makeAnimated from "react-select/animated"
import moment from 'moment'

import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Input } from 'reactstrap';

import {
  getCashJournal as onGetCashJournal,
  getPaymentMode as onGetPaymentMode,
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"

const CashJournal = (props) => {
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        cashJournal: setting.cashJournal,
      })
    )
    const { cashJournal } = useSelector(SettingsProperties)
    const [isLoading, setIsLoading] = useState(true)
    const [ totalIn, setTotalIn] = useState(0)
    const [open, setOpen] = useState('1');
    const [ totalOut, setTotalOut] = useState(0)
    const journalGroup = [
      { label: props.t("cash_sales"), value: "cashSales"},
      { label: props.t("deposits"), value: "deposit"},
      { label: props.t("payout"), value: "payout"},
    ]
    const [selectedGroup, setSelectedGroup] = useState(null)
    const periodType = [
      { label: props.t("date"), value: "date"},
      { label: props.t("month"), value: "month"},
    ]
    const [selectedPeriodType, setSelectedPeriodType] = useState(periodType[0])
    const [selectedValue, setSelectedValue] = useState(moment().format("YYYY-MM-DD"))

    const loadCashJournal = useCallback(() => {
      dispatch(
        onGetCashJournal({
          group : selectedGroup ? selectedGroup.value : "",
          filterBy: selectedPeriodType ? selectedPeriodType.value : "",
          filterValue: 
            selectedPeriodType &&  selectedPeriodType.value === "date" ? selectedValue : moment(selectedValue, "YYYY-MM").format("YYYY-MM-DD")
        })
      )
    })

    const loadPaymentMode = useCallback(() => {
      dispatch(onGetPaymentMode())
    })

    const toggle = (id) => {
      setOpen(open === id ? '' : id);
    };

    useEffect(() => {
      loadPaymentMode()
    },[dispatch])

    useEffect(() => {
      if (cashJournal) {
        setIsLoading(false)
        let totIn = 0
        let totOut = 0
        cashJournal.forEach(element => {
          totIn = totIn+element.moneyIn
          totOut = totOut+element.moneyOut
        });
        setTotalIn(totIn)
        setTotalOut(totOut)
      }
    },[cashJournal])
  
    useEffect(() => {
        loadCashJournal()
    },[selectedGroup, selectedValue])

  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <div className="cash-journal-sec">
              <div className="container">
                  <div className="heading-parent-sec">
                      <div className="heading-child-one-sec">
                          <h6>{props.t("cash_journal")}</h6>
                      </div>
                      <div className="heading-child-one-sec">
                      </div>
                      <div className="heading-child-two-sec">
                          <div className="search-box-sec my-2">
                          </div>
                      </div>
                  </div>
                  <div className="filter-record pb-2 d-flex justify-content-start">
                      <div className="col-3 filter1 d-flex  align-items-center justify-content-start px-2">
                          <div style={{ width: '100%' }}>
                          <b>{props.t("filter_record")}: </b>
                            <Select
                                name='accountType'
                                id='accountType-field'
                                value={selectedGroup}
                                isMulti={false}
                                isClearable={true}
                                autoComplete="off" 
                                onChange={(e) => {
                                    setSelectedGroup(e);
                                }}
                                options={journalGroup}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                            />
                          </div>

                      </div>
                      <div className="col-3 filter1 d-flex  align-items-center justify-content-start px-2 ">
                          <div style={{ width: '100%' }}>
                          <b>{props.t("filter_by")}: </b>
                            <Select
                                name='accountType'
                                id='accountType-field'
                                value={selectedPeriodType}
                                isMulti={false}
                                isClearable={false}
                                autoComplete="off" 
                                onChange={(e) => {
                                    setSelectedPeriodType(e);
                                }}
                                options={periodType}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                            />
                          </div>
                      </div>
                      <div className="col-3 filter1 d-flex align-items-center justify-content-start px-2">
                            <div style={{ width: '100%' }}>
                              <b>{props.t("period")}: </b>
                              <Input
                                type={selectedPeriodType && selectedPeriodType.value === "date" ? "date" : "month"}
                                value={selectedValue}
                                onChange={(e) => {
                                  setSelectedValue(e.target.value)
                                }}
                              />
                            </div>
                      </div>
                  </div>

                  {/* <!-- table --> */}
                  <div className="cash-journal-sec-main-sec ">
                    <Accordion className="accordion" open={open} toggle={toggle}>
                      <AccordionItem>
                        <AccordionHeader targetId="1">
                          {props.t("cash_journal")}
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                              <div className="table-responsive">
                                  <table className="table mb-0">
                                      <thead className="table-light">
                                          <tr className="align-middle">
                                              <th scope="col" className="ps-3" style={{ width: "12%" }}>{props.t("date")}</th>
                                              <th scope="col" style={{ width: "12%" }}>{props.t("time")}</th>
                                              <th scope="col" style={{ width: "24%" }}>{props.t("description")}</th>
                                              <th scope="col" style={{ width: "13%" }}>{props.t("money_in")}</th>
                                              <th scope="col" style={{ width: "13%" }}>{props.t("money_out")}</th>
                                              <th scope="col" style={{ width: "15%" }}>{props.t("system_user")}</th>
                                              <th scope="col" style={{ width: "11%" }}>{props.t("ref")}.</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                        {cashJournal && cashJournal.map((row, index) => {
                                          return <tr key={index} className="align-middle" data-bs-toggle="modal" data-bs-target="#salesDropModal">
                                              <td className="ps-3">{moment(row.createdAt).format('DD / MM/ YYYY')}</td>
                                              <td>{moment(row.createdAt).format('HH:mm')}</td>
                                              <td>{row.description}</td>
                                              <td>{row.moneyIn}</td>
                                              <td className="red">{row.moneyOut}</td>
                                              <td>{row.systemUser}</td>
                                              <td>{row.ref}</td>
                                          </tr>
                                        })}
                                          <tr>
                                              <th colSpan="3">{props.t("total")}</th>
                                              <th>{totalIn}</th>
                                              <th colSpan="3" className="red">{totalOut}</th>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                        </AccordionBody>
                      </AccordionItem>
                    </Accordion>
                  </div>
              </div>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(CashJournal))
