import {React } from 'react'
import { withTranslation  } from 'react-i18next'
import logo from "../assets/images/HMS-Logo-1.png"
import avatar from "../assets/images/user-avatar.png"
import withRouter from "../components/Common/withRouter"
import { Link } from 'react-router-dom'

const Header = (props) => {

    return (
    <section className="main-header">
        <nav className="navbar navbar-expand-lg fixed-top">
            <div className="container-fluid">
                <button className="toggler">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <Link className="navbar-brand" to="/dashboard">
                    <img className="img-fluid logo" src={logo} style={{ width: "50px", }} />
                </Link>

                <div className="collapse navbar-collapse mx-5" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <div className="vl"></div>
                            <h6 className="welcome mx-4">{props.t("welcome_back")}</h6>
                        </li>

                    </ul>
                    <ul className="navbar-nav ms-auto">
                        {props.isCashier && props.cashDesk ?
                            <li>
                                <button
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={() => props.clickClose()}
                                >
                                    {props.t("close_cash_desk")}
                                </button>
                            </li>
                            :
                            <></>
                        }
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page">
                                <i className="fa-solid fa-bell"></i>
                            </a>
                        </li>
                        <li className="nav-item user-nav-item">
                            <a className="nav-link">
                                <img src={avatar} className="user-avatar-img" alt="avatar" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </section>
    )
}

export default withRouter(withTranslation()(Header));
