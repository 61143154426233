import React, { useEffect, useState } from 'react'
import withRouter from '../../Common/withRouter'
import { withTranslation } from 'react-i18next'
import { Input } from 'reactstrap'

function TransfertRow(props) {

    const [quantity, setQuantity] = useState(props.row.quantity)
    const [intQuantity, setInitQuantity] = useState(props.row.quantity)
    const initialStockQuantity = props.row.quantityInStock
    const [Stockquantity, setStockQuantity] = useState(initialStockQuantity)


    const handleBlur = (e, setValue) => {
        if (e.target.value === '' || e.target.value === '0' || e.target.value <= 1) {
            setValue(1);
        }
    };

    useEffect(() => {
        if(!props.isEdit) {
            setStockQuantity(initialStockQuantity - quantity); // Utilisation de la fonction de mise à jour pour éviter les mises à jour multiples
        } else {
            if ((quantity-intQuantity < 0) && (quantity-intQuantity != null) ) {
                setStockQuantity(initialStockQuantity - (quantity-intQuantity));
            } else {
                setStockQuantity(initialStockQuantity - (quantity-intQuantity));
            }
            
        }
    }, [quantity]);
    

    useEffect(() =>{
        if (quantity > 0) {
            if (props.isValidate) {
                let object = {
                    productId: props.row.productId,
                    name: props.row.name,
                }
                props.updateRow(object)
            } else {
                let object = {
                    productId: props.row.productId,
                    quantity: quantity,
                    quantityInRest: Stockquantity
                }
                props.updateRow(object)
            }
        }
    },[Stockquantity])

  return (
    < >
        <td className="text-center">{props.index+1}</td>
        <td>{props.row.name}</td>
        <td>{Stockquantity}</td>
        <td className="text-center">
            <Input
                type="number"
                className="form-control m-0"
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))} 
                onBlur={(e) => handleBlur(e, setQuantity)} 
                aria-label="Text input with checkbox" 
                disabled = {props.isWatch ? true : false} 
            />
        </td>
        {!props.isWatch ?
            <td className="text-center" onClick={() => props.deleteRow(props.row) } >
                <i className="fa-solid fa-trash-can delete-action-btn"></i>
            </td>
            :
            <></>
        }
    </>
  )
}

export default withRouter(withTranslation()(TransfertRow))
