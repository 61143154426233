import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../../Common/withRouter'

const GeneralDispensaryContent = (props) => {
  return (
    <Modal id='showModal' isOpen={props.isOpen || false} toggle={props.toggleDispensary} centered
      className="modal-dialog  modal-dialog-centered modal-xl" style={{ maxWidth: "1140px" }}
    >
      <ModalHeader className="bg-light p-3" toggle={props.toggleDispensary}>
      </ModalHeader>
          <div class="modal-body">
              <div class="gen-lab-rep-print">
                  <div class="d-flex justify-content-start m-0 " style={{ gap: "320px" }}>
                      <p class="m-0 p-0">{props.t("reg_no")}</p>
                      <p class="text-danger fw-bold m-0 p-0" style={{ padding: "0", fontSize: "22px" }}>{props.t("tax_invoice")}</p>
                  </div>
                  <div class="text-center m-0">
                      <div class="d-flex gap-2 justify-content-center m-0 align-items-center">
                          <img src="../assets/images/HMS-Logo-1.png" class="img-fluid" alt="" style={{ maxWidth: "50px", }} />
                          <h4 style={{ fontSize: "22px", color: "#d13e6a", fontWeight: "700", margin: "0", padding: "0" }}>
                          {props.t("hospital_name")}</h4>
                      </div>
                      <h5 style={{ color: "#45a049", fontWeight: "500" }}>{props.t("hospital_location")}</h5>
                      <div class="d-flex justify-content-center gap-3">
                          <p>{props.t("hospital_email")}</p>
                          <p>{props.t("hospital_contact")}</p>
                      </div>
                  </div>
                  <div class="m-0">
                      <table class="table table-borderless m-0">
                          <tbody style={{ borderTop: "1px solid black" }}>

                              <tr>
                                  <th style={{ width: "18%" }}>BILL NO.</th>
                                  <td> <b>658</b></td>
                                  <th>DATE</th>
                                  <td> <b> 09/02/2024</b></td>
                                 
                                  
                              </tr>
                              <tr>
                                <th>Name</th>
                                <td> <b> ALex Thomas</b></td>
                                  
                                  <th>Pr. By</th>
                                  <td>Dr. S. WATSON</td>
                              </tr>
                              <tr>
                                  <th>ADDRESS</th>
                                  <td>BIPINDI, CAMEROON, AFRICA</td>
                                  <th></th>
                                  <td></td>
                                

                              </tr>
                          </tbody>
                      </table>
                  </div>
                  <div class="m-0">
                    <table class="table table-borderless mb-4">
                        <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                            <th>S.NO.</th>
                            <th>QTY</th>
                            <th>PACK</th>
                            <th>DESCRIPTION</th>
                            <th>HSN CODE</th>
                            <th>BATCH</th>
                            <th>EXPIRY DATE</th>
                            <th>RATE</th>
                            <th>AMOUNT</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>10</td>
                            <td>10 TAB</td>
                            <td>MAHACEF 200 MG TAB</td>
                            <td>30043200</td>
                            <td>COAW061</td>
                            <td>06/25</td>
                            <td>103.93</td>
                            <td>103.93</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>1</td>
                            <td>1*10ML</td>
                            <td>NASIVION CLASSIC R10</td>
                            <td>2106</td>
                            <td>3328C84501</td>
                            <td>10/26</td>
                            <td>109.01</td>
                            <td>109.01</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>3</td>
                            <td>10 TAB</td>
                            <td>MONTICOPE TAB</td>
                            <td>30049069</td>
                            <td>C45W025</td>
                            <td>11/25</td>
                            <td>29.97</td>
                            <td>29.97</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>1</td>
                            <td>100ML</td>
                            <td>KHOKHI Z SYP</td>
                            <td>00</td>
                            <td>ZUKCUK-KHO</td>
                            <td>02/25</td>
                            <td>89.00</td>
                            <td>89.00</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>10</td>
                            <td>10 TAB</td>
                            <td>CALPOL TAB</td>
                            <td>30049069</td>
                            <td>EA23173</td>
                            <td>11/25</td>
                            <td>9.97</td>
                            <td>9.97</td>
                        </tr>
                    </table>
                </div>
                
                  <div class="m-0">
                      <table class="table table-borderless m-0">
                          <tr style={{ borderTop: "1px solid black" }}>
                              <td style={{ width: "10%" }}>INCL TAXES DETAILS: </td>
                              <td style={{ width: "60%" }}>203.20x12%= 24.38,<br />
                                  GST 0% AMT: 80.10</td>
                              <td style={{ width: "10%" }} >VAT: <b>$ 12.19</b></td>
                              <td style={{ width: "10%" }} >Gross <br />Less Dis</td>
                              <td style={{ width: "10%" }} >: $ 341. <br />: $ 33.87</td>

                          </tr>
                          <tr >
                              <td style={{ width: "10%" }}> </td>
                              <td style={{ width: "60%", visibility: "hidden" }} >
                                 </td>
                              <td style={{ width: "10%" }} ></td>
                              <td style={{ width: "10%" }} ></td>
                              <td style={{ width: "10%" }} ></td>

                          </tr>
                         
                       
                      </table>
                  </div>
                  <div class="m-0">
                      <table class="table table-borderless m-0">
                          <tr style={{ borderTop: "1px solid black" }}>
                              <td style={{ width: "10%" }}>E.& O.E. &nbsp; MATHEW  &nbsp;12:26pm</td>
                              <td style={{ width: "60%", textAlign: "left" }}></td>
                              <td style={{ width: "10%", visibility: "hidden" }}></td>
                              <td style={{ width: "10%", whiteSpace: "nowrap" }} ><b> Total Amnt.</b></td>
                              <td style={{ width: "10%" }} >: $ 308.01</td>

                          </tr>
                          <tr style={{ borderBottom: "1px solid black" }}>
                              <td style={{ width: "10%", whiteSpace:"nowrap" }}>USD: Three Hundred and Eight Only </td>
                           <td style={{ width: "60%" }}></td>
                              <td style={{ width: "10%" }} > </td>
                              <td style={{ width: "10%" }} >Net Amt. (R/O)</td>
                              <td style={{ width: "10%" }} >: $ 308.00</td>

                          </tr>
                         
                       
                      </table>
                  </div>
                  <div class="text-center">
                      <p class="text fw-bold m-0 p-0 " style={{ fontSize: "22px", color: "#d13e6a" }}>We Care We Protect</p>
                      <p class="fw-bold text-danger m-0 p-0">Book Your Free Home Blood Sample Collection On: <span
                              class="text-primary" style={{ fontSize: "16px" }}>+237 6 75 55 38 64</span></p>
                  </div>
              </div>
          </div>
    </Modal>
  )
}

export default withRouter(withTranslation()(GeneralDispensaryContent))
 