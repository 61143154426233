import React, { useCallback, useEffect, useState } from 'react';
import withRouter from '../../../components/Common/withRouter';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import HomeSalesList from './SalesCahier/HomeSalesList';
import SalesOnHold from './SalesOnHold';
import SalePatientPayment from './SalePatientPayment';
import PatientAccount from './PatientAccount/PatientAccount';
import Deposits from './Deposits';
import { getPrintSetting as onGetPrintSetting } from '../../../slice/thunks';
import { useParams } from 'react-router-dom';

const AllSales = (props) => {
    const { section } = useParams();
    const dispatch = useDispatch();

    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) => ({
            printSettings: setting.printSettings,
            error: setting.error,
        })
    );

    const { printSettings } = useSelector(SettingsProperties);
    const [currentTab, setCurrentTab] = useState(1);

    const loadPrintSettings = useCallback(() => {
        dispatch(onGetPrintSetting());
    }, [dispatch]);

    useEffect(() => {
        loadPrintSettings();
    }, [loadPrintSettings]);

    useEffect(() => {
        setCurrentTab(parseInt(section) || 1);
    }, [section]);

    const tabs = [
        { id: 1, title: props.t("home"), component: <HomeSalesList printSettings={printSettings} /> },
        { id: 2, title: props.t("sales_on_hold"), component: <SalesOnHold printSettings={printSettings} /> },
        { id: 3, title: props.t("patients_accounts"), component: <PatientAccount printSettings={printSettings} /> },
        { id: 4, title: props.t("payments"), component: <SalePatientPayment printSettings={printSettings} /> },
        { id: 5, title: props.t("deposits"), component: <Deposits printSettings={printSettings} /> },
    ];

    return (
        <div className="container-fluid pb-2 px-4">
            {/* TABS */}
            <ul className="nav nav-tabs pt-4" id="myTab" role="tablist" style={{ borderBottom: "1px solid #A2A1A833" }}>
                {tabs.map(tab => (
                    <li className="nav-item" role="presentation" key={tab.id} onClick={() => setCurrentTab(tab.id)}>
                        <button
                            className={`nav-link ${currentTab === tab.id ? "active" : ""}`}
                            id={`${tab.title}-tab`}
                            type="button"
                            role="tab"
                            aria-controls={`${tab.title}-tab-pane`}
                            aria-selected={currentTab === tab.id}
                        >
                            <i className="fa fa-user ms-1 me-2" aria-hidden="true"></i>
                            {tab.title}
                        </button>
                    </li>
                ))}
            </ul>

            {/* TABS CONTENT */}
            <div className="tab-content" id="myTabContent">
                {tabs.map(tab => (
                    <div
                        key={tab.id}
                        className={`tab-pane fade ${currentTab === tab.id ? "show active" : ""}`}
                        id={`${tab.title}-tab-pane`}
                        role="tabpanel"
                        aria-labelledby={`${tab.title}-tab`}
                        tabIndex="0"
                    >
                        {React.cloneElement(tab.component, { isActive: currentTab === tab.id })}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default withRouter(withTranslation()(AllSales));
