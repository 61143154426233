import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from "yup"
import { useFormik } from 'formik'
import withRouter from '../../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate } from "react-router-dom"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { Col, Input, Label, Row , Card, Form, FormFeedback, Modal, ModalBody, ModalHeader} from 'reactstrap'
import moment from 'moment'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import {
    getPatientLabRecords as onGetPatientLabRecords,
} from "../../../../slice/thunks"
import TableContainer from '../../../../components/Common/TableContainer'
import GlobalLoader from '../../../../components/Common/GlobalLoader'

function PatientFromLab(props) {
    const dispatch = useDispatch()
    const selectLayoutState = (state) => state.Doctors;
    const OutputsProperties = createSelector(
      selectLayoutState,
      (outputs) =>({
        patientsLabRecords: outputs.patientsLabRecords,
        error: outputs.error
      })
    )
    const { patientsLabRecords,  error } = useSelector(OutputsProperties)
    const [status, setStatus] = useState({label: 'All', value: 'all'})
    const [searchInput, setSearchInput] = useState('')
    const [isLoading, setIsLoading] = useState(true)

  const [modal, setModal] = useState(false)
  const [currentPatient, setCurrentPatient] = useState(null)

  const loadConsultationList = useCallback((pageNumber, pageSize, term) => {

      if (pageNumber && pageSize) {
          if (term) {
            dispatch(onGetPatientLabRecords({page: pageNumber, size: pageSize, status: status.value, term: searchInput}))
          }
          else {
            dispatch(onGetPatientLabRecords({page: pageNumber, size: pageSize, status: status.value}))
          }
      } else {
          if (term) {
              dispatch(onGetPatientLabRecords({page: 1, size: 10, status: status.value, term: searchInput}))
          }
          else {
              dispatch(onGetPatientLabRecords({page: 1, size: 10, status: status.value}))
          }
      }
  })

  const toggle = useCallback(() => {

    if (modal) {
      setModal(false);
      setCurrentPatient(null)
    } else {
      setModal(true);
    }
  })

  useEffect(() =>{
      if (props.isActive) {
        loadConsultationList()
      }
  },[props.isActive])

  useEffect(() => {
    if (patientsLabRecords && patientsLabRecords.page >= 0) {
      setIsLoading(false)
    }
  },[patientsLabRecords])

    const handleSelectPatient = (patient) => {
        setCurrentPatient(patient)
        toggle()
      }

    const columns = useMemo(
        () => [
            {
                header: props.t("patient_id"),
                accessorKey: "index",
                style: "button",
                enableColumnFilter: false,
                cell: (cellProps) => {
                    return <span>#{cellProps.row.original.patient.patientRef}</span>;
                },
            },
            {
                header: props.t("patients_name"),
                accessorKey: "firstName",
                style: "text-start",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <div className='d-flex align-items-center'>
                                <span >
                                    {(cell.row.original.patient.lastName != null ) ? cell.row.original.patient.firstName+" "+cell.row.original.patient.lastName : cell.row.original.patient.firstName}
                                </span>
                        </div>
                }
            },
            {
                header: props.t("date"),
                style: "cat",
                enableColumnFilter: false,
                cell: (cell) => {
                  return <div className='d-flex align-items-center'>
                            <span>
                              {moment(cell.row.original.createdAt).format('DD/MM/YYYY')}
                            </span>
                    </div>
                }
            },
            {
                header: 'Status',
                style: "cat",
                enableColumnFilter: false,
                cell: (cell) => {
                  return <div className='d-flex align-items-center'>
                            <span>
                              {(cell.row.original.labRecordStatus)}
                            </span>
                    </div>
                }
            },
            {
                header: props.t("action"),
                style: "action d-flex justify-content-end me-2",
                cell: (cellProps) => {
                    return (
                    <td className="action d-flex justify-content-end me-2">
                        <Link
                            to={`/doctor/doctor-panel/see-lab-record/${cellProps.row.original.id}/${cellProps.row.original.patient.patientRef}/${(cellProps.row.original.patient.lastName != null ) ? cellProps.row.original.patient.firstName+" "+cellProps.row.original.patient.lastName : cellProps.row.original.patient.firstName}`}
                            // onClick={() =>{handleSelectPatient(cellProps.row.original)}}
                        >
                            <i className="fa fa-eye"></i>
                        </Link>
                    </td>
                    );
                },
            },
        ],
        [props]
    );

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="patients-list-content p-3 m-0 pt-4" id="new_patients">
                    <div className="container">
                    {/* <Row>
                        <Col xl={4} sm={4} className="">
                            <div className='mb-2'>
                                <label className="form-label" htmlFor="inputGroupSelect01">{props.t("select_date_range")}</label>
                                <div className='d-flex align-items-center'>
                                    <Flatpickr
                                        className='flatpick-custom'
                                        id="inputGroupSelect01"
                                        ref={fp}
                                        placeholder={props.t("select_date_range")}
                                        defaultValue={periods}
                                        value={ periods }
                                        options={{
                                            altInput: true,
                                            altFormat: "d / m/ Y",
                                            maxDate: new Date(),
                                            mode: "range",
                                        }}
                                        onChange={(e) => {
                                            if (e.length > 1) {
                                                setPeriods(e)
                                            }
                                        }}
                                    />
                                    <button type="button" className="btn btn-light mx-1" onClick={() => { if (!fp?.current?.flatpickr) return; fp.current.flatpickr.clear(); setPeriods([]) }} >
                                        {props.t("clear")}
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} sm={4}>
                            <div className='mb-2'>
                                <Label htmlFor="status-field" className="form-label">{props.t("see_doctor")}</Label><br/>
                                <Select
                                    name='status'
                                    id='status-field'
                                    value={status}
                                    isMulti={false}
                                    isClearable={false}
                                    onChange={(e) => {
                                        handleSelectStatus(e);
                                    }}
                                    options={statusList}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                />
                            </div>
                        </Col>
                        <Col xl={4} sm={4}>
                            <div className='mb-2'>
                                <Label htmlFor="status-field" className="form-label">{props.t("search_patient")}</Label><br/>
                                <div className="d-flex align-items-center">
                                    <div className="search-box me-2 mb-1 col-10">
                                        <Input
                                            className="custom-border form-control"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                        />
                                    </div>
                                    <button type="button" onClick={() => handleSearch()} className="btn btn-primary">
                                        <i className="fa fa-search p-0"></i>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    <div className="row pb-4">
                        <TableContainer
                            columns={columns}
                            data={((patientsLabRecords && patientsLabRecords.items) || [])}
                            isGlobalFilter={false}
                            customPageSize={10}
                            divClass="card table-card p-0"
                            tableClass="table"
                            tdClass="py-0 text-start"
                            theadClass="bg-gray"
                            tableSize={ patientsLabRecords && patientsLabRecords.size }
                            tablePage={ patientsLabRecords && patientsLabRecords.page }
                            tableTotal= { patientsLabRecords && patientsLabRecords.total_items }
                            tableTotalPage= { patientsLabRecords && patientsLabRecords.total_page }
                            onNextPage= {loadConsultationList}
                            onSearch= {loadConsultationList}
                            SearchPlaceholder={props.t("search_patient")}
                        />
                    </div>
                    </div>
                    <Modal id='showModal' size='' isOpen={modal || false} backdrop={'static'} centered>
                        <ModalHeader className="bg-light p-3" toggle={toggle}>
                        </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col xl={6} sm={6}>
                                        <p><b>{props.t("patient_ref")}:</b> #{currentPatient && currentPatient.patient && currentPatient.patient.patientRef }</p>
                                    </Col>
                                    <Col xl={6} sm={6}>
                                        <p><b>{props.t("patient_name")}:</b> {currentPatient && currentPatient.patient && currentPatient.patient.firstName }</p>
                                    </Col>
                                </Row>
                                <div className="table-area show mt-3" id="home-table">
                                    <table className="table">
                                        <thead>
                                            <tr scope="row">
                                                <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                                                <th style={{ width: "20%" }}>{props.t("product_name")}</th>
                                                {/* <th style={{ width: "10%" }}>{props.t("sample_type")}</th> */}
                                                <th style={{ width: "10%" }}>{props.t("range")}</th>
                                                {/* <th style={{ width: "10%" }} colSpan="2">{props.t("test_remark")}</th>
                                                <th style={{ width: "10%" }} colSpan="2">{props.t("test_results")}</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { currentPatient && currentPatient.labTests &&
                                                currentPatient.labTests.map((row, index) => (
                                                <tr key={index}>
                                                <td style={{ width: "10%" }}>{index+1}</td>
                                                <td style={{ width: "20%" }}>{row.productName}</td>
                                                {/* <td style={{ width: "10%" }}>{row.sampleType}</td> */}
                                                <td style={{ width: "10%" }}>{row.testRange}</td>
                                                {/* <td style={{ width: "10%" }}>{row.testRemark}</td>
                                                <td style={{ width: "10%" }}>{row.testResults}</td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </ModalBody>
                            <div className='p-3'>
                                <Row className="align-items-center">
                                <div className="col-sm">
                                </div>
                                <div className="col-sm-auto">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); setCurrentPatient(null) }}> {props.t("close")} </button>
                                    </div>
                                </div>
                                </Row>
                            </div>

                    </Modal>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PatientFromLab))
