import React, { useCallback, useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../components/Common/withRouter'
import {
  getLabRecord as onGetLabRecord,
  getAllLabRemarks as onGetAllLabRemarks,
  getAllLabSamples as onGetAllLabSamples,
  createLabTests as onCreateLabTests
} from "../../slice/thunks"
import { createSelector } from "reselect"
import { useSelector, useDispatch } from "react-redux"
import GlobalLoader from '../../components/Common/GlobalLoader'
import { Link, useNavigate, useParams } from "react-router-dom"
import { useReactToPrint } from 'react-to-print';
import TestsRow from '../../components/App/LabRecords/TestsRow'
import DeleteModal from '../../components/Global/DeleteModal'
import { Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import logo from "../../assets/images/HMS-Logo.png"

import moment from 'moment'

const LabRecordDetail = (props) => {
    const { id }= useParams()
    const dispatch = useDispatch()
    const history = useNavigate()
    const componentRef = useRef(null);
    const selectLayoutState = (state) => state.Laboratory;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            labRecord: setting.labRecord,
            allLabRemarks: setting.allLabRemarks,
            allLabSample: setting.allLabSample,
            createLabTestSuccess: setting.createLabTestSuccess,
            error: setting.error
        })
    )
    const { allLabRemarks, allLabSample, labRecord, createLabTestSuccess, error } = useSelector(SettingsProperties)
    const permission = JSON.parse(localStorage.getItem("perm"));
    const role = JSON.parse(localStorage.getItem("role"));
    const [ labRemarkOptions, setLabRemarkOptions ] = useState([])
    const [ labSampleOptions, setLabSampleOptions ] = useState([])
    const [ labTests, setLabTests ] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [printModal, setPrintModal] = useState(false)
    const [currentRow, setCurrentRow ] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const printRef = useRef(null);

    const printFn = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Lab test',
        copyStyles: true,
    });

    const loadLabRec = useCallback(() => {
        if (id) {
          dispatch(onGetLabRecord(id))
        }
    })

    const loadAllRemarks = useCallback(() => {
        if (id) {
          dispatch(onGetAllLabRemarks(id))
        }
    })

    const loadAllSamples = useCallback(() => {
        if (id) {
          dispatch(onGetAllLabSamples(id))
        }
    })

    const toggle = useCallback(() => {
        setPrintModal(!printModal)
    },[printModal])

    const handlePrint = useCallback(() => {
        printFn();
    }, [printFn]);

    useEffect(() => {
        loadLabRec()
        loadAllRemarks()
        loadAllSamples()
    },[dispatch])

    useEffect(() => {
        if (labRecord && labRecord.id) {
            setIsLoading(false)
        }
    },[labRecord])

    useEffect(() =>{
        if (allLabRemarks && allLabRemarks.length > 0) {
          const remarks_list = allLabRemarks.map( rem => {
            return { label: rem.name, value: rem.id }
          })
          setLabRemarkOptions(remarks_list)
        }
    },[allLabRemarks])

    useEffect(() =>{
        if (allLabSample && allLabSample.length > 0) {
          const samples_list = allLabSample.map( samp => {
            return { label: samp.name, value: samp.id }
          })
          setLabSampleOptions(samples_list)
        }
    },[allLabSample])

    useEffect(() => {
        if (labRecord && labRecord.labTests && labRecord.labTests.length > 0) {
            setLabTests(labRecord.labTests)
        }
    },[labRecord])

    useEffect(() => {
        if (createLabTestSuccess && !error) {
            history("/lab/lab-record")
        }
    },[createLabTestSuccess, labRecord])

    const handleUpdateRow = (row, index) => {
        let current_labTest = [...labTests];
        if (index !== -1) {
            current_labTest[index] = { ...current_labTest[index], ...row };
            setLabTests(current_labTest);
        }
    }
    const onClickDelete = (row) => {
        setCurrentRow(row)
        setDeleteModal(true);
    }
    const handleDelete = () => {
        let current_labTest = [...labTests];
        current_labTest = current_labTest.filter((prod) => prod.productName !== currentRow.productName )
        setLabTests(current_labTest)
        setCurrentRow(null)
        setDeleteModal(false)
    }

    const saveLabTest = () => {
        dispatch(onCreateLabTests({ id: labRecord.id, body: {labTests: labTests}}))
    }

    const getValueName = useCallback((id, list) => {
        const item = list.find((element) => element.value === id);
        return item?.label
    },[])

    const styles = {
        page: {
            marginLeft: '1rem',
            marginRight: '1rem',
            color: 'black',
            backgroundColor: 'white',
        },
        tablePatientInfo: {
            width: '100%',
            'caption-side': 'bottom',
            'border-collapse': 'collapse',
        },
        tbody: {
            'border-color': 'inherit',
            'border-style': 'solid',
            'border-width': 0,
        },
        trPatientInfo: {
            'border-bottom': '1px solid #acacac;'
        },
        tdPatientInfo: {
            padding: '7px 0',
            color: '#52575c',
            'vertical-align': 'middle'
        },

        tableTestsResultsDetails: {
            width: '100%',
            'caption-side': 'bottom',
            'border-collapse': 'collapse',
            'margin-top': '1.5rem!important'
        },
        trResultsDetails: {'border-style': 'solid'},
        tdProductName: { width: '25%'},
        tdSampleType: { width: '20%'},
        tdResults: { width: '15%'},
        tdRange: { width: '15%'},
        tdRemark: { width: '10%'},
    };

    const print = useCallback((patient) => {
        // const doc = new jsPDF({
        //     orientation: 'landscape',
        //     format: 'a4',
        //     unit: 'px',
        // });

        // // Adding the fonts.
        // //doc.setFont('Inter-Regular', 'normal');
        // const t = new Date();
        // doc.html(printRef.current, {
        //     async callback(doc) {
        //         await doc.save(`lab-test-reults-${patient.firstName}-${t.getTime()}`);
        //     },
        // });
        window.print();
    },[printRef])


  return (
    <React.Fragment>
        {isLoading ?
          <GlobalLoader />
            :
            <>
                <div className="px-4">
                    <div className="lab-record-details">
                        <div className="row py-4">
                            <div className="col-lg-4">
                                <Link to="/lab/lab-record">
                                    <div className="head-area">
                                        <i className="fa-solid fa-chevron-left"></i>
                                        <h6>{props.t("lab_tests")}</h6>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="patient-detail">
                                    <span className="title">{props.t("patient_id")}</span>
                                    <span className="value">- { labRecord && labRecord.patient && labRecord.patient.patientRef }</span>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="patient-detail">
                                    <span className="title">{props.t("patient_name")}</span>
                                    <span className="value">- { labRecord && labRecord.patient && labRecord.patient.firstName} {labRecord && labRecord.patient && labRecord.patient.lastName}</span>
                                </div>
                            </div>
                        </div>
                        <div className="detail-table">
                            <table className="table m-0">
                                <thead>
                                    <tr>
                                        <th>{props.t("test_name")}</th>
                                        <th>{props.t("sample_type")}</th>
                                        <th>{props.t("results")}</th>
                                        <th>{props.t("range")}</th>
                                        <th>{props.t("remark")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {labTests.map((row, index) => (
                                        <tr key={row.productName}>
                                            <TestsRow row={row} index={index}
                                                labRemarkOptions = {labRemarkOptions}
                                                labSampleOptions = {labSampleOptions}
                                                updateRow= {handleUpdateRow}
                                                deleteRow={onClickDelete}
                                            />
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {(role === 'LAB_TECHNICIAN')  ||  permission.includes('ADD_LAB_TESTS') || permission.includes('UPDATE_LAB_TESTS') ?
                            <div className="btns-area py-4 pb-5" style={{ height: "300px" }} >
                                <button className="save-btn me-2" onClick={() => saveLabTest()}>{props.t("save")}</button>
                                <button type='button' className="print-btn"onClick={() => {toggle()}} ><i className="fa-solid fa-print"></i>Print preview</button>
                            </div>
                            :
                            <></>
                        }


                    </div>
                </div>
                <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() =>setDeleteModal(false)} />
                <Modal id='showModal' size='xl' isOpen={printModal || false} toggle={toggle} backdrop={'static'} centered={false} >
                    <ModalBody>
                        <div style={styles.page} className='print-lab-tests'  ref={componentRef}>
                            <div className='hiden-print'>
                                <Row className='d-flex justify-content-between m-5'>
                                    <Col className ='d-flex align-items-center'>
                                        <img src={logo} className="img-fluid" style={{ height: "150px" }} alt="" />
                                    </Col>
                                    <Col className ='d-flex justify-content-end'>
                                        <div className="row">
                                            <h5 className="text-center text-primary"><b><u>{props.t("hospital_name")}</u></b></h5>
                                            <p className='text-center'>
                                                <b>{props.t("hospital_po")}</b><br />
                                                <b>{props.t("hospital_contact")}</b><br />
                                                <u className='text-primary'>{props.t("hospital_email")}</u><br />
                                                <u>{props.t("hospital_website")}</u><br />
                                                <i>{props.t("founder")+": "+props.t("hospital_founder")}</i>
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <table style={styles.tablePatientInfo} className='patient-info'>
                                <tbody style={styles.tbody}>
                                    <tr className='tr-border-bottom' style={styles.trResultsDetails}>
                                        <td style={styles.tdPatientInfo} colSpan={2}><label>{props.t("email")}</label> : {props.t("hospital_email")}</td>
                                        <td style={styles.tdPatientInfo}><label>{props.t("hospital_contact")}</label></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tdPatientInfo}><label>{props.t("bil_no")}</label>  : {labRecord && labRecord.saleOrder.orderRef}</td>
                                        <td style={styles.tdPatientInfo}><label>{props.t("patient_id")}</label>  : {labRecord && labRecord.patient.patientRef}</td>
                                        <td  style={styles.tdPatientInfo}><label>{props.t("date")}</label> : {labRecord && moment(labRecord.createdAt).format('DD/MM/YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tdPatientInfo}><label>{props.t("patient_name")}</label>  : {`${labRecord && labRecord.patient.firstName} ${labRecord && labRecord.patient.lastName}`}</td>
                                        <td style={styles.tdPatientInfo}><label>{props.t("phone")}</label>  : {labRecord && labRecord.patient.phone}</td>
                                        <td style={styles.tdPatientInfo}><label>{props.t("address")}</label>  : {labRecord && labRecord.patient.address.address}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table  style={styles.tableTestsResultsDetails} className='tests-result-details mt-4 table-bordered'>
                                <thead>
                                    <tr>
                                        <td style={styles.tdProductName} className='product-name'>{props.t("test")}</td>
                                        <td style={styles.tdSampleType} className='sample-type'>{props.t("sample_type")}</td>
                                        <td style={styles.tdResults} className='results'>{props.t("result")}</td>
                                        <td style={styles.tdRange} className='range'>{props.t("range")}</td>
                                        <td style={styles.tdRemark} className='ramarks'>{props.t("remark")}</td>
                                    </tr>
                                </thead>
                                <tbody style={styles.tbody}>
                                    {labTests.map((row, index) => (
                                        <tr>
                                            <td style={styles.tdProductName} className='product-name'>{row.productName}</td>
                                            <td style={styles.tdSampleType} className='sample-type'>{getValueName(row.sampleType, labSampleOptions)}</td>
                                            <td style={styles.tdResults} className='results'>{row.testResults}</td>
                                            <td style={styles.tdRange} className='range'>{row.testRange}</td>
                                            <td style={styles.tdRemark} className='ramarks'>{getValueName(row.testRemark, labRemarkOptions)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter className='centered'>
                        <div className='show-print'>
                            <button type='button' className="btn btn-danger me-3"onClick={() => {toggle()}} ><i className="fa-solid fa-close"></i>{props.t("cancel")}</button>
                            <button type='button' className="btn btn-success"onClick={() => { handlePrint()}} ><i className="fa-solid fa-print"></i>{props.t("print")}</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(LabRecordDetail))
