import React from 'react'
import { Modal } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../../Common/withRouter'

const MatrixLabReportsContent = (props) => {
  return (
    <Modal id='showModal' isOpen={props.isOpen || false} toggle={props.toggleLab} centered
        className="invoice-modal modal-conten" style={{ maxWidth: "1140px" }}
      >
        <span className="invoice-modal close" onClick={() => props.toggleLab()}>&times;</span>
        <div className="invoice-modal container">
            <div className="invoice-modal header">
                <div className="invoice-modal reg-no"
                    style={{ display: "flex", justifyContent: "flex-start", gap: "150px", margin: "0" }}>
                    <p style={{ fontFamily: "Courier, monospace", fontSize: "14px" }}>{props.t("reg_no")}</p>
                    <p style={{ padding: "0", fontSize: "22px", fontFamily: "Courier, monospace" }}>{props.t("tax_invoice")}</p>
                </div>
                <h4 style={{ fontFamily: "Courier, monospace", fontWeight: "600" }}>{props.t("hospital_name")}</h4>
                <h6  style={{ fontFamily: "Courier, monospace", textAlign: "center", fontSize: "22px" }}>{props.t("hospital_location")}</h6>
                <div className="invoice-modal "
                    style={{ display: "flex", justifyContent: "center", gap: "15px", margin: "0", flexDirection: "row" }}>
                    <p>{props.t("hospital_email")}</p>
                    <p>{props.t("hospital_contact")}</p>
                </div>
            </div>

            <div className="invoice-modal section" style={{ marginBottom: "0", borderTop: "1px solid black"}}>
                <table>
                    <tr>
                        <th style={{ width: "10%" }}>BILL NO.</th>
                        <td>LAB-756</td>
                        <th style={{ borderLeft: "1px solid black" }}>DATE</th>
                        <td>09/02/2024</td>
                    </tr>
                    <tr>
                        <th style={{ width: "20%" }}>PATIENT NAME</th>
                        <td>ALEX THOMAS</td>
                        <th style={{ borderLeft: "1px solid black" }}>Pr. By</th>
                        <td>Dr. S. WATSON</td>
                    </tr>
                    <tr>
                        <th style={{ width: "10%" }}>ADDRESS</th>
                        <td style={{ borderRight: "1px solid black ", width: "30%" }} colspan="">BIPINDI, CAMEROON, AFRICA
                        </td>
                    </tr>
                </table>
            </div>

            <div className="invoice-modal totals" style={{ margin: "0" }}>
                <table>
                    <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                        <th>S.NO.</th>
                        <th>SERVICES NO.</th>
                        <th></th>
                        <th className="text-right" style={{ borderLeft: "1px solid black" }}>AMOUNT</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>THYROID TH1, TH2</td>
                        <td></td>
                        <td className="text-right" style={{ borderLeft: "1px solid black" }}>$85.01</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>BLOOD TEST</td>
                        <td></td>
                        <td className="text-right" style={{ borderLeft: "1px solid black" }}>$148.25</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>HEMOGLOBIN</td>
                      <td></td>
                      <td className="text-right" style={{ borderLeft: "1px solid black" }}>$108.56</td>
                    </tr>
                    <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                        <th>Cash Amt.</th>
                        <td>$341.88</td>
                        <td>Amt. In Words: <b>THREE HUNDRED EIGHT ONLY</b></td>
                        <th style={{ borderLeft: "1px solid black" }}>Bill Amount</th>
                        <td className="invoice-modal text-right">$341.88</td>
                    </tr>
                    <tr>
                        <th>Less Dis</th>
                        <td className="invoice-modal text-right">$33.87</td>
                        <td></td>
                        <th style={{ borderLeft: "1px solid black" }}>Dis Amt</th>
                        <td className="invoice-modal text-right">$33.87</td>
                    </tr>
                    <tr>
                        <th>Net Amt.</th>
                        <td className="invoice-modal text-right">$308.01</td>
                        <td></td>
                        <th style={{ borderLeft: "1px solid black" }}>Paid Amt.</th>
                        <td className="invoice-modal text-right">$308.01</td>
                    </tr>
                    <tr>
                        <th></th>
                        <td></td>
                        <td></td>
                        <th style={{ borderLeft: "1px solid black" }}>Bal Amt</th>
                        <td className="invoice-modal text-right">$0.00</td>
                    </tr>
                </table>
            </div>

            <div className="invoice-modal section" style={{ marginTop: "0",  borderTop: "1px solid black", borderBottom: "1px solid black"}}>
                <table>
                    <tr>
                        <th style={{ textAlign: "center", borderRight: "1px solid black" }}>COMPUTERISED LABORATORY</th>
                        <th style={{ textAlign: "center", borderRight: "1px solid black" }}>WORKING HOURS</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "center", width: "40%", borderRight: "1px solid black" }}>All types of
                            Hematology, Biochemistry, Stool Analysis, Urine Analysis & Serology are done here.
                        </td>
                        <td style={{ textAlign: "center", width: "34%", borderRight: "1px solid black" }}>
                            Monday To Saturday<br />
                            6:30AM To 5:00PM<br />
                            Sunday 6:30AM To 5:00PM
                        </td>
                        <td style={{ fontSize: "12px", width: "26%", textAlign: "center", paddingTop: "50px" }}>For St.
                            BIAKA HOSPITAL<br /><br />
                            signature
                        </td>
                    </tr>
                </table>
            </div>

            <div className="invoice-modal footer">
                <p style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>100% GENUINE LAB Reports</p>
                <p style={{ textAlign: "center" }}>Book Your Free Home Blood Sample Collection On: <span
                        style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>+237 6 75 55 38 64</span></p>
            </div>
        </div>
      </Modal>
  )
}

export default withRouter(withTranslation()(MatrixLabReportsContent))
