import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import makeAnimated from "react-select/animated"
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
  getBeds as onGetBeds,
  loadWards as loadWardsApi,
  addBed as onAddBed,
  updateBed as onUpdateBed,
  deleteBed as onDeleteBed,
  searchBed as onSearchBed
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"

const Beds = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      beds: setting.beds,
      allwards: setting.allwards,
      bed: setting.bed,
      bed_success: setting.bed_success,
      error: setting.error
    })
  )
  const { beds, bed, allwards, bed_success, error } = useSelector(SettingsProperties)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [wardsOptions, setWardsOptions] = useState([])
  const [selectedWards, setselectedWards] = useState(null)
  const [currentWard, setCurrentWard] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const permission = JSON.parse(localStorage.getItem("perm"))

  useEffect(() =>{
    loadBeds()
    loadWards()
  },[dispatch])

  useEffect(() =>{
    if (allwards && allwards.length > 0) {
      const wards_list = allwards.map( ward => {
        return { label: ward.wardsName, value: ward.id }
      })
      setWardsOptions(wards_list)
    }
  },[allwards])

  useEffect(() => {
    if (beds && beds.page) {
      setIsLoading(false)
    }
  },[beds])


  useEffect(() => {
    if (bed_success && !error) {
      setIsEdit(false)
      setselectedWards(null)
      validation.resetForm()
      setModal(false)
      setCurrentWard(null)
      loadBeds()
    }
  },[bed_success, error, bed])

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      validation.resetForm();
      setCurrentWard(null);
      setselectedWards(null);
    } else {
      setModal(true);
    }
  }, [modal])
  
  const searchBeds = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onSearchBed({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onSearchBed({term: term, page: 1, size: 10}))
    }
  },[])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (currentWard && currentWard.id) || '',
      bedNumber: (currentWard && currentWard.bedNumber) || '',
      bedCharge: (currentWard && currentWard.bedCharge) || '',
      wardId: (currentWard && currentWard.wards.id) || '',
    },
    validationSchema: Yup.object({
      bedNumber: Yup.string().required(props.t("you_need_provide_bedNumber")),
      bedCharge: Yup.string().required(props.t("you_need_provide_bedCharge")),
    }),
    onSubmit: (values) => {

      if (isEdit) {
        const updateBedForm = {
          id: currentWard && currentWard.id,
          bedNumber: values.bedNumber,
          bedCharge: values.bedCharge,
          wardId: selectedWards.value
        }
        const id = currentWard.id

        dispatch(onUpdateBed({ data: updateBedForm, id: id }))
        

      } else {
        const newBedForm = {
          bedNumber: values.bedNumber,
          bedCharge: values.bedCharge,
          wardId: selectedWards.value
        }
        dispatch(onAddBed(newBedForm))
      }
      
    },
  })

  const loadBeds = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetBeds({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetBeds())
    }
  })

  const loadWards = useCallback(() => {
    dispatch(loadWardsApi())
  })

  const handleOpen = () => {
    setIsEdit(false)
    toggle()
  }

  const handleSelectBed = (arg) => {
    const selectedBed = arg
    setCurrentWard({
      id: selectedBed.id,
      bedNumber: selectedBed.bedNumber,
      bedCharge: selectedBed.bedCharge,
      wards: selectedBed.wards
    })
    setselectedWards({ label: selectedBed.wards && selectedBed.wards.wardsName, value: selectedBed.wards && selectedBed.wards.id })
    setIsEdit(true);
    toggle();
  }

  const onClickDelete = (ward) => {
    setCurrentWard(ward);
    setDeleteModal(true);
  }

  function handleDeleteBeds() {
    if (currentWard) {
      dispatch(onDeleteBed({ data: currentWard, id: currentWard.id }))
      loadBeds()
      setDeleteModal(false)
    }
  }

  function handleSelectWard(selectedWards) {
    setselectedWards(selectedWards)
  }

  const columns = useMemo(
    () => [
      {
        header: props.t("wardsName"),
        accessorKey: "wards",
        style: "button",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{(cell.row.original.wards != null )? cell.row.original.wards.wardsName : ''}</span>
        }
      },
      {
        header: props.t("bedNumber"),
        accessorKey: "bedNumber",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("bedCharge"),
        accessorKey: "bedCharge",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("action"),
        style: "text-end",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                  {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("UPDATE_BEDS")) ?
                    <li className="list-inline-item edit" title="Edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => { const bedData = cellProps.row.original; handleSelectBed(bedData); }}
                      >
                        <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                      </Link>
                    </li>
                    :<></>
                  }
                  {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("DELETE_BEDS")) ?
                    <li className="list-inline-item" title="Remove">
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => { const bedData = cellProps.row.original; onClickDelete(bedData); }}
                      >
                        <img src="../assets/images/delete.png" alt=""></img>
                      </Link>
                    </li>
                    :<></>
                  }
                </ul>
            </div>
          );
        },
      },
    ],
    [handleSelectBed]
  );


  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <DeleteModal show={deleteModal} onDeleteClick={handleDeleteBeds} onCloseClick={() =>setDeleteModal(false)} />
          <div className="all-beds-setting px-3">
              <div className="patients-list py-4 px-2  ">
                  <div className="container-fluid">
                      <div className="row list-patients d-flex  justify-content-between">
                          <div className="col-lg-5">
                          <BreadCrumb title={props.t("beds")} pageTitle={props.t("settings")} />
                          </div>
                          <div className="col-sm-auto new-mode-btn">
                            {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("ADD_BEDS")) ?
                              <SinpleActionButton title={props.t("create_new_bed")} img="../assets/images/user.png" action={handleOpen} />
                              :<></>
                            }
                          </div>
                      </div>

                      <div className="payment-mode-lists">
                          <div className="container">
                              <div className="row justify-content-between">
                                <div className="card table-card">
                                  <TableContainer
                                    columns={columns}
                                    data={((beds && beds.items) || [])}
                                    isGlobalFilter={true}
                                    customPageSize={10}
                                    divClass="table-card-ward"
                                    tableClass="align-middle"
                                    theadClass="thead-light table-light"
                                    tableSize={ beds && beds.size }
                                    tablePage={ beds && beds.page }
                                    tableTotal= { beds && beds.total_items }
                                    tableTotalPage= { beds && beds.total_page }
                                    onNextPage= {loadBeds}
                                    onSearch= {searchBeds}
                                    SearchPlaceholder={props.t("search_bed")}
                                  />
                                </div>
                              </div>
                          </div>
                      </div>

                      <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                          <ModalHeader className="bg-light p-3" toggle={toggle}>
                            {!!isEdit ? props.t("edit_bed") : props.t("create_new_bed")}
                          </ModalHeader>
                          <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <ModalBody>
                              <input type="hidden" id="id-field" />
                              <Row>
                                <Col xl={12} sm={12}>
                                  <div className='mb-3'>
                                  <Label
                                      htmlFor="wardId-field"
                                      className="form-label"
                                    >
                                      {props.t("wardsName")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Select
                                      name='wardId'
                                      id='wardId-field'
                                      value={selectedWards}
                                      isMulti={false}
                                      isClearable={false}
                                      autoComplete="off" 
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleSelectWard(e);
                                      }}
                                      options={wardsOptions}
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                    />
                                  </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="wards-field"
                                      className="form-label"
                                    >
                                      {props.t("bedNumber")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="bedNumber"
                                      id="bedNumber-field"
                                      className="form-control"
                                      autoComplete="off" 
                                      placeholder={props.t("bedNumber")}
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.bedNumber || ""}
                                      invalid={
                                        validation.touched.bedNumber && validation.errors.bedNumber ? true : false
                                      }
                                    />
                                    {validation.touched.bedNumber && validation.errors.bedNumber ? (
                                      <FormFeedback type="invalid">{validation.errors.bedNumber}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="wards-field"
                                      className="form-label"
                                    >
                                      {props.t("bedCharge")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="bedCharge"
                                      id="bedCharge-field"
                                      className="form-control"
                                      autoComplete="off" 
                                      placeholder={props.t("bedCharge")}
                                      type="number"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.bedCharge || ""}
                                      invalid={
                                        validation.touched.bedCharge && validation.errors.bedCharge ? true : false
                                      }
                                    />
                                    {validation.touched.bedCharge && validation.errors.bedCharge ? (
                                      <FormFeedback type="invalid">{validation.errors.bedCharge}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </ModalBody>
                            <div className='p-3'>
                              <Row className="align-items-center">
                                <div className="col-sm">
                                  <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                  <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setselectedWards(null); setCurrentWard(null); setIsEdit(false) }}> {props.t("close")} </button>
                                    <button type="submit" className="btn btn-success"> {!!isEdit ? props.t("update") : props.t("save")} </button>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Form>

                      </Modal>
                  </div>
              </div>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Beds))