import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import GlobalLoader from '../../../components/Common/GlobalLoader'
import withRouter from '../../../components/Common/withRouter'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import {
    getPatientsCheckout as onGetPatientsCheckout,
    releasePatient as onReleasePatient
} from '../../../slice/thunks'
import TableContainer from '../../../components/Common/TableContainer'
import moment from 'moment'
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from "yup"
import { createSelector } from 'reselect'
import { getAllGuarantors } from '../../../helpers/backend_helper'
import ReactSelect from 'react-select'


const PatientsCheckout =  (props) => {

    // variables definitions
    const dispatch = useDispatch();
    const selectLayoutState = (state) => state.Outputs;

    const OutputsProperties = createSelector(
        selectLayoutState,
        (outputs) =>({
          patientsCheckout: outputs.patientsCheckout,
          release_patient_success: outputs.release_patient_success,
          error: outputs.error
        })
    )

    let {patientsCheckout, release_patient_success, error } = useSelector(OutputsProperties)
    let [isLoading, setIsLoading] = useState(true);
    let [patientToDischarge, setPatientToDischarge] = useState(null)
    let [dischargeModal, setDischargeModal] = useState(false)
    let [guarantors, setGuarantors] = useState([])
    let [guarantor, setGuarantor] = useState(null)
    let [options, setOptions] = useState([])

    const loadPatitentsCheckout = useCallback((page, size) => {
        if (page && size) {
            dispatch(onGetPatientsCheckout({page: page, size: size}))
        } else {
            dispatch(onGetPatientsCheckout({page: 1, size: 10}))
        }
    },[dispatch])

    const searchPatient = useCallback((term, pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
          dispatch(onGetPatientsCheckout({term: term, page: pageNumber, size: pageSize}))
        } else {
          dispatch(onGetPatientsCheckout({term: term, page: 1, size: 10}))
        }
    },[dispatch])

    const releasePatientCallBack = useCallback((values)  => {
        dispatch(onReleasePatient({
            id: patientToDischarge.patientRecordId,
            data: {
                releaseDate: values.releaseDate,
                description: values.description,
                guarantorId: guarantor?.value
            }
        }))
        .then((data) => {
            if (data.payload) {
                loadPatitentsCheckout()
            }
        })
    },[patientToDischarge, dispatch, loadPatitentsCheckout, guarantor])

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            releaseDate: getTodayDate(),
            description: '',
            guarantorId: null
        },
        validationSchema: Yup.object({
            releaseDate: Yup.string().required('Select a release date'),
            description: Yup.string()
            .required('Add a note')
            .max(500, 'Max length is 500')
            .min(10, 'Min length is 10')
        }),
        onSubmit: (values) => {
            releasePatientCallBack(values);
        }
    })

    const selectPatientCheckoutRow = useCallback((data)  => {
        getAllGuarantors(setGuarantors)
        if(guarantors) {
            validation.resetForm();
            setPatientToDischarge(data);
            setDischargeModal(true)
        }
    }, [setPatientToDischarge, validation, guarantors, setGuarantors])

    const submitReleasePatient = useCallback((e) => {
        e.preventDefault();
        validation.handleSubmit();
        setDischargeModal(false);
        return false;
    },[validation]);


    useEffect(() => {
        loadPatitentsCheckout()
    }, [loadPatitentsCheckout])


    useEffect(() => {
        if(patientsCheckout && patientsCheckout.page >= 0) {
            setIsLoading(false)
        }
    }, [patientsCheckout])

    useEffect(() => {
        if(release_patient_success && !error) {
            loadPatitentsCheckout()
        }
    },[loadPatitentsCheckout, release_patient_success, error])

    useEffect(() => {
        if(guarantors.length) {
            const optionsList = guarantors.map( guarantor => {
                return { label: guarantor.fullName, value: guarantor.id }
            });
            setOptions(optionsList);
        }
    },[guarantors])


    // columns defintions
    const columns = useMemo(
        () => [
          {
            header: props.t("patient_ref"),
            enableColumnFilter: false,
            accessorKey: "patientId",
          },
          {
              header: props.t("patient_name"),
              enableColumnFilter: false,
              accessorKey: "fullName",
          },
          {
            header: props.t("ward_name"),
            accessorKey: "wardName",
            enableColumnFilter: false,
          },
          {
            header: props.t("bedNumber"),
            accessorKey: "bedNumber",
            enableColumnFilter: false
          },
          {
            header: props.t("visit_date"),
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>
                    {moment(cell.row.original.visitDate).format('DD/MM/YYYY')}
                </span>;
            },
          },
          {
            header: props.t("release_date"),
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>
                    {cell.row.original.releaseDate ?  moment(cell.row.original.releaseDate).format('DD/MM/YYYY') : ''}
                </span>;
            },
          },
          {
            header: props.t("total_amount"),
            accessorKey: "totalAmount",
            enableColumnFilter: false
          },
          {
            header: props.t("total_amount_paid"),
            accessorKey: "totalAmountPaid",
            enableColumnFilter: false
          },
          {
            header: props.t("action"),
            enableColumnFilter: false,
            cell: (cellProps) => {
                const data = cellProps.row.original;
                return <span>
                    {
                        ((!data.releaseDate)) ?
                        (
                            <button className='btn send-btn px-4' onClick={() => selectPatientCheckoutRow(cellProps.row.original)}>
                                Discharge
                            </button>
                        )
                        :
                        null
                    }
                </span>
            }
          }
        ],
        [selectPatientCheckoutRow, props]
      );


    return (
        <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className='patients-list py-4 px-2'>
                    <div className='patient-bill-and-discharge-sec'>
                            <div className="container">
                                <div className="heading-sec">
                                    <h6>{props.t("patients_checkout")}</h6>
                                </div>
                                <div className="patient-bill-and-discharge-main-sec">
                                    <div className="card table-card">
                                        <TableContainer
                                                    columns={columns}
                                                    data={((patientsCheckout && patientsCheckout.items) || [])}
                                                    isGlobalFilter={true}
                                                    customPageSize={10}
                                                    divClass="table-responsive"
                                                    tableClass="table"
                                                    theadClass="table-light"
                                                    tdClass="td"
                                                    tableSize={ patientsCheckout && patientsCheckout.size }
                                                    tablePage={ patientsCheckout && patientsCheckout.page }
                                                    tableTotal= { patientsCheckout && patientsCheckout.total_items }
                                                    tableTotalPage= { patientsCheckout && patientsCheckout.total_page }
                                                    onNextPage= { () => {loadPatitentsCheckout()}}
                                                    onSearch= {searchPatient}
                                        />
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

                <Modal id='discharge-modal' isOpen={dischargeModal} backdrop={'static'} size='sm' toggle={dischargeModal} centered>
                    <ModalHeader className='bg-light' toggle={dischargeModal}>
                        Discharge Patient
                    </ModalHeader>
                    <ModalBody>
                        <Form className="tablelist-form" autoComplete="off" onSubmit={submitReleasePatient}>
                        <Row>
                            <Input type='hidden' name='patientRecordId' value={patientToDischarge ? patientToDischarge.patientRecordId : ''} />
                            <Col xl={12} sm={12}>
                                <div className='mb-2 mt-1'>
                                    <Label className="form-label">Patient : </Label><br/>
                                    <Input className="form-control" type="text" value={patientToDischarge ? patientToDischarge.fullName : ''} disabled/>
                                </div>
                            </Col>
                                <Col xl={12} sm={12}>
                                    <div className='mb-2 mt-1'>
                                        <Label htmlFor="releaseDate-field" className="form-label">
                                            {props.t("release_date")}<span className='text-danger'> * </span>
                                        </Label><br/>
                                        <Input
                                            name="releaseDate" id='releaseDate-field' className="form-control" type="date"
                                            validate={{ required: { value: true } }} max={getTodayDate()} onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.releaseDate}
                                            autoComplete="off"
                                            invalid={validation.touched.releaseDate && validation.errors.releaseDate}
                                        />
                                        {validation.touched.releaseDate && validation.errors.releaseDate ? (
                                            <FormFeedback type="invalid">{validation.errors.releaseDate}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                    <div className='mb-2 mt-1'>
                                        <Label htmlFor="releaseDate-field" className="form-label">
                                            Select a guarantor if necessary
                                        </Label><br/>
                                        <ReactSelect
                                            name='guarantorId'
                                            id='guarantor-id'
                                            value={guarantor}
                                            isMulti={false}
                                            isClearable={true}
                                            onBlur={validation.handleBlur}
                                            options={options}
                                            onChange={(e) => { setGuarantor(e ? {value: e.value, label: e.label} : null); }}
                                            closeMenuOnSelect={true}
                                        />
                                    </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                    <div>
                                        <Label htmlFor="description-field" className="form-label">
                                            {props.t("description")}
                                            <span className='text-danger'> * </span>
                                        </Label>
                                        <textarea
                                            name="description"
                                            className={(validation.touched.description && validation.errors.description) ? "form-control is-invalid" : "form-control"}
                                            id="description-field"
                                            placeholder={props.t("enter_description")}
                                            rows={3}
                                            validate={{required: { value: true }}}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.description}
                                            invalid={validation.touched.description && validation.errors.description}
                                        />
                                        {validation.touched.description && validation.errors.description ? (
                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className='p-3'>
                                    <div className="col-sm-auto">
                                        <div className="hstack gap-2 justify-content-end">
                                            <button type="button" className="btn btn-sm btn-secondary"
                                            onClick={() => { setDischargeModal(false); setGuarantors([]); }}> {props.t("close")} </button>
                                            <button type="submit" className="btn btn-sm btn-success">{props.t("save")}</button>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        }
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(PatientsCheckout))