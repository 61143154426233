import withRouter from '../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import { useSelector, useDispatch } from "react-redux"
import { useReactToPrint } from 'react-to-print';
import moment from 'moment'
import * as Yup from "yup"
import { useFormik } from 'formik'
import Select from "react-select"
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'

import {
    getHospitalisationList as onGetHospitalisationList,
    leaveBed as onLeaveBed
} from "../../../slice/thunks"
import TableContainerWarehouse from '../../../components/Common/TableContainerWarehouse'

import GlobalLoader from '../../../components/Common/GlobalLoader'
import SinpleActionButton from '../../../components/Global/SinpleActionButton'

const PhOccupiedWards = (props) => {
  const dispatch = useDispatch()
  const componentRef = useRef(null);
  const selectLayoutState = (state) => state.Outputs;
  const SettingsProperties = createSelector(
      selectLayoutState,
      (output) =>({
      hospitalisationList: output.hospitalisationList,
      bed_leave_success: output.bed_leave_success,
      error: output.error
      })
  )
  const { hospitalisationList, bed_leave_success, error } = useSelector(SettingsProperties)
  const [currentPatient, setCurrentPatient] = useState(null)
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const loadHospitalisationList = useCallback((pageNumber, pageSize) => {
      if (pageNumber && pageSize) {
      dispatch(onGetHospitalisationList({page: pageNumber, size: pageSize}))
      } else {
      dispatch(onGetHospitalisationList({page: 1, size: 10}))
      }
  })

  const searchHospitalisation = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetHospitalisationList({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetHospitalisationList({term: term, page: 1, size: 10}))
    }
  })

  const toggle = useCallback(() => {
      if (modal) {
          setModal(false);
          setCurrentPatient(null);
          validation.resetForm()
      } else {
          setModal(true);
      }
  }, [modal])

  const printFn = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Account summary',
    copyStyles: true,
  });

  const handlePrint = useCallback(() => {
    printFn();
  }, [printFn]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        releaseDate: (currentPatient && currentPatient.releaseDate) || '',
    },
    validationSchema: Yup.object({
        releaseDate: Yup.string().required(props.t("you_need_provide_date")),
    }),
    onSubmit: (values) => {
        const leaveForm = {
            releaseDate: values.releaseDate,
        }
        const id = currentPatient.id
        dispatch(onLeaveBed({id: id, data: leaveForm}))
    },
  })

  useEffect(() =>{
    loadHospitalisationList()
  },[dispatch])

  useEffect(() => {
      if (hospitalisationList && hospitalisationList.page) {
        setIsLoading(false)
      }
  },[hospitalisationList])

  useEffect(() => {
      if (bed_leave_success && !error) {
          toggle()
          loadHospitalisationList()
      }
  }, [bed_leave_success, hospitalisationList])

  const handleSelectPatient = useCallback((arg) => {
      setCurrentPatient(arg)
      toggle()
  },[toggle])

  const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  }

  const columns = useMemo(
      () => [
        {
            header: props.t("patient_id"),
            accessorKey: "main",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
            return <span>
                {(cell.row.original.patient !== null )? "#"+cell.row.original.patient.patientRef : ''}
            </span>
            }
        },
        {
            header: props.t("ward_no"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
            return <span>
                {(cell.row.original.bed !== null )? cell.row.original.bed.wards.wardsName : ''}
            </span>
            }
        },
        {
            header: props.t("patient_name"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>
                {(cell.row.original.patient !== null )? cell.row.original.patient.firstName+" "+cell.row.original.patient.lastName : ''} 
                </span>
            }
        },
        // {
        //     header: props.t("bed_no"),
        //     style: "text-start",
        //     enableColumnFilter: false,
        //     cell: (cell) => {
        //     return <span>
        //         {(cell.row.original.bed !== null )? cell.row.original.bed.bedNumber : ''} 
        //     </span>
        //     }
        // },
        // {
        //     header: props.t("type_of_patient"),
        //     style: "text-start",
        //     enableColumnFilter: false,
        //     cell: (cell) => {
        //         return <span>
        //         {(cell.row.original.patient !== null )? cell.row.original.patient.patientType.typeName : ''} 
        //         </span>
        //     }
        // },
        // {
        //     header: props.t("date"),
        //     accessorKey: "entryDate",
        //     style: "text-start",
        //     enableColumnFilter: false,
        //     cell: (cell) => {
        //         return (
        //             <span>
        //                 { moment(cell.row.original.entryDate).format('DD/MM/YYYY') }
        //             </span>
        //             )
        //     }
        // },
        // {
        //     header: props.t("admit_patient"),
        //     style: "button",
        //     cell: (cellProps) => {
        //     return (
        //         <div className="d-flex">
        //             <ul className="list-inline hstack mb-0">
        //             <li className="list-inline-item edit" title="Edit">
        //                 <>
        //                     <Link 
        //                         to="#" 
        //                         className="btn save-del btn-danger " style={{fontSize: "11px" }}
        //                         onClick={() => { const admin = cellProps.row.original; handleSelectPatient(admin); }}
        //                     >
        //                     {props.t("leave_bed") }
        //                     </Link>
        //                 </>
        //             </li>
        //             </ul>
        //         </div>
        //     );
        //     },
        // },
        {
          header: props.t("action"),
          style: "action",
          cell: (cellProps) => {
            return (
              <div className="d-flex justify-content-center">
                  <ul className="list-inline hstack mb-0">
                      <li className="list-inline-item edit" title={props.t("edit")}>
                          <Link
                              to={`/pharmacy/occupied_wards/details/${cellProps.row.original.patientRecordId}/${cellProps.row.original.patient.id}/${(cellProps.row.original.bed !== null )? cellProps.row.original.bed.wards.wardsName : ''}`}
                              className="text-primary d-inline-block edit-item-btn"
                          >
                              <i className="fa fa-eye" aria-hidden="true"></i>
                          </Link>
                      </li>
                  </ul>
              </div>
            );
          },
        },
      ],
      [handleSelectPatient]
  );

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="patients-list-content">
                    <div className="patients-list px-2 pb-5">
                        <div className="container-fluid">
                                <div className="heading-parent-sec">
                                    <div className="heading-child-one-sec pt-4 pb-3">
                                        <h5>
                                            <b>{props.t("occupied_wards")}</b>
                                        </h5>
                                    </div>
                                    <div className="heading-child-two-sec pt-4">
                                        {hospitalisationList && hospitalisationList.items ? 
                                            <SinpleActionButton title={props.t("print")} ico="fa-print" action={handlePrint} />
                                            : null
                                        }
                                    </div>
                                </div>


                                <div className="card border-0 mx-2" ref={componentRef}>
                                    <Row className="m-3 hiden-print">
                                        <Col xl={6} sm={6}>
                                        <Row>
                                            <p>{props.t("reg_no")}</p>
                                            <p>{props.t("hospital_name")}</p>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <div className="mx-2">
                                        <TableContainerWarehouse
                                            columns={columns}
                                            data={((hospitalisationList && hospitalisationList.items) || [])}
                                            isGlobalFilter={true}
                                            customPageSize={10}
                                            divClass="card table-card table-warehouse"
                                            tableClass="table"
                                            theadClass="thead-light table-light"
                                            tdClass="td"
                                            tableSize={ hospitalisationList && hospitalisationList.size }
                                            tablePage={ hospitalisationList && hospitalisationList.page }
                                            tableTotal= { hospitalisationList && hospitalisationList.total_items }
                                            tableTotalPage= { hospitalisationList && hospitalisationList.total_page }
                                            onNextPage= {loadHospitalisationList}
                                            onSearch= {searchHospitalisation}
                                            SearchPlaceholder={props.t("search_name_phone_id")}
                                        />
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PhOccupiedWards))
