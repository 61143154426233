import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom"
import { withTranslation } from 'react-i18next'
import withRouter from '../../components/Common/withRouter'
import avatar from "../../assets/images/fa-user.png"
import {
    getProfile as onGetProfile,
} from "../../slice/thunks"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import GlobalLoader from '../../components/Common/GlobalLoader'

const Profile = (props) => {
    const dispatch = useDispatch()
    const history = useNavigate()
    const selectSettingState = (state) => state.Settings
    const SettingsProperties = createSelector(
        selectSettingState,
        (setting) =>({
          profile: setting.profile,
        })
    )
    const { profile } = useSelector(SettingsProperties)
    const [currentProfile, setCurrentProfile] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        dispatch(onGetProfile())
    },[dispatch])

    useEffect(() => {
        if (profile && profile.id) {
            setIsLoading(false)
            setCurrentProfile(profile)
        }
    }, [profile])

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="dashboard">
                    <div className="patient-details-sec">
                        <div className="container pt-3">
                            <div className="patient-details-main-sec pb-3">
                                <div className="patient-details-child-one">
                                    <a href="javascript:history.back()">
                                        <div className="patient-details-heading-sec">
                                            <div className="left-arrow">
                                                <i className="fa-solid fa-chevron-left"></i>
                                            </div>
                                            <div className="heading align-middle">
                                                <p className="mb-0">{props.t("my_profile")}</p>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="patient-details-btnn-sec">
                                        <div className="btnn-two">
                                            <button className="btn">Edit&nbsp;<i className="fa-solid fa-pen-to-square"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="patient-details-child-two">
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="upload-pic-sec py-3">
                                                        <div className="profile-pic">
                                                            <img src={avatar} id="output" width="200"></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                            <div className="patient-details-child-two-two">
                                                <div className="para-patient-id mb-3">
                                                    <p className="mb-2">{props.t("user_name")}<br />
                                                    <span className="text-danger"> {currentProfile && currentProfile.username}</span></p>
                                                </div>
                                                <div className="para-patient-id mb-3">
                                                    <p className="mb-1">{props.t("role")}<br />
                                                    <span className=""> {currentProfile && currentProfile.role && currentProfile.role.roleName}</span></p>
                                                </div>
                                                <div className="para-patient-id mb-3">
                                                    <p className="mb-2">{props.t("email")}<br />
                                                    <span className=""> {currentProfile && currentProfile.email}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                            <div className="patient-details-child-two-two">
                                                <div className="para-patient-id mb-3">
                                                    <p className="mb-2">{props.t("fullname")}<br />
                                                    <span className=""> {currentProfile && currentProfile.fullName} </span></p>
                                                </div>
                                                <div className="para-patient-id mb-3">
                                                    <p className="mb-2">{props.t("phoneNumber")}<br />
                                                    <span className=""> {currentProfile && currentProfile.phone}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Profile))
