import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../components/Common/withRouter'
import { Label, Input, Form, FormFeedback } from 'reactstrap'
import { Link, useNavigate, useParams } from "react-router-dom"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import * as Yup from "yup"
import { useFormik } from 'formik'
import {
    getPatient as onGetPatient,
    addNewPatientVitals as onAddNewPatientVitals,
    getPatientVitals as onGetPatientVitals,
    updatePatientVitals as onUpdatePatientVitals,
    getPatientVitalsRecords as onGetPatientVitalsRecords
} from "../../../../slice/thunks"
import GlobalLoader from '../../../../components/Common/GlobalLoader'

const PatientVital = (props) => {
    const dispatch = useDispatch()
    const history = useNavigate()
    let { id, action } = useParams();
    const selectLayoutState = (state) => state.Outputs;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (output) =>({
            patient: output.patient,
            patientVitals: output.patientVitals,
            patient_vital_success: output.patient_vital_success,
            error: output.error
        })
      )
    const { patient, patientVitals, patient_vital_success, error } = useSelector(SettingsProperties)
    const [isEdit, setIsEdit] = useState(false)
    const [saveNext, setSaveNext] = useState(false)
    const [currentPatientVitals, setCurrentPatientVitals] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (props.isActive) {
            loadPatient()
        }
    },[props.isActive])

    useEffect(() =>{
        if (!id || action != 'update') {
            setIsLoading(false)
        }
    },[id])

    useEffect(() =>{
        if ((patient && patient.patientRecord) && props.isActive) {
            loadPatientVitalsRecord(patient.patientRecord.id)
        }
    },[patient, props.isActive])

    useEffect(() => {
        if ((patientVitals && patientVitals.length > 0) && (patient.id === id)) {
            setIsEdit(true)
            setCurrentPatientVitals(patientVitals[0])
            setIsLoading(false)
        }
    },[patientVitals])

    useEffect(() =>{
        if (patient_vital_success && !error) {
            if (saveNext && !isEdit) {
                props.handleTabChange("see_doctor-content")
            }
            else if (saveNext && isEdit) {
                history(`/patients`)
            }
        }
    },[patient_vital_success, isEdit, patientVitals])

    const loadPatient = useCallback(() => {
        if (id) {
            dispatch(onGetPatient({id: id}))
        } else {
            dispatch(onGetPatient({id: props.patient.id}))
        }
    })

    const loadPatientVitals = useCallback(() => {
        dispatch(onGetPatientVitals(id))
    })

    const loadPatientVitalsRecord =  useCallback((currentId) => {
        dispatch(onGetPatientVitalsRecords(currentId))
    })

    const loadNext = useCallback(() => {
        props.handleTabChange("see_doctor-content")
    })

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: (currentPatientVitals && currentPatientVitals.id) || null,
            patientRecordId: (currentPatientVitals && currentPatientVitals.patientRecordId) || '',
            height: (currentPatientVitals && currentPatientVitals.height) || '',
            weight: (currentPatientVitals && currentPatientVitals.weight) || '',
            spo: (currentPatientVitals && currentPatientVitals.spo) || '',
            bp: (currentPatientVitals && currentPatientVitals.bp) || '',
            temperature: (currentPatientVitals && currentPatientVitals.temperature) || '',
        },
        onSubmit: (values) => {

            if (currentPatientVitals && currentPatientVitals.height) {
                const newVitalForm = {
                    height: values.height,
                    weight: values.weight,
                    spo: values.spo,
                    bp: values.bp,
                    temperature: values.temperature
                }
                dispatch(onUpdatePatientVitals({ id: currentPatientVitals.id, data: newVitalForm }))
            } else {
                const newVitalForm = {
                    height: values.height,
                    weight: values.weight,
                    spo: values.spo,
                    bp: values.bp,
                    temperature: values.temperature,
                    patientRecordId: props.patient.patientRecord.id
                }
                dispatch(onAddNewPatientVitals(newVitalForm))
                setIsEdit(false)
            }
        },
    })

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="patient-new mb-3">
                    <div className="container-fluid">
                        <div className="id ">
                            <div className="patient-id">
                                <div className="d-flex align-items-center mb-4">
                                    <h6 className="me-5">{props.t("patientId")} <span>{props.patient && props.patient.patientRef}</span></h6>
                                    <h6 className="ms-5">{props.t("patient_name")} <span>- {props.patient && props.patient.firstName}</span> </h6>
                                </div>
                                <h6 className="me-5">{props.t("patient_vitals")}</h6>

                                <Form className="" autoComplete="off" onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}>
                                    <div className="row">

                                        <div className="col-md-3 mt-3">
                                            {/* <label htmlFor="height" className="form-label">{props.t("height")}</label>
                                            <input type="number" className="form-control border" id="height" placeholder="Enter"  style={{ fontSize:"14px" }} /> */}
                                            <Label
                                                htmlFor="height-field"
                                                className="form-label"
                                            >
                                                {props.t("height")}<span className='text-danger'>*</span>
                                            </Label>
                                            <Input
                                                name="height"
                                                id="height-field"
                                                className="form-control border"
                                                autoComplete="off"
                                                placeholder={props.t("enter_height_value")}
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.height || ""}
                                                invalid={ validation.touched.height && validation.errors.height }
                                            />
                                            {validation.touched.height && validation.errors.height ? (
                                                <FormFeedback type="invalid">{validation.errors.height}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="col-md-3 mt-3">
                                            <Label
                                                htmlFor="weight-field"
                                                className="form-label"
                                            >
                                                {props.t("weight")}<span className='text-danger'>*</span>
                                            </Label>
                                            <Input
                                                name="weight"
                                                id="weight-field"
                                                className="form-control border"
                                                autoComplete="off"
                                                placeholder={props.t("enter_weight_value")}
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.weight || ""}
                                                invalid={ validation.touched.weight && validation.errors.weight }
                                            />
                                            {validation.touched.weight && validation.errors.weight ? (
                                                <FormFeedback type="invalid">{validation.errors.weight}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="col-md-3 mt-3">
                                            <Label
                                                htmlFor="bp-field"
                                                className="form-label"
                                            >
                                                {props.t("bp")}<span className='text-danger'>*</span>
                                            </Label>
                                            <Input
                                                name="bp"
                                                id="bp-field"
                                                className="form-control border"
                                                autoComplete="off"
                                                placeholder={props.t("enter_weight_value")}
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.bp || ""}
                                                invalid={ validation.touched.bp && validation.errors.bp }
                                            />
                                            {validation.touched.bp && validation.errors.bp ? (
                                                <FormFeedback type="invalid">{validation.errors.bp}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="col-md-3 mt-3">
                                            <Label
                                                htmlFor="temperature-field"
                                                className="form-label"
                                            >
                                                {props.t("temperature")}<span className='text-danger'>*</span>
                                            </Label>
                                            <Input
                                                name="temperature"
                                                id="temperature-field"
                                                className="form-control border"
                                                autoComplete="off"
                                                placeholder={props.t("enter_temperature_value")}
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.temperature || ""}
                                                invalid={ validation.touched.temperature && validation.errors.temperature }
                                            />
                                            {validation.touched.temperature && validation.errors.temperature ? (
                                                <FormFeedback type="invalid">{validation.errors.temperature}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="col-md-3 mt-3">
                                            <Label
                                                htmlFor="spo-field"
                                                className="form-label"
                                            >
                                                {props.t("oxygen_saturation")}<span className='text-danger'>*</span>
                                            </Label>
                                            <Input
                                                name="spo"
                                                id="spo-field"
                                                className="form-control border"
                                                autoComplete="off"
                                                placeholder={props.t("enter_spo_value")}
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.spo || ""}
                                                invalid={ validation.touched.spo && validation.errors.spo }
                                            />
                                            {validation.touched.spo && validation.errors.spo ? (
                                                <FormFeedback type="invalid">{validation.errors.spo}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mt-5 mx-auto">
                                        <div className="">
                                            <Link to={"/patients"} className="btn save-del btn-danger px-5 mx-2" style={{ padding: "10px", height: "45px", borderRadius: "8px" }}>{props.t("cancel")}</Link>
                                        </div>
                                        {/* <button className="btn1 me-2" type="button">{props.t("save")}</button> */}
                                        <button className="btn2" type="submit" onClick={() => { setSaveNext(true) }}>{ isEdit ? props.t("update") :  props.t("save_and_next")}</button>
                                    </div>
                                </Form>
                            </div>
                        </div>

                    </div>

                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PatientVital))
