import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import makeAnimated from "react-select/animated"
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
  getDoctors as onGetDoctors,
  getDoctorTitle as onGetDoctorTitle,
  getAllRoles as onGetAllRoles,
  addDoctor as onAddDoctor,
  updateDoctor as onUpdateDoctor,
  deleteDoctor as onDeleteDoctor,
  searchDoctor as onSearchDoctor
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"

const Doctors = ({ t = (key) => key }) => {
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      doctors: setting.doctors,
      doctor: setting.doctor,
      doctorTitles: setting.doctorTitles,
      allroles: setting.allroles,
      doctor_success: setting.doctor_success,
      error: setting.error
    })
  )
  const { doctors, doctor, doctorTitles, allroles, doctor_success, error } = useSelector(SettingsProperties)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [titlesOptions, setTitlesOptions] = useState([])
  const [selectedTitles, setselectedTitles] = useState(null)
  const [currentDoctor, setCurrentDoctor] = useState(null)
  const [passwordShow, setPasswordShow] = useState(false)
  const [passwordConfirmShow, setPasswordConfirmShow] = useState(false)
  const [password, setPassword] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [errorPassword, setPasswordError] = useState(false)
  const [isPasswordMatch, setisPaswordMatch] = useState(true)
  const [deleteModal, setDeleteModal] = useState(false)
  const [passwordOk, setPasswordOk] = useState(false)
  const [doctorRole, setDoctorRole] = useState(false)
  const [errorSelectRole, setErrorSelectRole] = useState(false)
  const permission = JSON.parse(localStorage.getItem("perm"))
  const [isLoading, setIsLoading] = useState(true)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (currentDoctor && currentDoctor.id) || '',
      username: (currentDoctor && currentDoctor.username) || '',
      password:  '',
      updatePassword: '',
      roleId: doctorRole.id,
      fullName: (currentDoctor && currentDoctor.fullName) || '',
      phone: (currentDoctor && currentDoctor.phone) || '',
      email: (currentDoctor && currentDoctor.email) || '',
      titleId: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t("you_need_provide_username")),
      fullName: Yup.string().required(t("you_need_provide_fullname")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateDoctorForm = {
          id: currentDoctor && currentDoctor.id,
          fullName: values.fullName,
          username: values.username,
          phone: values.phone,
          email: values.email,
          titleId: selectedTitles.value
        }
        if (password.length > 0) {
          updateDoctorForm.password = password;
        }
        const id = currentDoctor.id
        dispatch(onUpdateDoctor({ data: updateDoctorForm, id: id }))
      } else {
        const newDoctorForm = {
          username: values.username,
          password: password,
          roleId: values.roleId,
          fullName: values.fullName,
          phone: values.phone,
          email: values.email,
          titleId: selectedTitles.value
        }
        dispatch(onAddDoctor(newDoctorForm))
      }

      setPassword(null)
      setConfirmPassword("")
      setPasswordError(false)
    },
  })

  const loadDoctors = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetDoctors({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetDoctors({page: 1, size: 10}))
    }
  },[dispatch])

  const loadTitle = useCallback(() => {
    dispatch(onGetDoctorTitle())
  },[dispatch])

  const loadRoles = useCallback(() => {
    dispatch(onGetAllRoles())
  },[dispatch])

  const searchDoctors = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onSearchDoctor({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onSearchDoctor({term: term, page: 1, size: 10}))
    }
  },[dispatch])

  const matchPassword = useCallback((target) => {
    setConfirmPassword(target.value)
    setisPaswordMatch(true)
    if (isEdit) {
      if (target.value.toString() !== password.toString()) {
        setisPaswordMatch(false)
      }
    } else {
      if (target.value.toString() !== password.toString()) {
        setisPaswordMatch(false)
      }
    }
  },[isEdit, validation])

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false)
      validation.resetForm()
      setCurrentDoctor(null)
      setConfirmPassword("")
      setErrorSelectRole(false)
      setselectedTitles(null)
      setisPaswordMatch(true)
      setPassword(null)
      setPasswordError(false)
      setisPaswordMatch(true)
    } else {
      setModal(true);
    }
  }, [modal, validation])

  const handleSelectDoctor = useCallback((arg) => {
    const selectedDoctor = arg
    setCurrentDoctor({
      id: selectedDoctor.id,
      username: selectedDoctor.username,
      password: selectedDoctor.password,
      roleId: selectedDoctor.roleId,
      fullName: selectedDoctor.fullName,
      phone: selectedDoctor.phone,
      email: selectedDoctor.email
    })
    setselectedTitles({ label: selectedDoctor.title, value: selectedDoctor.titleCode })
    setIsEdit(true);
    toggle();
  },[toggle])

  useEffect(() =>{
    loadDoctors()
    loadTitle()
    loadRoles()
  },[dispatch, loadDoctors, loadTitle, loadRoles])

  useEffect(() =>{
    if (doctorTitles && doctorTitles.length > 0) {
      const titles_list = doctorTitles.map( title => {
        return { label: title.title, value: title.titleCode }
      })
      setTitlesOptions(titles_list)
    }
  },[doctorTitles])

  useEffect(() => {
    if (allroles.length > 0) {
      const role = allroles.find(({ roleName }) => roleName === "DOCTOR")
      setDoctorRole(role);
    }
  },[allroles])

  useEffect(() => {
    if (doctors && doctors.page) {
      setIsLoading(false)
    }
  },[doctors])

  useEffect(() => {
    if (selectedTitles && selectedTitles.label) {
      setErrorSelectRole(false)
    }
  },[selectedTitles])

  useEffect(() => {
    if (doctor_success && !error) {
      setIsEdit(false)
      setselectedTitles(null)
      validation.resetForm()
      setModal(false)
      setCurrentDoctor(null)
      setConfirmPassword("")

      loadDoctors()
    }
  },[doctor_success, doctor, error])

  useEffect(() => {
    if (password != null && password.length < 6) {
      setPasswordError(true)
    } else {
      setPasswordError(false)
    }
  },[password])

  useEffect(() => {
    if (isEdit) {
        setPasswordOk((password && confirmPassword && confirmPassword.length >= 6 && isPasswordMatch))
    } else {
      setPasswordOk((password && confirmPassword && confirmPassword.length > 6 && isPasswordMatch))
    }
  }, [password,confirmPassword,isPasswordMatch])

  const handleOpen = () => {
    setIsEdit(false)
    toggle()
  }



  function handleDeleteDoctor() {
    if (currentDoctor) {
      dispatch(onDeleteDoctor(currentDoctor.id))
      loadDoctors()
      setDeleteModal(false)
    }
  }

  function handleSelectTitle(selectedTitles) {
    setselectedTitles(selectedTitles)
  }

  function capitalizeName(name) {
    if (!name) return '';
    return name
        .split(' ') 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
        .join(' '); 
  }


  const columns = useMemo(
    () => [
      {
        header: t("fullName"),
        accessorKey: "fullName",
        style: "button",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{capitalizeName(cell.row.original.fullName)}</span>
        }
      },
      {
        header: t("title"),
        accessorKey: "title",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: t("phone"),
        accessorKey: "phone",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: t("email"),
        accessorKey: "email",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: t("action"),
        style: "text-end",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("UPDATE_DOCTORS")) ?
                  <li className="list-inline-item edit" title="Edit">
                    <Link
                      to="#"
                      className="text-primary d-inline-block edit-item-btn"
                      onClick={() => { const doctorData = cellProps.row.original; handleSelectDoctor(doctorData); }}
                    >
                      <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                    </Link>
                  </li>
                  :<></>
                }
                  {/* <li className="list-inline-item" title="Remove">
                    <Link
                      to="#"
                      className="text-danger d-inline-block remove-item-btn"
                      onClick={() => { const doctorData = cellProps.row.original; onClickDelete(doctorData); }}
                    >
                      <img src="../assets/images/delete.png" alt=""></img>
                    </Link>
                  </li> */}
                </ul>
            </div>
          );
        },
      },
    ],
    [handleSelectDoctor, t]
  );


  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <DeleteModal show={deleteModal} onDeleteClick={handleDeleteDoctor} onCloseClick={() =>setDeleteModal(false)} />
          <div className="all-beds-setting px-3">
              <div className="patients-list py-4 px-2  ">
                  <div className="container-fluid">
                      <div className="row list-patients d-flex  justify-content-between">
                          <div className="col-lg-5">
                            <BreadCrumb title={t("doctors")} pageTitle={t("settings")} />
                          </div>
                          <div className="col-sm-auto new-mode-btn">
                            {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("ADD_DOCTORS")) ?
                              <SinpleActionButton title={t("create_new_doctor")} img="../assets/images/user.png" action={handleOpen} />
                              :<></>
                            }
                          </div>
                      </div>

                      <div className="payment-mode-lists">
                          <div className="container">
                              <div className="row justify-content-between">
                                <div className="card table-card">
                                  <TableContainer
                                    columns={columns}
                                    data={((doctors && doctors.items) || [])}
                                    isGlobalFilter={true}
                                    customPageSize={10}
                                    divClass="table-card-ward"
                                    tableClass="align-middle"
                                    theadClass="thead-light table-light"
                                    tableSize={ doctors && doctors.size }
                                    tablePage={ doctors && doctors.page }
                                    tableTotal= { doctors && doctors.total_items }
                                    tableTotalPage= { doctors && doctors.total_page }
                                    onNextPage= {loadDoctors}
                                    onSearch= {searchDoctors}
                                    SearchPlaceholder={t("search_doctor")}
                                  />
                                </div>
                              </div>
                          </div>
                      </div>

                      <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} centered>
                          <ModalHeader className="bg-light p-3" toggle={toggle}>
                            {!!isEdit ? t("edit_doctor") : t("create_new_doctor")}
                          </ModalHeader>
                          <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <ModalBody>
                              <input type="hidden" id="id-field" />
                              <Row>
                                <Col xl={12} sm={12}>
                                  <div className='mb-3'>
                                  <Label
                                      htmlFor="title-field"
                                      className="form-label"
                                    >
                                      {t("doctor_title")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Select
                                      name='title'
                                      id='title-field'
                                      value={selectedTitles}
                                      isMulti={false}
                                      isClearable={false}
                                      autoComplete="off"
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleSelectTitle(e);
                                      }}
                                      options={titlesOptions}
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                    />
                                    {errorSelectRole ?
                                      <p className='text-danger' type="invalid" style={{ fontSize: "13px" }} >{t("select_user_role")}</p>
                                      : <></>
                                    }
                                  </div>
                                </Col>
                                <Col xl={6} sm={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="username-field"
                                      className="form-label"
                                    >
                                      {t("username")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="username"
                                      id="username-field"
                                      className="form-control"
                                      autoComplete="off"
                                      placeholder={t("enter_username")}
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.username || ""}
                                      invalid={
                                        validation.touched.username && validation.errors.username ? true : false
                                      }
                                    />
                                    {validation.touched.username && validation.errors.username ? (
                                      <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xl={6} sm={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="fullname-field"
                                      className="form-label"
                                    >
                                      {t("fullname")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="fullName"
                                      id="fullname-field"
                                      autoComplete="off"
                                      className="form-control"
                                      placeholder={t("enter_fullname")}
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.fullName || ""}
                                      invalid={
                                        validation.touched.fullName && validation.errors.fullName ? true : false
                                      }
                                    />
                                    {validation.touched.fullName && validation.errors.fullName ? (
                                      <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xl={6} sm={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="email-field"
                                      className="form-label"
                                    >
                                      {t("email")}
                                    </Label>
                                    <Input
                                      name="email"
                                      id="email-field"
                                      autoComplete="off"
                                      className="form-control"
                                      placeholder={t("enter_email")}
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.email || ""}
                                      invalid={
                                        validation.touched.email && validation.errors.email ? true : false
                                      }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                      <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xl={6} sm={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="phone-field"
                                      className="form-label"
                                    >
                                      {t("phone")}
                                    </Label>
                                    <Input
                                      name="phone"
                                      id="phone-field"
                                      autoComplete="new-phone"
                                      className="form-control"
                                      placeholder={t("enter_phone")}
                                      type="number"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.phone || ""}
                                      invalid={
                                        validation.touched.phone && validation.errors.phone ? true : false
                                      }
                                    />
                                    {validation.touched.phone && validation.errors.phone ? (
                                      <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>

                                <Col xl={6} sm={6}>
                                    <div data-mdb-input-init className="form-outline mb-1" style={{position: 'relative'}}>
                                      <Label
                                        htmlFor="password-field"
                                        className="form-label"
                                      >
                                        {t("password")} {isEdit ? null :<span className='text-danger'>*</span>}
                                      </Label>
                                      <Input 
                                        id="password-field"
                                        name="password"
                                        type={passwordShow ? "text" : "password"}
                                        className="form-control"
                                        autoComplete="new-password"
                                        placeholder={t("password")}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onBlur={(e) => setPassword(e.target.value)}
                                        value={password || ""}
                                      />
                                      <i 
                                        className={passwordShow ? "fa fa-eye" : "fa fa-eye-slash"}
                                        id="togglePassword" 
                                        onClick={() => {setPasswordConfirmShow(!passwordConfirmShow); setPasswordShow(!passwordShow)}}
                                        style={{ position: 'absolute', top: '75%', right: '30px', transform: 'translateY(-50%)', cursor: 'pointer', fontSize: '16px', color: '#64748b' }}
                                      ></i>
                                    </div>
                                    {errorPassword ? (
                                      <p className='text-danger' style={{ fontSize: "14px" }}> {t("you_need_provide_password")} </p>
                                    ) : null}
                                </Col>

                                <Col xl={6} sm={6}>
                                  <div data-mdb-input-init className="form-outline mb-1" style={{position: 'relative'}}>
                                    <Label
                                      htmlFor="password-conf-f"
                                      className="form-label"
                                    >
                                      {t("confirm_password")} {!!isEdit ? "": <span className='text-danger'>*</span> }
                                    </Label>
                                    <Input
                                      id="password-conf-f"
                                      name="confirm_password"
                                      type={passwordConfirmShow ? "text" : "password"}
                                      className="form-control"
                                      autoComplete="new-password-confirm"
                                      placeholder={t("enter_password_again")}
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={(e) =>{ matchPassword(e.target)}}
                                      value={confirmPassword || ""}
                                      invalid = {!isPasswordMatch}
                                      disabled= { password && password.length > 0 ? false : true }
                                    />
                                    <i
                                      className={passwordConfirmShow ? "fa fa-eye" : "fa fa-eye-slash"}
                                      id="togglePassword"
                                      onClick={() => {setPasswordConfirmShow(!passwordConfirmShow); setPasswordShow(!passwordShow)}}
                                      style={{ position: 'absolute', top: '75%', right: '30px', transform: 'translateY(-50%)', cursor: 'pointer', fontSize: '16px', color: '#64748b' }}
                                    ></i>
                                  </div>
                                  {!isPasswordMatch ?
                                    <p className='text-danger' type="invalid" >{t("password_thos_not_match")}</p>
                                    : <></>
                                  }
                                </Col>
                              </Row>
                            </ModalBody>
                            <div className='p-3'>
                              <Row className="align-items-center">
                                <div className="col-sm">
                                  <span className='text-danger align-middle'>* : {t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                  <div className="hstack gap-2 justify-content-end">
                                    <button 
                                      type="button" 
                                      className="btn btn-secondary" 
                                      onClick={
                                        () => { 
                                          setModal(false); 
                                          validation.resetForm(); 
                                          setPassword(null);
                                          setConfirmPassword(""); 
                                          setCurrentDoctor(null); 
                                          setIsEdit(false); 
                                          setErrorSelectRole(false);
                                          setisPaswordMatch(true)
                                      }}
                                    > 
                                      {t("close")} 
                                    </button>
                                    
                                    <button 
                                      type="submit" 
                                      className="btn btn-success"
                                      disabled={!passwordOk}
                                      onClick={
                                        () => {
                                          selectedTitles && selectedTitles.label ?
                                            setErrorSelectRole(false)
                                            : setErrorSelectRole(true)
                                      }}
                                    >
                                      {!!isEdit ? t("update") : t("save")} 
                                    </button>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Form>

                      </Modal>
                  </div>
              </div>
          </div>
        </>
      }

    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Doctors))