import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// i18n

import { importProducts as onImport } from "../../../slice/thunks";

//redux
import { useDispatch } from "react-redux";
import withRouter from "../../Common/withRouter";
import { withTranslation } from "react-i18next";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const ImportProduct = (props) => {
  const dispatch = useDispatch();
  
  const [files, setFiles] = useState([]);


  const clear = () => {
    setFiles([]);
    props.onCloseClick();
  }

  const upload = () => {
    if (files.length > 0) {
        const formData = new FormData();
        formData.append("file", files[0].file);
        dispatch(onImport(formData));
        props.onCloseClick();
        setFiles([]);
    }
  };


  return (
    <Modal isOpen={props.show} toggle={props.onCloseClick} centered={true}>
        <ModalHeader toggle={props.onCloseClick}>
          <h4 className="card-title mb-0">{props.t("import_data")}</h4>
        </ModalHeader>
        <ModalBody className="py-3 px-5">
              <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={false}
                maxFiles={1}
                name="files"
                className="filepond filepond-input-multiple"
              />
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
                type="button"
                className="btn w-sm btn-light"
                data-bs-dismiss="modal"
                onChange={props.onCloseClick}
                onClick={clear}
            >
                {props.t("close")}
            </button>
            <button
                type="button"
                onClick={() => upload()}
                className="btn w-sm btn-success"
                id="delete-record"
                disabled = {files.length < 1 ? true : false}
            >
            {props.t("import")}
            </button>
            </div>
      </ModalBody>
    </Modal>
  );
};

ImportProduct.propTypes = {
  onCloseClick: PropTypes.func,
  data: PropTypes.any,
  show: PropTypes.any,
};

export default withRouter(withTranslation()(ImportProduct));
