import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../components/Common/withRouter'
import { Link, useNavigate, useParams } from "react-router-dom"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
    getDoctors as onGetDoctors,
    getPatient as onGetPatient,
    searchDoctor as onSearchDoctor,
    assignDoctor as onAssignDoctor,
    getDoctorToSee as onGetDoctorToSee,
    changeDoctorToSee as onChangeDoctorToSee
} from "../../../../slice/thunks"
import GlobalLoader from '../../../../components/Common/GlobalLoader'

const PatientSeeDoctor = (props) => {
    const dispatch = useDispatch()
    const history = useNavigate();
    let { id, action } = useParams();
    const settingLayoutState = (state) => state.Settings;
    const outputLayoutState = (state) => state.Outputs;
    const SettingsProperties = createSelector(
        settingLayoutState,
        (output) =>({
            doctors: output.doctors,
            error: output.error
        })
    )
    const OutputsProperties = createSelector(
        outputLayoutState,
        (output) =>({
            patient: output.patient,
            doctor_to_see: output.doctor_to_see,
            assignedDoctor: output.assignedDoctor,
            assign_doctor_success: output.assign_doctor_success,
            doctor_changed_success: output.doctor_changed_success,
            errorOutputs: output.error
        })
    )
    const { doctors, error } = useSelector(SettingsProperties)
    const { patient, doctor_to_see, assignedDoctor, assign_doctor_success, doctor_changed_success, errorOutputs } = useSelector(OutputsProperties)
    const [isEdit, setIsEdit] = useState(false)
    const [saveNext, setSaveNext] = useState(false)
    const [currentDoctor, setCurrentDoctor] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    
    const assignADoctor = useCallback(() => {
        dispatch(onAssignDoctor({ recordId: patient.patientRecord.id, data: { doctorId: currentDoctor.id }}))
        setSaveNext(true)
    })

    const changeDoctor = useCallback(() => {
        dispatch(onChangeDoctorToSee({ recordId: patient.patientRecord.id, data: { doctorId: currentDoctor.id }}))
        history("/patients/consultation_list-content")
    })

    const loadDoctor = useCallback(() => {
        dispatch(onGetDoctors({page: 1, size: 20}))
    })

    const loadDoctorToSee = useCallback((recordId) => {
        dispatch(onGetDoctorToSee(recordId))
    })

    const loadPatient = useCallback(() => {
        if (id) {
            dispatch(onGetPatient({id: id}))
        } else {
            dispatch(onGetPatient({id: props.patient.id}))
        }
    })

    const searchDoctor = useCallback((value) => {
        if (value.length >2) {
            dispatch(onSearchDoctor({term: value, page: 1, size: 20}))
        } else if (value.length === 0) {
            loadDoctor()
        }
    })

    useEffect(() =>{
        if ((id || action == 'create') && (doctors && doctors.page)) {
            setIsLoading(false)
        }
    },[doctors])

    useEffect(() => {
        if (props.isActive) {
            loadDoctor()
            loadPatient()
        }
    },[props.isActive])

    useEffect(() => {
        if (props.isActive) {
            if (patient && patient.id) {
                loadDoctorToSee(patient.patientRecord.id)
            }
        }
    }, [patient])

    useEffect(() => {
        if (props.isActive) {
            if (doctor_to_see && doctor_to_see.id) {
                const current_doctor = doctors.items.filter((doctor) => doctor.id == doctor_to_see.id)
                if (current_doctor.length > 0) {
                    setCurrentDoctor(current_doctor[0])
                    setIsEdit(true)
                    setIsLoading(false)
                }
            }
        }
    },[doctor_to_see])

    useEffect(() =>{
        if (assign_doctor_success) {
            if (saveNext && !isEdit) {
                props.handleTabChange("consultation_list-content")
            }
        } 
        else if (doctor_changed_success) {

        }
    },[assign_doctor_success, doctor_changed_success, assignedDoctor])

 


  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="see-doctor-sec">
                    {/* <!-- main section --> */}
                    <div className="see-new ">
                        <div className="container-fluid">
                            <div className="name ">
                                <div className="patient-name">
                                    <div className="d-flex align-items-center flex-wrap mb-4">
                                        <h6 className="me-5">{props.t("patient_id")} <span>- { patient && patient.patientRef}</span></h6>
                                        <h6>{props.t("patient_name")}<span>- { patient && patient.firstName+" "+patient.lastName}</span></h6>
                                        <div className="search-icon ms-auto me-2">
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                            <input type="text" className="search px-2" onChange={(e) => searchDoctor(e.target.value)} 
                                                placeholder={props.t("search_doctor")}
                                                style={{ fontSize: "13px", color:"rgba(160, 164, 168, 1)" }} />
                                        </div>
                                    </div>

                                    <div className="doctors mb-4">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {doctors && doctors.items ? 

                                                    doctors.items.map((doctor, index) =>(

                                                        <div className="col-md-4 my-2" style={{cursor:"pointer"}} key={index} onClick={() => setCurrentDoctor(doctor)}>
                                                            <div className="main-box">
                                                                <div className="sub-box">
                                                                    <div className="row">
                                                                        <div className="col text-sec">
                                                                            <div className="head-1 d-flex justify-content-between">
                                                                                <h6>Dr. {doctor.fullName} </h6>
                                                                                <input type='radio' checked={currentDoctor && currentDoctor.id == doctor.id ? true : false} ></input>
                                                                            </div>
                                                                            <hr className="mt-2 mb-2" />
                                                                            <h6 className="mb-1">{doctor.title}</h6>
                                                                            {/* <h6>Consultant Patients : <span>150</span></h6> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                :
                                                <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mt-5 mx-auto">
                                        <div className="">
                                            <Link to={"/patients"} className="btn save-del btn-danger px-5 mx-2" style={{ padding: "7px", height: "40px", borderRadius: "10px" }}>{props.t("cancel")}</Link>
                                        </div>
                                        <button className="btn-one" disabled={currentDoctor && currentDoctor.id ? false : true } type="submit" 
                                            onClick={() => { isEdit ? changeDoctor() : assignADoctor(); setIsEdit(false) }}>
                                            { isEdit ? props.t("update") :  props.t("save_and_next")}
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PatientSeeDoctor))
