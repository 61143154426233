import React, { useCallback, useEffect, useMemo, useState } from 'react'
import withRouter from '../../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import {
  getPatientAccountHistory as onGetPatientAccountHistory,
} from '../../../../slice/thunks'
import { useSelector, useDispatch } from "react-redux"
import moment from 'moment'
import { createSelector } from "reselect"
import { Link, useParams } from 'react-router-dom'
import GlobalLoader from '../../../../components/Common/GlobalLoader'
import TableContainer from '../../../../components/Common/TableContainer'

const PatientAccountDetail = (props) => {
    const { id, accountRef, balance } = useParams()
    const dispatch = useDispatch()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            patientAccountHistory: setting.patientAccountHistory,
            error: setting.error,
        })
    )
    const { patientAccountHistory } = useSelector(SettingsProperties)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      loadAccountDetail()
    },[dispatch])

  useEffect(() => {
      if (patientAccountHistory && patientAccountHistory.page > 0) {
          setIsLoading(false)
      }
  },[patientAccountHistory])


    const loadAccountDetail = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
            dispatch(onGetPatientAccountHistory({ id: id, page: pageNumber, size: pageSize}))
        } else {
            dispatch(onGetPatientAccountHistory({ id: id}))
        }
    })



    const columns = useMemo(
        () => [
          {
            header: props.t("no"),
            style: "cat",
            enableColumnFilter: false,
            cell: (cellProps) => {
              return <span>
                  {patientAccountHistory.page == 1 ? (cellProps.row.index + 1) : (((patientAccountHistory.page-1)*10)+cellProps.row.index + 1) }
              </span>;
            },
          },
          {
            header: props.t("date"),
            style: "type",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>
                    {moment(cell.row.original.createAt).format('DD / MM/ YYYY HH:mm')}
                </span>;
            }
          },
          {
            header: props.t("transaction_ref"),
            style: "type",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.transactionRef}</span>
            }
          },
          {
            header: props.t("transaction_type"),
            style: "type",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.transactionType}</span>
            }
          },
          {
            header: props.t("transaction_mode"),
            style: "type",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.transactionMode}</span>
            }
          },
          {
            header: props.t("amount"),
            style: "sale",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.amount}</span>
            }
          },
          {
            header: props.t("balance"),
            style: "sale",
            enableColumnFilter: false,
            cell: (cell) => {
              return <span>{cell.row.original.amountAfterTransaction}</span>
            }
          }
        ],
        [patientAccountHistory]
    );
  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="content-fluide">
                        <div className="view-occupied-wards-section">
                            <div className="container-fluid">
                                <div className="id g-4  px-4 pb-5 ">
                                    <Link to="/cashier/all-sales/3">
                                        <div className="heading-parent-sec">
                                            <div className="heading-child-one-sec pt-4 pb-3">
                                                <h5><i className="fa-solid fa-chevron-left"></i>&nbsp;<b>{props.t("view_transaction_history")}</b></h5>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="row parient-all-list pt-3 pb-3 ">
                                        <div className="col-lg-5 ps-4">
                                            <h6>{props.t("account_number")} <span style={{ color: "#2563EB", fontWeight: "bold" }}>- {accountRef}</span></h6>
                                        </div>
                                        <div className="col-lg-5 ps-4">
                                            <h6>{props.t("balance")} <span style={{ color: "#2563EB", fontWeight: "bold" }}>- {balance}</span></h6>
                                        </div>
                                        <div className="col-lg-1">&nbsp;</div>
                                    </div>

                                    <div className="card border-0 row p-2">
                                        <div className="row">
                                            <TableContainer
                                            columns={columns}
                                            data={((patientAccountHistory && patientAccountHistory.items) || [])}
                                            isGlobalFilter={false}
                                            customPageSize={10}
                                            divClass="card table-card table-warehouse table-responsive"
                                            tableClass="table"
                                            theadClass="thead-sec table-light"
                                            tdClass="td"
                                            tableSize={ patientAccountHistory && patientAccountHistory.size }
                                            tablePage={ patientAccountHistory && patientAccountHistory.page }
                                            tableTotal= { patientAccountHistory && patientAccountHistory.total_items }
                                            tableTotalPage= { patientAccountHistory && patientAccountHistory.total_page }
                                            onNextPage= {loadAccountDetail}
                                            onSearch= {loadAccountDetail}
                                            SearchPlaceholder={props.t("search_patient_account")}
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PatientAccountDetail))
