import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import {
    getPatients as onGetPatients,
    searchPatient as onSearchPatient,
    admitPatient as onAdmitPatient
} from "../../../slice/thunks"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import TableContainer from '../../../components/Common/TableContainer'
import { Link, useNavigate } from "react-router-dom"
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import GlobalLoader from '../../../components/Common/GlobalLoader'


const AllPatients = ({t = (key) => key, isActive = true}) => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const selectLayoutState = (state) => state.Outputs;
  const OutputsProperties = createSelector(
    selectLayoutState,
    (outputs) =>({
      patients: outputs.patients,
      patient: outputs.patient,
      admitPatient: outputs.admitPatient
    })
  )
  const { patients, patient, admitPatient } = useSelector(OutputsProperties)
  const [isLoading, setIsLoading] = useState(true)

  const loadPatients = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetPatients({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetPatients())
    }
  },[dispatch])

  const searchPatients = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onSearchPatient({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onSearchPatient({term: term, page: 1, size: 10}))
    }
  },[dispatch])

  const admitPatients = useCallback((patient) => {
      dispatch(onAdmitPatient({id: patient.id, data: patient}))
  },[dispatch])

  useEffect(() =>{
    if (isActive) {
      loadPatients()
    }
  },[isActive, loadPatients])

  useEffect(() => {
    if (patients && patients.page) {
      setIsLoading(false)
    }
  },[patients])

  useEffect(() => {
    if (admitPatient) {
        history(`/patients/create/${patient.id}/patient_vital-content`)
    }
  },[admitPatient, patient, history])

  function capitalizeName(name) {
    if (!name) return '';
    return name
        .split(' ') 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
        .join(' '); 
  }

  const columns = useMemo(
    () => [
      {
        header: t("s_no"),
        style: "button",
        enableColumnFilter: false,
        cell: (cellProps) => {
          return <span>
              {patients.page === 1 ? (cellProps.row.index + 1) : (((patients.page-1)*10)+cellProps.row.index + 1) }
          </span>;
        },
      },
      {
        header: t("patients_name"),
        accessorKey: "firstName",
        style: "text-start",
        enableColumnFilter: false,
        cell: (cell) => {
            return <span>{capitalizeName((cell.row.original.lastName != null ) ? cell.row.original.firstName+" "+cell.row.original.lastName : cell.row.original.firstName)}</span>
        }
      },
      {
        header: t("phone"),
        accessorKey: "phone",
        style: "text-start",
        enableColumnFilter: false,
      },
      {
        header: t("patients_code"),
        accessorKey: "patientRef",
        style: "text-start",
        enableColumnFilter: false,
        cell: (cellProps) => {
            return (
                <span className="d-flex justify-content-start"> {cellProps.row.original.patientRef}</span>
            )
        }
      },
      {
        header: t("patient_type"),
        accessorKey: "patientType",
        style: "text-start",
        enableColumnFilter: false,
        cell: (cellProps) => {
            return (
                <span className="d-flex justify-content-start"> {cellProps.row.original.patientType.typeName}</span>
            )
        }
      },
      {
        header: t("action"),
        style: "text-start",
        cell: (cellProps) => {
          return (
            <td className="action d-flex justify-content-start px-0" style={{ zIndex: "100" }}>
              <UncontrolledDropdown>
                <DropdownToggle
                  href="#"
                  className="btn btn-soft-secondary btn-sm"
                  tag="button"
                >
                  <i className="fa-solid fa-ellipsis"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link to={`/patients/details/${cellProps.row.original.id}/patient`}>
                    <DropdownItem>
                      {t("view_patient")}
                    </DropdownItem>
                  </Link>
                  {/* <DropdownItem divider /> */}

                  <DropdownItem divider />
                  {cellProps.row.original.patientRecord != null ?
                    <>
                    </>
                    :
                    <>
                      <Link to="#" onClick={() =>admitPatients(cellProps.row.original)}>
                        <DropdownItem>New Visit</DropdownItem>
                      </Link>
                      <DropdownItem divider />
                    </>
                  }
                  {cellProps.row.original.patientVitals != null ?
                    <>
                        <Link to={ cellProps.row.original.patientRecord != null ? `/patients/history/vitals/${cellProps.row.original.id}/${ cellProps.row.original.patientRecord.id}`: `/patients/history/vitals/${cellProps.row.original.id}` }>
                          <DropdownItem>
                              {t("see_vitals")}
                          </DropdownItem>
                        </Link>
                      <DropdownItem divider />
                    </>
                    :
                    <></>
                  }
                  {cellProps.row.original.doctor != null ?
                    <>
                        <Link to={`/patients/create/${cellProps.row.original.id}/see_doctor-content/update`}>
                          <DropdownItem>
                              {t("change_doctor")}
                          </DropdownItem>
                        </Link>
                    </>
                    :<>
                      <Link to={`/patients/create/${cellProps.row.original.id}/see_doctor-content/create`}>
                        <DropdownItem>
                          {t("assign_doctor")}
                        </DropdownItem>
                      </Link>
                    </>
                  }
                  <DropdownItem divider />
                    <Link to={`/patients/history/${cellProps.row.original.id}`}>
                      <DropdownItem>
                          {t("consult_history")}
                      </DropdownItem>
                    </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          );
        },
      },
    ],
    [t, admitPatients, patients]
  );

  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <div className="card patients-list-content border-0 p-0 m-0" id="table1">
              <div className="container">
                  <div className="row ">
                      <div className="pt-1">
                          <div className='justify-content-center'>
                          <TableContainer
                              columns={columns}
                              data={((patients && patients.items) || [])}
                              isGlobalFilter={true}
                              customPageSize={10}
                              divClass="table-card-ward"
                              tableClass="align-middle"
                              theadClass="thead-light table-light"
                              tableSize={ patients && patients.size }
                              tablePage={ patients && patients.page }
                              tableTotal= { patients && patients.total_items }
                              tableTotalPage= { patients && patients.total_page }
                              onNextPage= {loadPatients}
                              onSearch= {searchPatients}
                              SearchPlaceholder={t("search_patient")}
                          />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AllPatients))