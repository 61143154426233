import React from 'react'
import withRouter from '../../../Common/withRouter'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

const GeneralCashier = (props) => {
  return (
    <Modal id='showModal' isOpen={props.isOpen || false} toggle={props.toggleCashier} centered
      className="modal-dialog  modal-dialog-centered modal-xl" style={{ maxWidth: "1140px" }}
    >
      <ModalHeader className="bg-light p-3" toggle={props.toggleCashier}>
      </ModalHeader>
        <ModalBody className="modal-body">
            <div className="gen-lab-rep-print">
                <div className="d-flex justify-content-start m-0 " style={{ gap: "320px" }}>
                    <p className="m-0 p-0">{props.t("reg_no")}</p>
                    <p className="text-danger fw-bold m-0 p-0" style={{ padding: "0", fontSize: "22px" }}>{props.t("tax_invoice")}</p>
                </div>
                <div className="text-center m-0">
                    <div className="d-flex gap-2 justify-content-center m-0 align-items-center">
                        <img src="../assets/images/HMS-Logo-1.png" className="img-fluid" alt="" style={{ width: "50px", }}/>
                        <h4 style={{ fontSize: "22px", color: "#d13e6a", fontWeight:"700", margin: "0", padding: "0" }}>
                            {props.t("hospital_name")}
                        </h4>
                    </div>
                    <h5 style={{ color: "#45a049", fontWeight: "500" }}>{props.t("hospital_location")}</h5>
                    <div className="d-flex justify-content-center gap-3">
                        <p>{props.t("hospital_email")}</p>
                        <p>{props.t("hospital_contact")}</p>
                    </div>
                </div>

                    {props.section && props.section == "sales" ?
                        <>
                            <div className="m-0">
                                <table className="table table-borderless m-0">
                                    <tbody style={{ borderTop: "1px solid black" }}>
                                        <tr>
                                            <th style={{ width: "18%" }}>{props.t("bill_no")}</th>
                                            <td>{props.sale && props.sale.orderRef}</td>

                                            <th>{props.t("patient_id")}</th>
                                            <td>{props.sale && props.sale.patient && props.sale.patient.patientRef}</td>

                                            <th>{props.t("date")}</th>
                                            <td>{props.sale && moment(props.sale.orderDate).format('DD / MM/ YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <th>{props.t("patient_name")}</th>
                                            <td>{props.sale && props.sale.patient && props.sale.patient.firstName} {props.sale && props.sale.patient && props.sale.patient.lastName}</td>

                                            <th>{props.t("gender")}</th>
                                            <td>{props.sale && props.sale.patient && props.sale.patient.gender === "M" ? props.t("male") : props.t("female") }</td>

                                            {/* <th>{props.t("pr_by")}</th>
                                            <td>Dr. S. WATSON</td> */}
                                        </tr>
                                        <tr>
                                            <th>{props.t("address")}</th>
                                            <td>
                                                {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.address ? props.sale.patient.address.address : ""}
                                                {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.city ? ", "+props.sale.patient.address.city : ""}
                                                {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.region ? ", "+props.sale.patient.address.region : ""}
                                            </td>

                                            {/* <th>{props.t("age")}</th>
                                            <td>25</td> */}

                                            {props.sale && props.sale.patientRecord && props.sale.patientRecord.bedRecords ?
                                                <>
                                                    <th>{props.t("bed_no")}</th>
                                                    <td>VIP 02</td>
                                                </>
                                                :
                                                <></>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-0">
                                <table className="table table-borderless mb-4">
                                    <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                                        <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                                        <th style={{ width: "60%" }}>{props.t("ipd_service_details")}</th>
                                        <th style={{ width: "10%" }} >{props.t("qty")}</th>
                                        <th style={{ width: "10%" }} >{props.t("rate")}</th>
                                        <th style={{ width: "10%" }} >{props.t("discount")}</th>
                                        <th style={{ width: "10%" }} >{props.t("amount")}</th>
                                    </tr>
            
                                    {props.items && props.items.map((row, index) => (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{row.label}</td>
                                            <td >{row.quantity}</td>
                                            <td>{row.unitPrice}</td>
                                            <td>{row.discount}</td>
                                            <td>{row.rowTotal}</td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                            <div className="m-0">
                                <table className="table table-borderless m-0">
                                    
                                    <tr>
                                        <td style={{ width: "10%" }}></td>
                                        <td style={{ width: "60%", visibility: "hidden" }} ></td>
                                        <td style={{ width: "10%" }} ></td>
                                        <td style={{ width: "10%" }} ></td>
                                        <td style={{ width: "10%" }} ></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="m-0">
                                <table className="table table-borderless m-0">
                                    <tr style={{ borderTop: "1px solid black" }}>
                                        <td style={{ width: "10%" }}>
                                        </td>
                                        <td style={{ width: "60%", textAlign: "left" }}></td>
                                        <td style={{ width: "10%", visibility: "hidden" }} ></td>
                                        <td style={{ width: "10%", whiteSpace: "nowrap" }} >
                                            <b> {props.t("total_amount")}.</b>
                                        </td>
                                        <td style={{ width: "10%" }} >
                                            : {props.totalAmount}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "10%" }}>
                                        </td>
                                        <td style={{ width: "60%", textAlign: "left" }}></td>
                                        <td style={{ width: "10%", visibility: "hidden" }} ></td>
                                        <td style={{ width: "10%", whiteSpace: "nowrap" }} >
                                            <b> {props.t("amount_recieved")}.</b>
                                        </td>
                                        <td style={{ width: "10%" }} >
                                            : {props.amountRecieved}
                                        </td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid black" }}>
                                        <td style={{ width: "10%" }}>
                                        </td>
                                        <td style={{ width: "60%", textAlign: "left" }}></td>
                                        <td style={{ width: "10%", visibility: "hidden" }} ></td>
                                        <td style={{ width: "10%", whiteSpace: "nowrap" }} >
                                            <b> {props.t("amount_return")}.</b>
                                        </td>
                                        <td style={{ width: "10%" }} >
                                            : {props.amountRecieved > props.totalAmount ? props.amountRecieved - props.totalAmount : 0}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </>
                        : props.section && props.section == "payment" ?
                        <>
                            <div className="m-0">
                                <table className="table table-borderless m-0">
                                    <tbody style={{ borderTop: "1px solid black" }}>
                                        <tr>
                                            <th style={{ width: "18%" }}>{props.t("bill_no")}</th>
                                            <td>LAB-756</td>

                                            <th>{props.t("patient_id")}</th>
                                            <td>12334</td>

                                            <th>{props.t("date")}</th>
                                            <td>09/02/2024</td>
                                        </tr>
                                        <tr>
                                            <th>{props.t("patient_name")}</th>
                                            <td>ALEX THOMAS</td>

                                            <th>{props.t("gender")}</th>
                                            <td>Male</td>

                                            <th>{props.t("pr_by")}</th>
                                            <td>Dr. S. WATSON</td>
                                        </tr>
                                        <tr>
                                            <th>{props.t("address")}</th>
                                            <td>BIPINDI, CAMEROON, AFRICA</td>

                                            <th>{props.t("age")}</th>
                                            <td>25</td>

                                            <th>{props.t("bed_no")}</th>
                                            <td>VIP 02</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-0">
                                <table className="table table-borderless mb-4">
                                    <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                                        <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                                        <th style={{ width: "60%" }}>{props.t("ipd_service_details")}</th>
                                        <th style={{ width: "10%" }} >{props.t("qty")}</th>
                                        <th style={{ width: "10%" }} >{props.t("rate")}</th>
                                        <th style={{ width: "10%" }} >{props.t("amount")}</th>
                                    </tr>
            
                                    <tr>
                                        <td>1</td>
                                        <td>Operation Charge</td>
                                        <td >1</td>
                                        <td>256.81</td>
                                        <td>256.81</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Bed Charge (VIP WARD 02)</td>
                                        <td >1</td>
                                        <td >85.07</td>
                                        <td >85.07</td>
                                    </tr>
                                
                                </table>
                            </div>
                            <div className="m-0">
                                <table className="table table-borderless m-0">
                                    <tr style={{ borderTop: "1px solid black" }}>
                                        <td style={{ width: "10%" }}>{props.t("incl_taxes_details")}: </td>
                                        <td style={{ width: "60%" }}>203.20x12%= 24.38,<br />
                                            GST 0% AMT: 80.10</td>
                                        <td style={{ width: "10%" }} >{props.t("vat")}: <br />
                                            $ 12.19<br />
                                        </td>
                                        <td style={{ width: "10%" }} >{props.t("gross")} <br />{props.t("less_dis")}</td>
                                        <td style={{ width: "10%" }} >: $ 341. <br />: $ 33.87</td>
            
                                    </tr>
                                    <tr >
                                        <td style={{ width: "10%" }}> </td>
                                        <td style={{ width: "60%", visibility: "hidden" }} >  </td>
                                        <td style={{ width: "10%" }} ></td>
                                        <td style={{ width: "10%" }} ></td>
                                        <td style={{ width: "10%" }} ></td>
                                    </tr>
                                    
                                </table>
                            </div>
                            <div className="m-0">
                                <table className="table table-borderless m-0">
                                    <tr style={{ borderTop: "1px solid black" }}>
                                        <td style={{ width: "10%" }}>E.& O.E. &nbsp; MATHEW  &nbsp;12:26pm</td>
                                        <td style={{ width: "60%", textAlign: "left" }}></td>
                                        <td style={{ width: "10%", visibility: "hidden" }} ></td>
                                        <td style={{ width: "10%", whiteSpace: "nowrap" }} ><b> {props.t("total_amount")}.</b></td>
                                        <td style={{ width: "10%" }} >: $ 308.01</td>
            
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid black" }}>
                                        <td style={{ width: "10%", whiteSpace: "nowrap" }}>USD: Three Hundred and Eight Only </td>
                                        <td style={{ width: "60%" }}></td>
                                        <td style={{ width: "10%" }} > </td>
                                        <td style={{ width: "10%" }} >{props.t("net_amount")}. (R/O)</td>
                                        <td style={{ width: "10%" }} >: $ 308.00</td>
                                    </tr>
                                </table>
                            </div>
                        </>
                        :
                        <>
                            <div className="m-0">
                                <table className="table table-borderless m-0">
                                    <tbody style={{ borderTop: "1px solid black" }}>
                                        <tr>
                                            <th style={{ width: "18%" }}>{props.t("bill_no")}</th>
                                            <td>LAB-756</td>

                                            <th>{props.t("patient_id")}</th>
                                            <td>12334</td>

                                            <th>{props.t("date")}</th>
                                            <td>09/02/2024</td>
                                        </tr>
                                        <tr>
                                            <th>{props.t("patient_name")}</th>
                                            <td>ALEX THOMAS</td>

                                            <th>{props.t("gender")}</th>
                                            <td>Male</td>

                                            <th>{props.t("pr_by")}</th>
                                            <td>Dr. S. WATSON</td>
                                        </tr>
                                        <tr>
                                            <th>{props.t("address")}</th>
                                            <td>BIPINDI, CAMEROON, AFRICA</td>

                                            <th>{props.t("age")}</th>
                                            <td>25</td>

                                            <th>{props.t("bed_no")}</th>
                                            <td>VIP 02</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-0">
                                <table className="table table-borderless mb-4">
                                    <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                                        <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                                        <th style={{ width: "60%" }}>{props.t("ipd_service_details")}</th>
                                        <th style={{ width: "10%" }} >{props.t("qty")}</th>
                                        <th style={{ width: "10%" }} >{props.t("rate")}</th>
                                        <th style={{ width: "10%" }} >{props.t("amount")}</th>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Operation Charge</td>
                                        <td >1</td>
                                        <td>256.81</td>
                                        <td>256.81</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Bed Charge (VIP WARD 02)</td>
                                        <td >1</td>
                                        <td >85.07</td>
                                        <td >85.07</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="m-0">
                                <table className="table table-borderless m-0">
                                    <tr style={{ borderTop: "1px solid black" }}>
                                        <td style={{ width: "10%" }}>{props.t("incl_taxes_details")}: </td>
                                        <td style={{ width: "60%" }}>203.20x12%= 24.38,<br />
                                            GST 0% AMT: 80.10</td>
                                        <td style={{ width: "10%" }} >{props.t("vat")}: <br />
                                            $ 12.19<br />
                                        </td>
                                        <td style={{ width: "10%" }} >{props.t("gross")} <br />{props.t("less_dis")}</td>
                                        <td style={{ width: "10%" }} >: $ 341. <br />: $ 33.87</td>
                                    </tr>
                                    <tr >
                                        <td style={{ width: "10%" }}> </td>
                                        <td style={{ width: "60%", visibility: "hidden" }} >  </td>
                                        <td style={{ width: "10%" }} ></td>
                                        <td style={{ width: "10%" }} ></td>
                                        <td style={{ width: "10%" }} ></td>
                                    </tr>    
                                </table>
                            </div>
                            <div className="m-0">
                                <table className="table table-borderless m-0">
                                    <tr style={{ borderTop: "1px solid black" }}>
                                        <td style={{ width: "10%" }}>E.& O.E. &nbsp; MATHEW  &nbsp;12:26pm</td>
                                        <td style={{ width: "60%", textAlign: "left" }}></td>
                                        <td style={{ width: "10%", visibility: "hidden" }} ></td>
                                        <td style={{ width: "10%", whiteSpace: "nowrap" }} ><b> {props.t("total_amount")}.</b></td>
                                        <td style={{ width: "10%" }} >: $ 308.01</td>
            
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid black" }}>
                                        <td style={{ width: "10%", whiteSpace: "nowrap" }}>USD: Three Hundred and Eight Only </td>
                                        <td style={{ width: "60%" }}></td>
                                        <td style={{ width: "10%" }} > </td>
                                        <td style={{ width: "10%" }} >{props.t("net_amount")}. (R/O)</td>
                                        <td style={{ width: "10%" }} >: $ 308.00</td>
                                    </tr>
                                </table>
                            </div>
                        </>
                    }

                {/* Footer  */}
                <div className="text-center">
                    <p className="text fw-bold m-0 p-0 " style={{ fontSize: "22px", color: "#d13e6a" }}>We Care We Protect</p>
                    <p className="fw-bold text-danger m-0 p-0">
                        Book Your Free Home Blood Sample Collection On:
                        <span className="text-primary" style={{ fontSize: "16px" }}>+237 6 75 55 38 64</span></p>
                </div>
            </div>
        </ModalBody>
    </Modal>
  )
}

export default withRouter(withTranslation()(GeneralCashier))