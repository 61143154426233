import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link, useNavigate } from "react-router-dom"
import { createSelector } from "reselect"
import makeAnimated from "react-select/animated"
import moment from 'moment'
import {
    getTransfertOrders as onGetTransfertOrders,
  } from "../../../slice/thunks"
import TableContainerWarehouse from '../../../components/Common/TableContainerWarehouse'

function TransfertOrder(props) {
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            transfertOrders: setting.transfertOrders,
            error: setting.error
        })
    )
    const { transfertOrders, error } = useSelector(SettingsProperties)

    const loadTransfertOrder = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
          dispatch(onGetTransfertOrders({page: pageNumber, size: pageSize}))
        } else {
          dispatch(onGetTransfertOrders())
        }
    })

    const searchTransfertOrder = useCallback((term, pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
            dispatch(loadTransfertOrder({term: term, page: pageNumber, size: pageSize}))
        } else {
            dispatch(loadTransfertOrder({term: term, page: 1, size: 10}))
        }
    })

    useEffect(() => {
        loadTransfertOrder()
    },[dispatch])


    const columns = useMemo(
        () => [
          {
            header: props.t("date"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                          <span>
                            {moment(cell.row.original.orderDate).format('DD / MM/ YYYY')}
                          </span>
                  </div>
            }
          },
          {
            header: props.t("transfertNo"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.orderRef}</span>
            }
          },
          {
            header: props.t("sector"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.transferTo}</span>
            }
          },
          {
            header: props.t("doneBy"),
            style: "main",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{cell.row.original.doneBy}</span>
            }
          },
          {
            header: props.t("status"),
            accessorKey: "status",
            style: "main",
            enableColumnFilter: false,
          },
          {
            header: props.t("action"),
            style: "action",
            cell: (cellProps) => {
              return (
                <div className="d-flex justify-content-center">
                    <ul className="list-inline hstack mb-0">

                      { cellProps.row.original.status !== 'Confirmed' ?
                        <>
                            <li className="list-inline-item edit" title={props.t("edit")}>
                                <Link
                                    to={`/warehouse/transfert-order-new/${cellProps.row.original.id}/edit`}
                                    className="text-primary d-inline-block edit-item-btn"
                                >
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item edit" title={props.t("validate")}>
                                <Link
                                to={`/warehouse/transfert-order-new/${cellProps.row.original.id}/validate`}
                                className="text-primary d-inline-block edit-item-btn"
                                >
                                <i className="fa fa-check" aria-hidden="true"></i>
                                </Link>
                            </li>
                        </>
                            : 
                        <>
                            <li className="list-inline-item edit" title={props.t("edit")}>
                                <Link
                                    to={`/warehouse/transfert-order-new/${cellProps.row.original.id}/see`}
                                    className="text-primary d-inline-block edit-item-btn"
                                >
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                </Link>
                            </li>
                        </>

                      }
                      {/* <li className="list-inline-item" title="Remove">
                        <Link
                          to="#"
                          className="text-danger d-inline-block remove-item-btn"
                          onClick={() => { const supplierData = cellProps.row.original; onClickDelete(supplierData); }}
                        >
                          <img src="../assets/images/delete.png" alt=""></img>
                        </Link>
                      </li> */}
                    </ul>
                </div>
              );
            },
          },
        ],
        []
    );



  return (
    <div className="patients-list-content">
            {/* <!-- Create patients Start --> */}
            <div className="patients-list  px-2 pt-4 pb-5 ">
                <div className="container-fluid">
                    {/* <!-- Title-area  --> */}

                    <div className="heading-parent-sec">
                        <div className="heading-child-one-sec">
                            <h6>{props.t("transfert_orders")}</h6>
                        </div>
                        <div className="heading-child-two-sec d-flex justify-content-end align-items-center">
                            <div className="create-new-patient-btn my-2 ps-2">
                                <img src="../assets/images/new-patient/img-one.png" className="img-fluid" />
                                <div className="create-new-patient-btn-child-two">
                                    <Link to="/warehouse/transfert-order-new">
                                        <button className="btn">{props.t("new_transfert_order")}</button>
                                    </Link>

                                </div>
                            </div>
                            {/* <div className="btn-sec ms-2" style={{ height: "35px",
                            cursor: "pointer",
                            borderRadius: "5px",
                            background: "linear-gradient(166.59deg, #2563EB 3.59%, #153885 90.92%)"}}>
                                <button className="btn btn-two-sec" data-bs-toggle="modal" data-bs-target="#exampleModalSec"><img src="../assets/images/warehouse/Vector.png" className="img-fluid pb-2"/></button>
                            </div> */}
                        </div>
                    </div>

                    <div className="row pb-2">
                        {/* <!-- TAB 5 : See Doctor's Patient --> */}
                        <div className="see-doctor-patient ">
                            <div className="row">
                                <TableContainerWarehouse
                                    columns={columns}
                                    data={((transfertOrders && transfertOrders.items) || [])}
                                    isGlobalFilter={false}
                                    customPageSize={10}
                                    divClass="card table-card table-warehouse table-responsive"
                                    tableClass="table"
                                    theadClass="thead-sec table-light"
                                    tdClass="td"
                                    tableSize={ transfertOrders && transfertOrders.size }
                                    tablePage={ transfertOrders && transfertOrders.page }
                                    tableTotal= { transfertOrders && transfertOrders.total_items }
                                    tableTotalPage= { transfertOrders && transfertOrders.total_page }
                                    onNextPage= {loadTransfertOrder}
                                    onSearch= {searchTransfertOrder}
                                    SearchPlaceholder={props.t("search_transfert_order")}
                                />
                                {/* <div className="card table-card table-responsive">
                                    
                                    <table className="table ">
                                        <thead className="thead-sec table-light ">
                                            <tr>
                                                <th style={{ width: "19%" }} className="ps-3">Date</th>
                                                <th style={{ width: "18%" }}>Bill Payment No.</th>
                                                <th style={{ width: "16%" }}>Contact/Account</th>
                                                <th style={{ width: "17%" }}>Amount</th>
                                                <th style={{ width: "16%" }}>Balance</th>
                                                <th style={{ width: "8%" }}>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody className="output-sno">
                                            <tr>
                                                <td className="ps-3">10/10/2020</td>
                                                <td>PMT01</td>
                                                <td>Ajeet Pharmacy</td>
                                                <td>Fr.1000</td>
                                                <td>
                                                    Fr.1000
                                                </td>
                                                
                                                <td>
                                                    <div className="eye-pen">
                                                        <a href="#"><i
                                                                className="fa-solid fa-eye"></i></a>
                                                        <a href="#"><i
                                                                className="fa-solid fa-pencil"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="ps-3">10/12/2020</td>
                                                <td>PMT02</td>
                                                <td>JK Pharmacy</td>
                                                <td>Fr.2000</td>
                                                <td>
                                                    Fr.2000
                                                </td>
                                               
                                                <td>
                                                    <div className="eye-pen">
                                                        <a href="#"><i
                                                                className="fa-solid fa-eye"></i></a>
                                                        <a href="#"><i
                                                                className="fa-solid fa-pencil"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="ps-3">10/13/2020</td>
                                                <td>PMT03</td>
                                                <td>John Pharmacy</td>
                                                <td>Fr.1000</td>
                                                <td>
                                                    Fr.1000
                                                </td>
                                            
                                                <td>
                                                    <div className="eye-pen">
                                                        <a href="#"><i
                                                                className="fa-solid fa-eye"></i></a>
                                                        <a href="#"><i
                                                                className="fa-solid fa-pencil"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="ps-3">10/14/2020</td>
                                                <td>PMT04</td>
                                                <td>Robert Pharmacy</td>
                                                <td>Fr.2000</td>
                                                <td>
                                                    Fr.2000
                                                </td>
                                              
                                                <td>
                                                    <div className="eye-pen">
                                                        <a href="#"><i
                                                                className="fa-solid fa-eye"></i></a>
                                                        <a href="#"><i
                                                                className="fa-solid fa-pencil"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="ps-3">10/15/2020</td>
                                                <td>PMT05</td>
                                                <td>Ajeet Pharmacy</td>
                                                <td>Fr.1000</td>
                                                <td>
                                                    Fr.1000
                                                </td>
                                               
                                                <td>
                                                    <div className="eye-pen">
                                                        <a href="#"><i
                                                                className="fa-solid fa-eye"></i></a>
                                                        <a href="#"><i
                                                                className="fa-solid fa-pencil"></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="ps-3">10/16/2020</td>
                                                <td>PMT06</td>
                                                <td>JKL Pharmacy</td>
                                                <td>Fr.2000</td>
                                                <td>
                                                    Fr.2000
                                                </td>
                                                
                                                <td>
                                                    <div className="eye-pen">
                                                        <a href="#"><i
                                                                className="fa-solid fa-eye"></i></a>
                                                        <a href="#"><i
                                                                className="fa-solid fa-pencil"></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="ps-3">10/17/2020</td>
                                                <td>PMT07</td>
                                                <td>John Pharmacy</td>
                                                <td>Fr.1000</td>
                                                <td>
                                                    Fr.1000
                                                </td>
                                                
                                                <td>
                                                    <div className="eye-pen">
                                                        <a href="#"><i
                                                                className="fa-solid fa-eye"></i></a>
                                                        <a href="#"><i
                                                                className="fa-solid fa-pencil"></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="ps-3">10/18/2020</td>
                                                <td>PMT08</td>
                                                <td>Robert Pharmacy</td>
                                                <td>Fr.2000</td>
                                                <td>
                                                    Fr.2000
                                                </td>
                                              
                                                <td>
                                                    <div className="eye-pen">
                                                        <a href="#"><i
                                                                className="fa-solid fa-eye"></i></a>
                                                        <a href="#"><i
                                                                className="fa-solid fa-pencil"></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="ps-3">10/19/2020</td>
                                                <td>PMT09</td>
                                                <td>Ajeet Pharmacy</td>
                                                <td>Fr.1000</td>
                                                <td>
                                                    Fr.1000
                                                </td>
                                              
                                                <td>
                                                    <div className="eye-pen">
                                                        <a href="#"><i
                                                                className="fa-solid fa-eye"></i></a>
                                                        <a href="#"><i
                                                                className="fa-solid fa-pencil"></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="ps-3">10/20/2020</td>
                                                <td>PMT10</td>
                                                <td>John Pharmacy</td>
                                                <td>Fr.2000</td>
                                                <td>
                                                    Fr.2000
                                                </td>
                                                
                                                <td>
                                                    <div className="eye-pen">
                                                        <a href="#"><i
                                                                className="fa-solid fa-eye"></i></a>
                                                        <a href="#"><i
                                                                className="fa-solid fa-pencil"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination ps-3">
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <img src="../assets/images/left.png" alt="" />
                                                </a>
                                            </li>
                                            <li className="page-item active" aria-current="page">
                                                <span className="page-link">1</span>
                                            </li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    <img src="../assets/images/right.png" alt="" />
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default withRouter(withTranslation()(TransfertOrder))
