import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import withRouter from '../../../../components/Common/withRouter'
import {
    getRecordPatient as onGetRecordPatient,
} from "../../../../slice/thunks"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import TableContainer from '../../../../components/Common/TableContainer'
import GlobalLoader from '../../../../components/Common/GlobalLoader'


const PatientHistory = (props) => {
    let { id } = useParams()
    const dispatch = useDispatch()
    const selectLayoutState = (state) => state.Outputs;
    const [isLoading, setIsLoading] = useState(true)
    const OutputsProperties = createSelector(
        selectLayoutState,
        (outputs) =>({
            patientHistory: outputs.patientHistory,
            // patient: outputs.patient,
        })
    )
    const { patientHistory } = useSelector(OutputsProperties)

    const loadPatientHistory = useCallback(() => {
        dispatch(onGetRecordPatient({ id: id }))
    }, [dispatch, id])

    useEffect(() =>{
        if (id) {
            loadPatientHistory()
        }
    },[dispatch, id, loadPatientHistory])

    useEffect(() => {
        if (patientHistory && patientHistory.page) {
            setIsLoading(false)
        }
    },[patientHistory])


    const columns = useMemo(
        () => [
          {
            header: props.t("no"),
            accessorKey: "index",
            style: "button",
            enableColumnFilter: false,
            cell: (cellProps) => {
                return <span>{cellProps.row.index + 1}</span>;
            },
          },
          {
            header: props.t("ref_id"),
            accessorKey: "ref",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{ cell.row.original.ref }</span>
            }
          },
          {
            header: props.t("entry_date"),
            accessorKey: "entryDate",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cellProps) => {
                return (
                    <span className="d-flex justify-content-start">
                        {moment(cellProps.row.original.entryDate).format('DD/MM/YYYY')}
                    </span>
                )
            }
          },
          {
            header: props.t("release_date"),
            accessorKey: "releaseDate",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cellProps) => {
                return (
                    <span className="d-flex justify-content-start"> {cellProps.row.original.releaseDate}</span>
                )
            }
          },
          {
            header: props.t("action"),
            style: "text-start",
            cell: (cellProps) => {
              return (
                <td className="action d-flex justify-content-start px-0">
                    <Link to={`/patients/history_details/${cellProps.row.original.id}`}>
                        <i className="fa fa-eye"></i>
                    </Link>
                </td>
              );
            },
          },
        ],
        [props]
    );


  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className='patients-list-content'>
                    <div className="patients-list py-4 px-2  ">
                        <p></p>
                        <div className="patients-list-content p-0 m-0" id="table1">
                            <div className="container">
                                <div className="row d-flex align-items-center justify-content-between">
                                    <div className="col-lg-4">
                                        <Link to="/patients" >
                                            <div className="heading-child-one-sec d-flex align-items-center">
                                                    <i className="fa-solid fa-chevron-left"></i>
                                                <h6 className='mb-0'>{props.t("patient_visits")}</h6>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="pt-1">
                                        <div className='justify-content-center'>
                                            <TableContainer
                                                columns={columns}
                                                data={((patientHistory && patientHistory.items) || [])}
                                                isGlobalFilter={false}
                                                customPageSize={10}
                                                divClass="card table-card table-card-ward p-0"
                                                tableClass="table align-middle"
                                                tdClass="py-0 text-start"
                                                theadClass="thead-light table-light"
                                                tableSize={ patientHistory && patientHistory.size }
                                                tablePage={ patientHistory && patientHistory.page }
                                                tableTotal= { patientHistory && patientHistory.total_items }
                                                tableTotalPage= { patientHistory && patientHistory.total_page }
                                                onNextPage= {loadPatientHistory}
                                                onSearch= {loadPatientHistory}
                                                SearchPlaceholder={props.t("search_patient")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PatientHistory))
