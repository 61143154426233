import React from 'react'

const NavItemCs = ({ icon, label, target, active, onTabChange}) => {

  return (
    <React.Fragment>
        <li className="nav-item" role="presentation" onClick={() => { onTabChange(target) }}>
            <button className={active ? "nav-link active" : "nav-link"} id="pills-home-tab" data-bs-toggle="pill" data-bs-target={"#"+target} type="button" role="tab" aria-controls="pills-home" aria-selected={active ? "true" : "false"}>
                <i className={"fa-solid "+icon}></i>
                { label }
            </button>
        </li>
    </React.Fragment>
  )
}

export default NavItemCs