import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { withTranslation  } from 'react-i18next'
import { ToastContainer } from 'react-toastify';
import { createSelector } from 'reselect'
import { Link } from "react-router-dom"
import { Spinner } from 'reactstrap'
import { useFormik } from "formik"
import * as Yup from "yup"

import 'react-toastify/dist/ReactToastify.css';

import { loginUser, resetLoginFlag } from "../../../slice/thunks"
import withRouter from '../../../components/Common/withRouter'


const Login = (props) => {
  const dispatch = useDispatch()
  const selectLayoutState = (state) => state
  const loginpageData = createSelector(
    selectLayoutState,
    (state) => ({
      loading: state.Login.loading,
      errorMsg: state.Login.errorMsg,
    })
  )
  const {
    loading, errorMsg
  } = useSelector(loginpageData)

  const [passwordShow, setPasswordShow] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,
    
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: Yup.object({
      username: Yup.string().required(props.t("required_username")),
      password: Yup.string().required(props.t("required_password"))
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate))
    }
  })

  useEffect(() => {
    if (errorMsg) {
        setTimeout(() => {
            dispatch(resetLoginFlag());
        }, 3000);
    }
}, [dispatch, errorMsg]);


  document.title = props.t("login_title")
  return (
    <div>
      <section className="login">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6  order-lg-1  order-md-1  order-sm-2 background-img">
                </div>
                <div className="col-lg-6 order-lg-2 order-md-2 order-sm-2 order-lg-2 mt-1">
                    <div className="card-body align-items-center  mx-md-4 card-padding">
                        <div className="text-center">
                            <img src="./assets/images/LOGO.png" style={{ width: '185px' }} alt="logo" />
                            <h4 className="mt-3 mb-2 ">{props.t("sign_in_to_your_account")}</h4>
                            <p className="mb-3 text-muted">{props.t("welcome_back_and_detail")}</p>
                        </div>
                        <form 
                          className="login-form mx-auto"
                          autoComplete='off'
                          onSubmit={(e) =>{
                            e.preventDefault()
                            validation.handleSubmit()
                            return false;
                          }}
                        >
                            <div data-mdb-input-init className="form-outline">
                                <input 
                                  id="username" 
                                  type="text" 
                                  name ="username"
                                  className="form-control" 
                                  placeholder={props.t("username")}
                                  onChange={validation.handleChange}
                                  autoComplete="off"
                                  onBlur={validation.handleBlur}
                                  value={validation.values.username || ""}
                                  invalid = { validation.touched.username && validation.errors.username }
                                  
                                />
                                <label className="form-label" htmlFor="username"></label>
                                <i className="fa fa-user"style={{position: 'absolute', top: '35%', left: '20px', transform: 'translateY(-50%)', fontSize: '16px', color: '#64748b'}}></i>
                            </div>
                            {validation.touched.username && validation.errors.username ? (
                              <div className="alert alert-danger p-2 text-center" role="alert">
                                {validation.errors.username}
                              </div>
                            ) : null}

                            <div data-mdb-input-init className="form-outline mb-1" style={{position: 'relative'}}>
                                <input 
                                  id="pass"
                                  name="password"
                                  type={passwordShow ? "text" : "password"}
                                  className="form-control" 
                                  placeholder={props.t("password")}
                                  autoComplete="new-password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.password || ""}
                                  invalid = { validation.touched.password && validation.errors.password }
                                />
                                <label className="form-label" htmlFor="pass"></label>
                                  <i className="fa fa-lock" style={{ position: 'absolute', top: '35%', left: '20px', transform: 'translateY(-50%)', cursor: 'pointer', fontSize: '16px', color: '#64748b'}}></i>
                                  <i 
                                    className={passwordShow ? "fa fa-eye" : "fa fa-eye-slash"}
                                    id="togglePassword" 
                                    onClick={() => setPasswordShow(!passwordShow)}
                                    style={{ position: 'absolute', top: '40%', right: '20px', transform: 'translateY(-50%)', cursor: 'pointer', fontSize: '16px', color: '#64748b' }}
                                  ></i>
                            </div>
                            {validation.touched.password && validation.errors.password ? (
                              <div className="alert alert-danger p-2 text-center" role="alert">
                                {validation.errors.password}
                              </div>
                            ) : null}

                            

                            <div className="text-center pt-1 mb-2 pb-1">
                                <button 
                                  className="btn btn-login mb-3" 
                                  type="submit"
                                >
                                  {loading ? (<Spinner color="light">Loading...</Spinner>) : <>{props.t("sign_in")}</> }
                                </button>
                            </div>
                        </form>



                    </div>
                </div>
            </div>
        </div>
      </section>


      {/* <!-- loginSuccess modal --> */}
      <div className="modal fade" id="siginSuccessModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
          tabIndex="-1">
          <div className="modal-dialog modal-sm modal-dialog-centered">
              <div className="modal-content">
                  <div className="modal-header">
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                          style={{ borderRadius: '50%', border: '1px solid #000' }}></button>
                  </div>
                  <div className="correct">
                      <img src="./assets/images/correct.png" alt="" />
                  </div>
                  <div className="modal-body pt-0">
                      <h5>{props.t("congratulations")}</h5>
                      <p>{props.t("successfully_log")}</p>
                      <div className="text-center">
                        <button type="button" className="btn">{props.t("continue")}</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      
      <ToastContainer closeButton={false} limit={5} />
    </div>
  )
}

export default withRouter(withTranslation()(Login));