import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import withRouter from '../../components/Common/withRouter'

const Settings = (props) => {

    const permission = JSON.parse(localStorage.getItem("perm"));

  return (
    <div className="dashboard">
        {/* <!-- Start settings-three-settings Section --> */}
        <div className="settings-three-settings-sec py-3 px-2">
            <div className="container">
                <div className="heading-parent-sec">
                    <h6>{props.t("settings")}</h6>
                </div>
                <div className="settings-three-main-settings-sec ps-4">
                    <div className="container">
                        <div className="row-parent-sec">
                            {permission && (
                                    permission.includes("ALL_PERMISSIONS") ||
                                    permission.includes("VIEW_USERS") || permission.includes("ADD_USERS") || permission.includes("UPDATE_USERS") || permission.includes("DELETE_USERS") ||
                                    permission.includes("VIEW_ROLES") || permission.includes("ADD_ROLES") || permission.includes("UPDATE_ROLES") || permission.includes("DELETE_ROLES")
                                ) ?
                                    <div className="row-child-sec">
                                        <Link to="/settings/user_management">
                                            <div className="img-sec">
                                                <img alt="" src="./assets/images/settings-three-settings/settings-one.png" className="img-fluid" />
                                            </div>
                                            <div className="text-sec">
                                                <p>{props.t("user_managment")}</p>
                                            </div>
                                        </Link>
                                    </div>
                                    :<></>
                            }
                            {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("VIEW_DOCTORS") || permission.includes("ADD_DOCTORS") || permission.includes("UPDATE_DOCTORS") || permission.includes("DELETE_DOCTORS")) ?
                                <div className="row-child-sec">
                                    <Link to="/settings/doctors">
                                        <div className="img-sec">
                                            <img alt="" src="./assets/images/settings-three-settings/settings-one.png" className="img-fluid" />
                                        </div>
                                        <div className="text-sec">
                                            <p>{props.t("doctor_management")}</p>
                                        </div>
                                    </Link>
                                </div>
                                :<></>
                            }
                            {permission && (
                                    permission.includes("ALL_PERMISSIONS") 
                                    // ||
                                    // permission.includes("VIEW_USERS") || permission.includes("ADD_USERS") || permission.includes("UPDATE_USERS") || permission.includes("DELETE_USERS") ||
                                    // permission.includes("VIEW_ROLES") || permission.includes("ADD_ROLES") || permission.includes("UPDATE_ROLES") || permission.includes("DELETE_ROLES")
                                ) ?
                                    <div className="row-child-sec">
                                        <Link to="/settings/guarantors">
                                            <div className="img-sec">
                                                <img alt="" src="./assets/images/settings-three-settings/settings-one.png" className="img-fluid" />
                                            </div>
                                            <div className="text-sec">
                                                <p>{props.t("hospital_staff")}</p>
                                            </div>
                                        </Link>
                                    </div>
                                    :<></>
                            }
                            {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("VIEW_CATEGORIES") || permission.includes("ADD_CATEGORIES") || permission.includes("UPDATE_CATEGORIES") || permission.includes("DELETE_CATEGORIES")) ?
                                <div className="row-child-sec">
                                    <Link to="/settings/categories">
                                        <div className="img-sec">
                                            <span className='img-fluid' style={{ color: "#2563EB" }}>
                                                <i className="fa-solid fa-list fa-5x" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div className="text-sec">
                                            <p>{props.t("categories")}</p>
                                        </div>
                                    </Link>
                                </div>
                                :<></>
                            }
 
                            <div className="row-child-sec">
                                <Link to="/settings/productsDiscount">
                                    <div className="img-sec">
                                        <img alt="" src="./assets/images/settings-three-settings/settings-three.png"
                                            className="img-fluid" />
                                    </div>
                                    <div className="text-sec">
                                        <p>{props.t("product_discounts")}</p>
                                    </div>
                                </Link>
                            </div>

                            <div className="row-child-sec">
                                <Link to="/settings/financial-year">
                                    <div className="img-sec">
                                        <img alt="" src="./assets/images/settings-three-settings/settings-three.png"
                                            className="img-fluid" />
                                    </div>
                                    <div className="text-sec">
                                        <p>{props.t("financial_year")}</p>
                                    </div>
                                </Link>
                            </div>

                            <div className="row-child-sec">
                                <Link to="/settings/accounting">
                                    <div className="img-sec">
                                        <img alt="" src="./assets/images/settings-three-settings/settings-twelve.png"
                                            className="img-fluid" />
                                    </div>
                                    <div className="text-sec">
                                        <p>{props.t("accounting")}</p>
                                    </div>
                                </Link>
                            </div> 

                            {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("VIEW_WARDS") || permission.includes("ADD_WARDS") || permission.includes("UPDATE_WARDS") || permission.includes("DELETE_WARDS")) ?
                                <div className="row-child-sec">
                                    <Link to="/settings/wards">
                                        <div className="img-sec">
                                            <img alt="" src="../assets/images/settings-three-settings/settings-thirteen.png"
                                                className="img-fluid" />
                                        </div>
                                        <div className="text-sec">
                                            <p>{props.t("wards")}</p>
                                        </div>
                                    </Link>
                                </div>
                                :<></>
                            }

                            {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("VIEW_BEDS") || permission.includes("ADD_BEDS") || permission.includes("VIEW_BEDS") || permission.includes("UPDATE_BEDS") || permission.includes("DELETE_BEDS")) ?
                                <div className="row-child-sec">
                                    <Link to="/settings/beds">
                                        <div className="img-sec">
                                            <img alt="" src="../assets/images/settings-three-settings/settings-fourteen.png"
                                                className="img-fluid" />
                                        </div>
                                        <div className="text-sec">
                                            <p>{props.t("beds")}</p>
                                        </div>
                                    </Link>
                                </div>
                                :<></>
                            }

                            {permission && (permission.includes("ALL_PERMISSIONS")) ?
                                <div className="row-child-sec">
                                    <Link to="/settings/cash-desk">
                                        <div className="img-sec">
                                            <img alt="" src="./assets/images/settings-three-settings/settings-three.png"
                                                className="img-fluid" />
                                        </div>
                                        <div className="text-sec">
                                            <p>{props.t("cash_desk")}</p>
                                        </div>
                                    </Link>
                                </div>
                                :<></>
                            }
                            <div className="row-child-sec">
                                    <Link to="/settings/print">
                                        <div className="img-sec">
                                            <img src="../assets/images/settings-three-settings/settings-five.png"
                                                className="img-fluid" />
                                        </div>
                                        <div className="text-sec">
                                            <p>{props.t("print")}</p>
                                        </div>
                                    </Link>
                                </div>
                            

                            

                        </div>
                    </div>

                </div>
            </div>
        </div>
        {/* <!-- End settings-three-settings Section --> */}
    </div>
  )
}

export default withRouter(withTranslation()(Settings))