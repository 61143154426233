import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link, useNavigate, useParams } from "react-router-dom"
import { createSelector } from "reselect"
import makeAnimated from "react-select/animated"
import {
  addSupplierOrder as onAddSupplierOrder,
  updateSupplierOrder as onUpdateSupplierOrder,
  confirmSupplierOrder as onConfirmSupplierOrder,
  getSupplierOrder as onGetSupplierOrder,
  getAllSuppliers as onGetAllSuppliers,
  getProductsAndSearch as onGetProductsAndSearch,
  deleteSupplierOrderItem as onDeleteSupplierOrderItem,
} from "../../../slice/thunks"
import { Input, Label } from 'reactstrap'
import PurchaseRow from '../../../components/App/Warehouse/PurchaseRow'
import DeleteModal from '../../../components/Global/DeleteModal'

const NewSuppliersOrder = (props) => {
  const { id, action } = useParams()
  const dispatch = useDispatch()
  const history = useNavigate()
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            allSuppliers: setting.allSuppliers,
            productsList: setting.productsList,
            supplierOrder: setting.supplierOrder,
            createSupplierOrderSuccess: setting.createSupplierOrderSuccess,
            updateSupplierOrderSuccess: setting.updateSupplierOrderSuccess,
            confirmSupplierOrderSuccess: setting.confirmSupplierOrderSuccess,
            deleteSupplierOrderSuccess: setting.deleteSupplierOrderSuccess,
            error: setting.error
        })
    )
    const { allSuppliers, productsList, supplierOrder, createSupplierOrderSuccess, updateSupplierOrderSuccess, confirmSupplierOrderSuccess, deleteSupplierOrderSuccess, error } = useSelector(SettingsProperties)
    const [currentSupplierOrder, setCurrentSupplierOrder ] = useState(null)
    const [suppliersOption, setSupplierOption] = useState(null)
    const [productsOption, setProducstOption] = useState(null)
    const [selectedSupplier, setSellectedSupplier] = useState(null)
    const [selectedProduct, setSellectedProduct] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [supplierOrderItems, setSupplierOrderItems] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isValidate, setIsValidate] = useState(false)
    const [isWatch, setIsWatch] = useState(false)
    const status = [
      { label: props.t("pending"), value: "Pending"},
      { label: props.t("confirmed"), value: "Confirmed"},
      { label: props.t("canceled"), value: "Canceled"},
      { label: props.t("finished"), value: "Finished"},
    ]

    const loadAllSuppliers = useCallback(() => {
      dispatch(onGetAllSuppliers())
    })

    const loadSupplierOrder = useCallback((currentId) => {
      if (currentId) {
        dispatch(onGetSupplierOrder(currentId))
      }
    })

    const loadAllProduct = useCallback((term) => {
      if (term && term.length > 0) {
        dispatch(onGetProductsAndSearch(term))
      } else {
        dispatch(onGetProductsAndSearch())
      }
    })


    useEffect(() => {
      if (action && action === 'edit') {
        setIsEdit(true)
      } else if (action && action === 'validate') {
        setIsEdit(true)
        setIsValidate(true)
      } else if (action && action === 'see') {
        setIsWatch(true)
        setIsValidate(true)
      }
    },[action])

    useEffect(() => {
      loadAllSuppliers();
      loadAllProduct();
      if ((action === 'edit') || action === 'validate' || action === 'see') {
        loadSupplierOrder(id)
      }
    },[dispatch])

    useEffect(() =>{
      if (allSuppliers && allSuppliers.length > 0) {
        const suppliers_list = allSuppliers.map( sup => {
          return { label: sup.companyName, value: sup.id }
        })
        setSupplierOption(suppliers_list)
      }
    },[allSuppliers])

    useEffect(() =>{
      if (productsList && productsList.length > 0) {
        const products_list = productsList.map( prod => {
          return { label: prod.name, value: prod.id, price: prod.purchasePrice }
        })
        setProducstOption(products_list)
      }
    },[productsList])

    useEffect(() => {
      if (supplierOrder && supplierOrder.orderRef) {
        setSellectedSupplier({ label: supplierOrder.supplier.companyName, value: supplierOrder.supplier.id })
        setSelectedDate(supplierOrder.orderDate)
        let Objproducts = supplierOrder.supplierOrderItems.map(item => {
          return {
            id: item.id,
            productId: item.product.id,
            name: item.product.name,
            price: item.amount,
            amount: (item.quantity*item.amount),
            quantity: item.quantity
          };
        });

        setSupplierOrderItems(Objproducts)
      }
    }, [supplierOrder])

    useEffect(() => {
      if (createSupplierOrderSuccess && !error) {
        setIsEdit(false)
        setSelectedDate(null)
        setSellectedSupplier(null)
        setSellectedProduct(null)
        setSupplierOrderItems([])
        setTotalAmount(0)
        history("/warehouse/purchase-supplier")
      }
    },[createSupplierOrderSuccess, error, supplierOrder])

    useEffect(() => {
      if (updateSupplierOrderSuccess && !error) {
        history("/warehouse/purchase-supplier")
      }
    },[updateSupplierOrderSuccess, error, supplierOrder])

    useEffect(() => {
      if (confirmSupplierOrderSuccess && !error) {
        history("/warehouse/purchase-supplier")
      }
    },[confirmSupplierOrderSuccess, error, supplierOrder])

    useEffect(() => {
      if (deleteSupplierOrderSuccess && !error) {
        let current_products = [...supplierOrderItems]; 
        current_products = current_products.filter((prod) => prod.productId !== selectedProduct.productId )
        setSupplierOrderItems(current_products);
      }
    },[deleteSupplierOrderSuccess, error, supplierOrder])

    useEffect(() => {
      if (supplierOrderItems.length > 0) {
        let total = 0
        supplierOrderItems.forEach(element => {
          total +=element.amount
        });
        setTotalAmount(total) 
      }
    },[supplierOrderItems])

    useEffect(() => {
      if (selectedProduct !== null) {
          let current_products = [...supplierOrderItems]; 
          if (!current_products.some((current) => current.productId === selectedProduct.productId)) {
            const new_products = [...current_products, selectedProduct]; 
            setSupplierOrderItems(new_products); 
          }
      }
    }, [selectedProduct]);


    const handleSelectSupplier = (sup) => {
      setSellectedSupplier(sup)
    }

    const handleSelectProduct = (sup) => {
      if(sup) {
        let row = {
          productId: sup.value,
          name: sup.label,
          price: sup.price,
          amount: 0,
          quantity: 1
        }
        setSellectedProduct(row)
      }
    }

    const handleSearchProduct = (value) => {
      loadAllProduct(value)
    }

    const handleUpdateRow = (row) => {
      let current_products = [...supplierOrderItems]; 
      const index = current_products.findIndex(objet => objet.productId === row.productId);
      if (index !== -1) {
        current_products[index] = { ...current_products[index], ...row };
        setSupplierOrderItems(current_products); 
      } 
    }

    const handleChangeDate = (val) => {
      setSelectedDate(val)
    }

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  }

    const onClickDelete = (row) => {
      setSellectedProduct(row)
      setDeleteModal(true);
    }

    const handleDelete = () => {
      if (selectedProduct) {
        if (selectedProduct.id) {
          dispatch(onDeleteSupplierOrderItem(selectedProduct.id))
        } else {
          let current_products = [...supplierOrderItems]; 
          current_products = current_products.filter((prod) => prod.productId !== selectedProduct.productId )
          setSupplierOrderItems(current_products); 
        }
        setDeleteModal(false)
      }
    }

    const saveOrder = () => {
      let productsOrder = []

      if (isValidate) {

        productsOrder =  supplierOrderItems.map(item => {
          return {
            id: item.id,
            productId: item.productId,
            amount: item.price,
            quantity: item.quantity,
            deliveryQuantity: item.deliveryQuantity || item.quantity
          };
        });

        let confirmData = {
          orderDate: selectedDate,
          supplierId: selectedSupplier.value,
          status: "",
          supplierOrderItems: productsOrder
        }

        dispatch(onConfirmSupplierOrder({ id: id, data: confirmData }))
      }else if (isEdit) {
        productsOrder =  supplierOrderItems.map(item => {
          return {
            id: item.id,
            productId: item.productId,
            amount: item.price,
            quantity: item.quantity
          };
        });

        let updateData = {
          orderDate: selectedDate,
          supplierId: selectedSupplier.value,
          status: "",
          supplierOrderItems: productsOrder
        }
        dispatch(onUpdateSupplierOrder({ id:id, data:updateData }))

      } else {

        productsOrder =  supplierOrderItems.map(item => {
          return {
            productId: item.productId,
            amount: item.price,
            quantity: item.quantity
          };
        });

        let createData = {
          orderDate: selectedDate,
          supplierId: selectedSupplier.value,
          status: "",
          supplierOrderItems: productsOrder
        }

        dispatch(onAddSupplierOrder(createData))
      }
    }

    const onClear = () => {
      setIsEdit(false)
      setSelectedDate(null)
      setSellectedSupplier(null)
      setSellectedProduct(null)
      setSupplierOrderItems([])
      setTotalAmount(0)
    }

  return (
    <>
      <div className="record-Purchase-contant pb-5">
          <div className="record-list py-4 px-4">
              <h1>{ props.t("record_purchase")}</h1>
              <div className="row  Purchase-order">
                  <h6>
                      <Link to="/warehouse/purchase-supplier">
                          <i className="fa-solid fa-chevron-left"></i> {isWatch ? props.t("purchase_order") : isEdit ? props.t("edit_purchase_order") : props.t("new_purchase_order")}
                      </Link>
                  </h6>
              </div>

              <div className="row py-3 Purchase-list">
                {isEdit ? 
                  <div className="col-lg-2 second-select pt-3">
                      <Label  htmlFor="supplier-field" className="form-label">
                          {props.t("purchase_order_no")}<span className='text-danger'>*</span>
                      </Label>
                      <h4 className='px-2'><b>{ supplierOrder && supplierOrder.orderRef }</b></h4>
                  </div>
                : <></>
                }

                  <div className="col-lg-3 second-select pt-3">
                      <Label  htmlFor="supplier-field" className="form-label">
                          {props.t("purchase_order_data")}<span className='text-danger'>*</span>
                      </Label>
                      <Input 
                          name="dob" id='dob-field' className="form-control" type="date"
                          value={selectedDate}
                          validate={{ required: { value: true } }} 
                          max={getTodayDate()} 
                          onChange={(e) => handleChangeDate(e.target.value)}
                          autoComplete="off"
                          disabled = {isValidate ? true : false}
                      />
                  </div>

                  <div className="col-lg-3 second-select pt-3">
                      <Label  htmlFor="supplier-field" className="form-label">
                          {props.t("supplier_vendor")}<span className='text-danger'>*</span>
                      </Label>
                      <Select
                          name='supplier'
                          id='supplier-field'
                          value={selectedSupplier}
                          isMulti={false}
                          isClearable={false}
                          autoComplete="off" 
                          onChange={(e) => {
                            handleSelectSupplier(e);
                          }}
                          options={suppliersOption}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          isDisabled = {isValidate ? true : false}
                      />
                  </div>

                  <div className="col-lg-3 second-select pt-3">
                      <Label  htmlFor="product-field" className="form-label">
                          {props.t("product")}<span className='text-danger'>*</span>
                      </Label>
                      <Select
                          name='product'
                          id='product-field'
                          value=""
                          isMulti={false}
                          isClearable={false}
                          autoComplete="off"
                          onChange={(e) => {
                            handleSelectProduct(e);
                          }}
                          onInputChange={(inputValue, { action }) => {
                            if (action === 'input-change') {
                              if (inputValue.length > 0) {
                                handleSearchProduct(inputValue)
                              } else {
                                loadAllProduct()
                              }
                            }
                          }}
                          onMenuOpen={() => {
                            loadAllProduct()
                          }}
                          options={productsOption}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          isDisabled = {isValidate ? true : false}
                      />
                  </div>
              </div>

              <div className="Purchase-all-list ">
                  <div className="table table-responsive">
                      <table className="table table-bordered ">
                          <thead>
                              <tr className="first-head">
                                  <th scope="col" style={{ width: "2%", textAlign: "center", padding:"17px" }}>{props.t("s_no")}</th>
                                  <th scope="col" style={{ width: "43%", padding:"17px" }}>{props.t("item")}</th>
                                  <th scope="col" style={{ width: "12%", textAlign: "center", padding:"17px" }}>{props.t("qty")}</th>
                                  <th scope="col" style={{ width: "19%", padding: "17px" }}>{props.t("rate_fcfa")}</th>
                                  <th scope="col" style={{ width: "19%", padding:"17px" }}>{props.t("amount")}</th>
                                  <th scope="col" style={{ width: "5%", textAlign: "center", padding: "17px" }}>{props.t("action")}</th>
                              </tr>
                          </thead>
                          <tbody>

                            {supplierOrderItems.map((row, index) => (
                              <tr key={row.productId}>
                                <PurchaseRow row={row} index={index} isWatch={isWatch} isValidate= {isValidate} updateRow= {handleUpdateRow} deleteRow={onClickDelete}/>
                              </tr>
                            ))}

                              <tr>
                                  <td colSpan="4" rowspan="1" className="sum-total">
                                      <h6>{props.t("sub_total")}</h6>
                                      {/* <h6>Round Off</h6> */}
                                  </td>
                                  <td>{totalAmount} {props.t("current_device")}</td>
                                  {/* <td>&nbsp;</td> */}
                              </tr>
                              {/* <tr>
                                  <td></td>
                                  <td>&nbsp;</td>
                              </tr> */}
                          </tbody>
                      </table>
                      <div className="bttns">
                          <Link to="/warehouse/purchase-supplier" onClick={() => onClear()}>
                            <button className="btn-secondary" style={{ padding: "6px 35px 6px 35px", fontSize: "18px", margin: "8px", textAlign: "center", borderRadius: "8px" }} type="button">{props.t("cancel")}</button>
                          </Link>
                          {!isWatch ?
                            <button 
                              className="btn-two" 
                              onClick={() => saveOrder()}
                              disabled={ (selectedSupplier && selectedSupplier.value) && selectedDate && (supplierOrderItems.length > 0) ? false : true }
                            >
                              {isValidate ? props.t("validate") : isEdit ? props.t("update") :  props.t("save")}
                            </button>
                            :
                            <></>
                          }
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() =>setDeleteModal(false)} />
    </>
  )
}

export default withRouter(withTranslation()(NewSuppliersOrder))
