import * as Yup from "yup"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
  getWards as onGetWards,
  addWard as onAddWard,
  updateWard as onUpdateWard,
  deleteWard as onDeleteWard,
  searchWard as onSearchWard
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"


const Wards = (props) => {
  const dispatch = useDispatch()
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      wards: setting.wards,
      ward: setting.ward,
      ward_success: setting.ward_success,
      error: setting.error
    })
  )
  const { wards, ward, ward_success, error } = useSelector(SettingsProperties)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentWard, setCurrentWard] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const permission = JSON.parse(localStorage.getItem("perm"));
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() =>{
    loadWards()
  },[dispatch])

  useEffect(() => {
    if (wards && wards.page) {
      setIsLoading(false)
    }
  },[wards])

  useEffect(() =>{
    if (ward_success && !error) {
      setIsEdit(false)
      validation.resetForm()
      setModal(false)
      setCurrentWard(null)
      loadWards()
    }
  },[ward_success, error, ward])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (currentWard && currentWard.id) || '',
      wardsName: (currentWard && currentWard.wardsName) || '',
    },
    validationSchema: Yup.object({
      wardsName: Yup.string().required(props.t("you_need_provide_wardname")),
    }),
    onSubmit: (values) => {

      if (isEdit) {
        const updateWardForm = {
          id: currentWard && currentWard.id,
          wardsName: values.wardsName,
        }
        const id = currentWard.id

        dispatch(onUpdateWard({ data: updateWardForm, id: id }))
        

      } else {
        const newWardForm = {
          wardsName: values.wardsName
        }
        dispatch(onAddWard(newWardForm))
      }
    },
  })

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      validation.resetForm();
      setCurrentWard(null);
    } else {
      setModal(true);
    }
  }, [modal])

  const searchWards = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onSearchWard({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onSearchWard({term: term, page: 1, size: 10}))
    }
  },[])

  const loadWards = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetWards({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetWards())
    }
  })

  const handleOpen = () => {
    setIsEdit(false)
    toggle()
  }

  const handleSelectWard = (arg) => {
    const selectedWard = arg
    setCurrentWard({
      id: selectedWard.id,
      wardsName: selectedWard.wardsName,
    })
    setIsEdit(true);
    toggle();
  }

  const onClickDelete = (ward) => {
    setCurrentWard(ward);
    setDeleteModal(true);
  }

  function handleDeleteWards() {
    if (currentWard) {
      dispatch(onDeleteWard({ data: currentWard, id: currentWard.id }))
      loadWards()
      setDeleteModal(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        header: props.t("wardsName"),
        accessorKey: "wardsName",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("action"),
        style: "text-end",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                  {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("UPDATE_WARDS")) ?
                    <li className="list-inline-item edit" title="Edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => { const wardData = cellProps.row.original; handleSelectWard(wardData); }}
                      >
                        <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                      </Link>
                    </li>
                    :<></>
                  }
                  {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("DELETE_WARDS")) ?
                    <li className="list-inline-item" title="Remove">
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => { const wardData = cellProps.row.original; onClickDelete(wardData); }}
                      >
                        <img src="../assets/images/delete.png" alt=""></img>
                      </Link>
                    </li>
                    :<></>
                  }
                </ul>
            </div>
          );
        },
      },
    ],
    [handleSelectWard]
  );


  document.title = props.t("ward_title")
  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <DeleteModal show={deleteModal} onDeleteClick={handleDeleteWards} onCloseClick={() =>setDeleteModal(false)} />
          <Card>
            <div className="ward-list-mode px-3">
                <div className="patients-list py-4 px-2  ">
                    <div className="container-fluid">
                        <div className="row list-patients d-flex  justify-content-between">
                            <div className="col-lg-5">
                              <BreadCrumb title={props.t("wards")} pageTitle={props.t("settings")} />
                            </div>
                            <div className="col-lg-3 new-mode-btn">
                              {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("ADD_WARDS")) ?
                                <SinpleActionButton title={props.t("create_new_ward")} img="../assets/images/user.png" action={handleOpen} />
                                :<></>
                              }
                            </div>
                        </div>

                        <div className="payment-mode-lists">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="card table-card">
                                      <TableContainer
                                        columns={columns}
                                        data={((wards && wards.items) || [])}
                                        isGlobalFilter={true}
                                        customPageSize={10}
                                        divClass="table-card-ward"
                                        tableClass="align-middle"
                                        theadClass="thead-light table-light"
                                        tableSize={ wards && wards.size }
                                        tablePage={ wards && wards.page }
                                        tableTotal= { wards && wards.total_items }
                                        tableTotalPage= { wards && wards.total_page }
                                        onNextPage= {loadWards}
                                        onSearch= {searchWards}
                                        SearchPlaceholder={props.t("search_ward")}
                                      />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                          <ModalHeader className="bg-light p-3" toggle={toggle}>
                            {!!isEdit ? props.t("edit_ward") : props.t("create_new_ward")}
                          </ModalHeader>
                          <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <ModalBody>
                              <input type="hidden" id="id-field" />
                              <Row>
                                <Col xl={12} sm={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="wards-field"
                                      className="form-label"
                                    >
                                      {props.t("wardsName")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="wardsName"
                                      id="wardsName-field"
                                      className="form-control"
                                      autoComplete="off"
                                      placeholder={props.t("wardsName")}
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.wardsName || ""}
                                      invalid={
                                        validation.touched.wardsName && validation.errors.wardsName ? true : false
                                      }
                                    />
                                    {validation.touched.wardsName && validation.errors.wardsName ? (
                                      <FormFeedback type="invalid">{validation.errors.wardsName}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </ModalBody>
                            <div className='p-3'>
                              <Row className="align-items-center">
                                <div className="col-sm">
                                  <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                  <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setCurrentWard(null); setIsEdit(false) }}> {props.t("close")} </button>
                                    <button type="submit" className="btn btn-success"> {!!isEdit ? props.t("update") : props.t("save")} </button>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Form>

                        </Modal>
                    </div>
                </div>
            </div>

          </Card>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Wards))
