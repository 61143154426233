import * as Yup from "yup"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from "react-redux"
import withRouter from '../../../components/Common/withRouter'
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import DeleteModal from "../../../components/Global/DeleteModal"
import TableContainer from "../../../components/Common/TableContainer"
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import {
  getCashDesks as onGetCashDesks,
  createCashDesk as onCreateCashDesk,
  updateCashDesk as onUpdateCashDesk,
  searchWard as onSearchWard
} from "../../../slice/thunks"
import GlobalLoader from "../../../components/Common/GlobalLoader"


const CashDesk = (props) => {
  const dispatch = useDispatch()
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      cashDesks: setting.cashDesks,
      cashDesk: setting.cashDesk,
      createCashDeskSuccess: setting.createCashDeskSuccess,
      updateCashDeskSuccess: setting.updateCashDeskSuccess,
      error: setting.error
    })
  )
  const { cashDesks, cashDesk, createCashDeskSuccess, updateCashDeskSuccess, error } = useSelector(SettingsProperties)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentCashDesk, setCurrentCashDesk] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const permission = JSON.parse(localStorage.getItem("perm"));
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() =>{
    loadWards()
  },[dispatch])

  useEffect(() => {
    if (cashDesks && cashDesks.page) {
      setIsLoading(false)
    }
  },[cashDesks])

  useEffect(() =>{
    if (createCashDeskSuccess && !error) {
      setIsEdit(false)
      validation.resetForm()
      setModal(false)
      setCurrentCashDesk(null)
      loadWards()
    }
  },[createCashDeskSuccess, error, cashDesk])

  useEffect(() =>{
    if (updateCashDeskSuccess && !error) {
      setIsEdit(false)
      validation.resetForm()
      setModal(false)
      setCurrentCashDesk(null)
      loadWards()
    }
  },[updateCashDeskSuccess, error, cashDesk])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: (currentCashDesk && currentCashDesk.id) || '',
      cashDeskName: (currentCashDesk && currentCashDesk.cashDeskName) || '',
    },
    validationSchema: Yup.object({
      cashDeskName: Yup.string().required(props.t("you_need_provide_name")),
    }),
    onSubmit: (values) => {
      
      if (isEdit) {
        const updateForm = {
          cashDeskName: values.cashDeskName,
        }
        const id = currentCashDesk.id

        dispatch(onUpdateCashDesk({ body: updateForm, id: id }))

      } else {
        const newForm = {
          cashDeskName: values.cashDeskName
        }
        dispatch(onCreateCashDesk(newForm))
      }
    },
  })

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      validation.resetForm();
      setCurrentCashDesk(null);
    } else {
      setModal(true);
    }
  }, [modal])

  const searchWards = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onSearchWard({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onSearchWard({term: term, page: 1, size: 10}))
    }
  },[])

  const loadWards = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetCashDesks({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetCashDesks())
    }
  })

  const handleOpen = () => {
    setIsEdit(false)
    toggle()
  }

  const handleSelectCashDesk = (arg) => {    
    const selectedWard = arg
    setCurrentCashDesk({
      id: selectedWard.id,
      cashDeskName: selectedWard.cashDeskNumber,
    })
    setIsEdit(true);
    toggle();
  }

  const onClickDelete = (cashDesk) => {
    setCurrentCashDesk(cashDesk);
    setDeleteModal(true);
  }

  const columns = useMemo(
    () => [
      {
        header: props.t("name"),
        accessorKey: "cashDeskNumber",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("status"),
        style: "button",
        enableColumnFilter: false,
        cell: (cellProps) => { 
            if (cellProps.row.original.opened == true) {
              return <span>{props.t("opened")}</span>
            } else {
              return <span>{props.t("closed")}</span>
            }
        }
      },
      {
        header: props.t("action"),
        style: "text-end",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                  {permission && (permission.includes("ALL_PERMISSIONS")) ?
                    <li className="list-inline-item edit" title="Edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => { const CashData = cellProps.row.original; handleSelectCashDesk(CashData); }}
                      >
                        <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                      </Link>
                    </li>
                    :<></>
                  }
                </ul>
            </div>
          );
        },
      },
    ],
    [handleSelectCashDesk, cashDesks]
  );


  document.title = props.t("ward_title")
  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <Card>
            <div className="ward-list-mode px-3">
                <div className="patients-list py-4 px-2  ">
                    <div className="container-fluid">
                        <div className="row list-patients d-flex  justify-content-between">
                            <div className="col-lg-5">
                              <BreadCrumb title={props.t("cash_desk")} pageTitle={props.t("settings")} />
                            </div>
                            <div className="col-lg-3 new-mode-btn">
                              {permission && (permission.includes("ALL_PERMISSIONS")) ?
                                <SinpleActionButton title={props.t("create_cash_desk")} img="../assets/images/user.png" action={handleOpen} />
                                :<></>
                              }
                            </div>
                        </div>

                        <div className="payment-mode-lists">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="card table-card">
                                      <TableContainer
                                        columns={columns}
                                        data={((cashDesks && cashDesks.items) || [])}
                                        isGlobalFilter={false}
                                        customPageSize={10}
                                        divClass="table-card-ward"
                                        tableClass="align-middle"
                                        theadClass="thead-light table-light"
                                        tableSize={ cashDesks && cashDesks.size }
                                        tablePage={ cashDesks && cashDesks.page }
                                        tableTotal= { cashDesks && cashDesks.total_items }
                                        tableTotalPage= { cashDesks && cashDesks.total_page }
                                        onNextPage= {loadWards}
                                        onSearch= {searchWards}
                                        SearchPlaceholder={props.t("search_ward")}
                                      />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                          <ModalHeader className="bg-light p-3" toggle={toggle}>
                            {!!isEdit ? props.t("edit_cash_desk") : props.t("create_cash_desk")}
                          </ModalHeader>
                          <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <ModalBody>
                              <input type="hidden" id="id-field" />
                              <Row>
                                <Col xl={12} sm={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="wards-field"
                                      className="form-label"
                                    >
                                      {props.t("name")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                      name="cashDeskName"
                                      id="cashDeskName-field"
                                      className="form-control"
                                      autoComplete="off"
                                      placeholder=""
                                      type="text"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.cashDeskName || ""}
                                      invalid={
                                        validation.touched.cashDeskName && validation.errors.cashDeskName ? true : false
                                      }
                                    />
                                    {validation.touched.cashDeskName && validation.errors.cashDeskName ? (
                                      <FormFeedback type="invalid">{validation.errors.cashDeskName}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </ModalBody>
                            <div className='p-3'>
                              <Row className="align-items-center">
                                <div className="col-sm">
                                  <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                  <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setCurrentCashDesk(null); setIsEdit(false) }}> {props.t("close")} </button>
                                    <button type="submit" className="btn btn-success"> {!!isEdit ? props.t("update") : props.t("save")} </button>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Form>

                        </Modal>
                    </div>
                </div>
            </div>
          </Card>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(CashDesk))
