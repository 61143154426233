import React from 'react'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import withRouter from '../../../../../Common/withRouter'
import { Col } from 'reactstrap'

const GeneralCashierContent = (props) => {
    
  return (
    <div 
        className="gen-lab-rep-print p-3 print-bg" 
        // style={{  }}
    >
        <div className="d-flex justify-content-between m-0 ">
            <span className="m-0 p-0 ft-6" style={{ padding: "0" }}>{props.t("reg_no")}</span>
            <span className="text-danger fw-bold m-0 p-0 ft-6" style={{ padding: "0" }}>{props.t("tax_invoice")}</span>
        </div>
        <div className="text-center m-0">
            <div className="d-flex gap-2 justify-content-center m-0 align-items-center">
                <Col>
                    <h5 className="" style={{ color: "#d13e6a", fontWeight:"700", margin: "0", padding: "0" }}>
                        {props.t("hospital_name")}</h5>
                    <h6 className='ft-10' style={{ color: "#45a049", fontWeight: "500" }}>{props.t("hospital_location")}</h6>
                </Col>
            </div>
            <div className="d-flex justify-content-center gap-3">
                <span className='ft-9' style={{  marging: "0" }}>{props.t("hospital_email")}</span>
                <span className='ft-9' style={{ marging: "0" }}>{props.t("hospital_contact")}</span>
            </div>
        </div>

            {props.section && props.section === "sale" ?
                <>
                    <div className="m-0">
                        <table className="table table-borderless m-0" style={{ background: "none" }}>
                            <tbody className='ft-10' style={{ borderTop: "1px solid black",  background: "none" }}>
                                <tr style={{  background: "none" }}>
                                    <th style={{ width: "10%", background: "none"  }}>{props.t("bill_no")}</th>
                                    <td style={{ background: "none" }}>{props.sale && props.sale.orderRef}</td>

                                    <th style={{ background: "none" }}>{props.t("patient_id")}</th>
                                    <td style={{ background: "none" }}>{props.sale && props.sale.patient && props.sale.patient.patientRef}</td>

                                    <th style={{ background: "none" }}>{props.t("date")}</th>
                                    <td style={{ background: "none" }}>{props.paymentDate && moment(props.paymentDate).format('DD / MM/ YYYY') || props.sale && moment(props.sale.orderDate).format('DD / MM/ YYYY')}</td>
                                </tr>
                                <tr>
                                    <th  style={{ width: "14%", background: "none"  }}>{props.t("patient_name")}</th>
                                    <td style={{ background: "none" }}>{props.sale && props.sale.patient && props.sale.patient.firstName} {props.sale && props.sale.patient && props.sale.patient.lastName}</td>

                                    <th style={{ background: "none" }}>{props.t("gender")}</th>
                                    <td style={{ background: "none" }}>{props.sale && props.sale.patient && props.sale.patient.gender === "M" ? props.t("male") : props.t("female") }</td>
                                    <th style={{ background: "none" }}>{props.t("address")}</th>
                                    <td style={{ background: "none" }}>
                                        {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.address ? props.sale.patient.address.address : ""}
                                        {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.city ? ", "+props.sale.patient.address.city : ""}
                                        {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.region ? ", "+props.sale.patient.address.region : ""}
                                    </td>
                                </tr>
                                <tr>
                                    

                                    {props.sale && props.sale.patientRecord && props.sale.patientRecord.bedRecords ?
                                        <>
                                            <th>{props.t("bed_no")}</th>
                                            <td>VIP 02</td>
                                        </>
                                        :
                                        <></>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="m-0">
                        <table className="table table-borderless fw-bold ft-11">
                            <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                                <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                                <th style={{ width: "60%" }}>{props.t("ipd_service_details")}</th>
                                <th style={{ width: "10%" }} >{props.t("qty")}</th>
                                <th style={{ width: "10%" }} >{props.t("rate")}</th>
                                <th style={{ width: "10%" }} >{props.t("discount")}</th>
                                <th style={{ width: "10%" }} >{props.t("amount")}</th>
                            </tr>

                            {props.items && props.items.map((row, index) => (
                                <tr key={index}>
                                    <th>{index+1}</th>
                                    <th>{row.label || row && row.product && row.product.name}</th>
                                    <th >{row.quantity}</th>
                                    <th>{row.unitPrice}</th>
                                    <th>{row.discount}</th>
                                    <th>{row.rowTotal || row.finalPrice}</th>
                                </tr>
                            ))}
                        </table>
                    </div>
                    {/* <div className="m-0">
                        <table className="table table-borderless m-0">
                            <tr>
                                <td style={{ width: "10%" }}></td>
                                <td style={{ width: "60%", visibility: "hidden" }} ></td>
                                <td style={{ width: "10%" }} ></td>
                                <td style={{ width: "10%" }} ></td>
                                <td style={{ width: "10%" }} ></td>
                            </tr>
                        </table>
                    </div> */}
                    <div className="m-0">
                        <table className="table table-borderless m-0">
                            <tr style={{ borderTop: "1px solid black" }}>
                                <td style={{ whiteSpace: "nowrap" }} >
                                    <b style={{ background: "none"  }}> {props.t("total_amount")} :</b>
                                </td>
                                <td >
                                     {props.totalAmount}
                                </td>

                                <td style={{ whiteSpace: "nowrap" }} >
                                    <b style={{ background: "none"  }}> {props.t("amount_recieved")} :</b>
                                </td>
                                <td  >
                                    {props.amountRecieved}
                                </td>

                                <td style={{ whiteSpace: "nowrap" }} >
                                    <b style={{ background: "none"  }}> {props.t("amount_return")}:</b>
                                </td>
                                <td >
                                     {props.amountRecieved > props.totalAmount ? props.amountRecieved - props.totalAmount : 0}
                                </td>
                            </tr>

                        </table>
                    </div>
                </>
            :
                null
            }

        {/* Footer  */}
        <div className="text-center">
            <p className="text fw-bold m-0 p-0 ft-9" style={{ color: "#d13e6a" }}>{props.t("slogan")}</p>
            <p className="fw-bold text-danger m-0 p-0 ft-9">
                {props.t("invoice_footer")}:
                <span className="text-primary ft-9">+237 6 75 55 38 64</span></p>
        </div>
    </div>
  )
}

export default withRouter(withTranslation()(GeneralCashierContent))