import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from "yup"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux"
import { 
    Accordion, 
    AccordionBody, 
    AccordionHeader, 
    AccordionItem, 
    Modal, 
    ModalBody, 
    ModalHeader, 
    Form, 
    FormFeedback, 
    Input, 
    Label, 
    Col, 
    Row 
} from 'reactstrap'
import {
  getBudgeting as onGetBudgeting,
  createBudgeting as onCreateBudgeting,
} from "../../../slice/thunks"
import withRouter from '../../../components/Common/withRouter'
import GlobalLoader from "../../../components/Common/GlobalLoader"


const Budgeting = (props) => {
    const dispatch = useDispatch()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        budgetings: setting.budgetings,
        budgeting: setting.budgeting,
        createBudgetingSuccess: setting.createBudgetingSuccess,
        error: setting.error
      })
    )

    const { 
        budgetings,
        budgeting,
        createBudgetingSuccess,
        error
    } = useSelector(SettingsProperties)
    const [modal, setModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [openAccordeon, setOpenAccordeon] = useState(false)
    const [currentAccount, setCurrentAccount] = useState(null)

    const loadBudgeting = useCallback(() => {
        dispatch(onGetBudgeting())
    })

    const toggle = useCallback(() => {
        if (modal) {
          setModal(false);
          validation.resetForm();
          setCurrentAccount(null)
        } else {
          setModal(true);
        }
    }, [modal])

    const toggleAccordeon = (id) => {
        setOpenAccordeon(openAccordeon === id ? [] : id);
    };

    const handleClickCreate = (account) => {
        setCurrentAccount(account);
        toggle()
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
            amount: (currentAccount && currentAccount.budgetedAmount) || '',
        },
    
        validationSchema: Yup.object({
            amount: Yup.string().required(props.t("you_need_provide_amount"))
        }),
    
        onSubmit: (values) => {
            const newForm = {
                classAccountId: currentAccount.id,
                amount: values.amount,
            }
            dispatch(onCreateBudgeting(newForm))
        },
    
    })

    useEffect(() =>{
        loadBudgeting()
    }, [dispatch])

    useEffect(() => {
        if (budgetings && Object.entries(budgetings).length > 0) {
            setIsLoading(false)
        }
    },[budgetings])

    useEffect(() => {
        if (createBudgetingSuccess && !error) {
            setModal(false)
            validation.resetForm()
            setCurrentAccount(null)
            loadBudgeting()
        }
    },[createBudgetingSuccess,budgeting])


  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="budgeting-sec">
                    <div className="container-fluide pt-4">
                        <div className="heading-parent-sec ">
                            <div className="heading-child-one-sec pt-4">
                                <h6 className="m-0 p-0">{props.t("budgeting_financial_year")}</h6>
                            </div>
                        </div>

                        {/* <!-- table --> */}
                        <div className="row banks-or-cash-table pt-0 mt-0">
                            <div className="col-12 mb-5 pt-0 mt-0">
                                <div className="custom-table pt-0 mt-0">
                                    <div className="table-wrapper">
                                        <div className="table-title">
                                            <table className="p-0 m-0" style={{ width:"100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th className='th_budgeting'>{ props.t("account") }</th>
                                                        <th className='th_budgeting'>{ props.t("description") }
                                                            <br />
                                                            <sub style={{ fontSize: "10px", whiteSpace: "nowrap" }}>
                                                                {props.t("source_of_revenue")}
                                                            </sub>
                                                        </th>
                                                        <th className='th_budgeting'>{ props.t("budgeted") }</th>
                                                        <th className='th_budgeting' style={{ whiteSpace: "nowrap" }}>{ props.t("actual") }</th>
                                                        <th className='th_budgeting'>{props.t("variance")}</th>
                                                        <th className='th_budgeting'>{props.t("rate")}</th>
                                                        <th className='th_budgeting'>{props.t("action")}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <Accordion className="accordion" open={openAccordeon ? [openAccordeon] : []} toggle={toggleAccordeon}>
                                            { Object.entries(budgetings).map(([key, value]) => {
                                                const totalBudgetedAmount = value.reduce((total, row) => total + row.budgetedAmount, 0);
                                                const totalActualAmount = value.reduce((total, row) => total + row.budgetedAmount, 0);

                                                return (<AccordionItem key={key}>
                                                    <AccordionHeader targetId={key}>
                                                        <table className="custom-table p-0 m-0" style={{ boxShadow: "none" }}>
                                                            <thead className="p-0 m-0">
                                                                <tr className="p-0 m-0">
                                                                    <th className="text-center" style={{ fontSize: "17px", fontWeight: "700" }}>
                                                                        { value[0].parentCode +"-"+value[0].parentName}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </AccordionHeader>
                                                    <AccordionBody accordionId={key}>
                                                        <table className="custom-table p-0 m-0" style={{ boxShadow: "none" }}>
                                                            <tbody className="p-0 m-0">
                                                                {value && value.map((row, index) => {
                                                                    return <tr key={index} className=" bg-white">
                                                                        <td className='td_budgeting'>{row.account}</td>
                                                                        <td className='td_budgeting'>{row.description}</td>
                                                                        <td className='td_budgeting'>{row.budgetedAmount}</td>
                                                                        <td className='td_budgeting'>{row.totalAmount || 0}</td>
                                                                        <td className='td_budgeting'>{row.budgetedAmount - row.actualAmount}</td>
                                                                        <td className='td_budgeting'>{row.budgetedAmount > 0 ? ((row.actualAmount / row.budgetedAmount) * 100).toFixed(2) : 0 }%</td>
                                                                        <td className="d-flex g-2" style={{ gap: "2px" }}>
                                                                            {row.budgetedAmount <= 0 ?
                                                                                <Link
                                                                                    
                                                                                    onClick={() =>{handleClickCreate(row)}}
                                                                                >
                                                                                    <img src="../assets/images/create.png" alt="" />
                                                                                </Link>
                                                                                :
                                                                                <Link 
                                                                                    
                                                                                    onClick={() =>{handleClickCreate(row)}}
                                                                                >
                                                                                    <img src="../assets/images/edits.png" alt="" />
                                                                                </Link>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                })}
                                                                <tr className=" bg-white">
                                                                    <td className='td_budgeting'><b>{props.t("grand_total")}</b></td>
                                                                    <td className='td_budgeting'></td>
                                                                    <td className='td_budgeting'><b>{totalBudgetedAmount}</b></td>
                                                                    <td className='td_budgeting'><b>{totalActualAmount}</b></td>
                                                                    <td className='td_budgeting'><b>{totalBudgetedAmount - totalActualAmount}</b></td>
                                                                    <td className='td_budgeting'><b>{totalActualAmount === 0 ? 0 : totalBudgetedAmount - totalActualAmount}%</b></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </AccordionBody>
                                                </AccordionItem>
                                            )})}
                                        </Accordion>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <Modal id='showModal' size='lg' isOpen={modal} toggle={toggle} backdrop={'static'} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                        {props.t("define_budget_for_class_account")}
                    </ModalHeader>
                    <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                        }}
                    >
                        <ModalBody>
                            <Row>
                                <Col xl={12} sm={12}>
                                    <div className="mb-3">
                                    <Label
                                        htmlFor="amount-field"
                                        className="form-label"
                                    >
                                        {props.t("amount")}
                                        <span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                        name="amount"
                                        id="amount-field"
                                        className="form-control"
                                        autoComplete="off"
                                        type="number"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.amount || ""}
                                        invalid={
                                            validation.touched.amount && validation.errors.amount ? true : false
                                        }
                                    />
                                    {validation.touched.amount && validation.errors.amount ? (
                                        <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                    ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <div className='p-3'>
                            <Row className="align-items-center">
                            <div className="col-sm">
                                <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                            </div>
                            <div className="col-sm-auto">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setCurrentAccount(null) }}> {props.t("close")} </button>
                                    <button type="submit" className="btn btn-success"> {props.t("save")} </button>
                                </div>
                            </div>
                            </Row>
                        </div>
                    </Form>
                </Modal>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Budgeting))
