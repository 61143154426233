import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
// import SalesCahier from './SalesCahier'
import withRouter from '../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import * as Yup from "yup"
import { useFormik } from 'formik'
import { createSelector } from "reselect"
import { 
    getPatientInvoice as onGetInvoice,
    getPatient as onGetPatient
} from '../../../slice/thunks'
import { useSelector, useDispatch } from "react-redux"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import TableContainerWarehouse from '../../../components/Common/TableContainerWarehouse'
import SinpleActionButton from '../../../components/Global/SinpleActionButton'
import GlobalLoader from '../../../components/Common/GlobalLoader'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { Link, useParams } from 'react-router-dom'
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

const PhInvoiceDetails = (props) => {
    const { id, patientId, wardNo } = useParams()
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const selectLayoutPatientState = (state) => state.Outputs;
    const SettingsProperties = createSelector(
        selectLayoutState,
        (setting) =>({
            patientInvoices: setting.patientInvoices,
            error: setting.error
        })
    )
    const OutputsProperties = createSelector(
        selectLayoutPatientState,
        (output) =>({
            patient: output.patient,
            PatientError: output.error
        })
    )
    const { patientInvoices, error } = useSelector(SettingsProperties)
    const { patient, PatientError } = useSelector(OutputsProperties)
    const [isLoading, setIsLoading] = useState(true)

    const loadInvoices = useCallback(() => {
        dispatch(onGetInvoice(id))
    })

    const loadPatient = useCallback(() => {        
        dispatch(onGetPatient({id: patientId}))
    })

    useEffect(() => {
        loadInvoices()
        loadPatient()
    },[dispatch])

    useEffect(() => {
    },[patientInvoices])

    useEffect(() => {        
        if (patient && patient.id) {
            setIsLoading(false)
        }
    },[patient])

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="content-fluide">
                        <div className="view-occupied-wards-section">
                            <div className="container-fluid">
                                <div className="id g-4  px-4 pb-5 ">
                                    <Link to="/pharmacy/occupied_wards">
                                        <div className="heading-parent-sec">
                                            <div className="heading-child-one-sec pt-4 pb-3">
                                                <h5><i className="fa-solid fa-chevron-left"></i>&nbsp;<b>{props.t("view_occupied_ward")}</b></h5>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="row parient-all-list pt-3 pb-3 ">
                                        <div className="col-lg-3 ps-4">
                                            <h6>{props.t("patient_id")} <span style={{ color: "#2563EB", fontWeight: "bold" }}>- {patient && patient.patientRef}</span></h6>
                                        </div>
                                        <div className="col-lg-3 ps-4">
                                            <h6>{props.t("patient_name")}<span style={{ color: "#2563EB", fontWeight: "bold" }}>- {patient && patient.firstName} {patient && patient.lastName}</span></h6>
                                        </div>
                                        <div className="col-lg-1">&nbsp;</div>
                                        <div className="col-lg-5">
                                            <h6>{props.t("ward_bed_no")}. <span style={{ color: "#2563EB", fontWeight: "bold" }}>- {wardNo}</span></h6>
                                        </div>
                                    </div>

                                    <div className="row all-bill-history">
                                        <div className="col-md-3 py-4 bill-history">
                                            <span>{props.t("bill_history")}</span>
                                            {/* <div className="bed-charge">Daily Bed Charge : <span>5,000</span></div>
                                            <div className="bed-charge">Other Bills : <span>5,000</span></div> */}
                                        </div>
                                        <div className="col-md-3 py-4 total-daily-bills">
                                            {/* <div className="total-bills">Total Bills :<span> 1,00,000</span></div>
                                            <div className="daily-rate">Daily Rate : <span>10,000</span></div> */}
                                        </div>
                                        <div className="col-md-3 no-days">
                                            <div>&nbsp;</div>
                                            {/* <div>No. of Days : 5</div> */}
                                        </div>
                                        <div className="col-md-3 py-3 print-btn ">
                                            <div className="print-all-bed">
                                                {/* <button>Print All<i className="fa-solid fa-print"></i></button> */}
                                            </div>
                                            {/* <div className="total-bed-charge">Total Bed Charge :<span>50,000</span></div> */}
                                        </div>
                                    </div>

                                    {patientInvoices && patientInvoices.map((invoice, index) => {
                                        return <div className="view-posted-detail pt-3" key={index}>
                                            <div className="row">
                                                <div className="bill-detail">
                                                    <h6 className="date">{props.t("date")} {moment(invoice.createdAt).format('DD / MM/ YYYY')}</h6>
                                                    {/* <button className="print-btn">Print<i className="fa-solid fa-print"></i></button> */}
                                                </div>
                                            </div>
                                            <div className="detail-table table-responsive">
                                                <table className="table m-0">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "8%" }}>{props.t("s_no")}</th>
                                                            <th style={{ width: "72%" }}>{props.t("product")}</th>
                                                            <th style={{ width: "8%" }}>{props.t("qty")}</th>
                                                            <th style={{ width: "12%" }}>{props.t("amount")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {invoice.saleOrder && invoice.saleOrder.items && invoice.saleOrder.items.map((item, itemIndex) =>{
                                                            return <tr key={itemIndex}>
                                                                <td>{itemIndex+1}</td>
                                                                <td>{item.product.name}</td>
                                                                <td>{item.quantity}</td>
                                                                <td>{item.finalPrice}</td>
                                                            </tr>
                                                        })}
                                                        <tr>
                                                            <td colSpan="3">{props.t("total")}</td>
                                                            <td>&nbsp;{invoice.saleOrder.amount}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    })}

                                </div>
                                
                            </div>
                            
                        </div>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PhInvoiceDetails))
