import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
//action
import { geValidateToken, refreshToken } from '../slice/thunks';

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from './AuthProtected';
import { useDispatch } from 'react-redux';
import withRouter from '../components/Common/withRouter';
import { withTranslation } from 'react-i18next';

const Index = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [isInactive, setIsInactive] = useState(false);
    let timeout;

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("accessToken"))) {
            const intervalId = setInterval(() => {
                dispatch(refreshToken(navigate));
            }, 57 * 60 * 1000);
            return () => { clearInterval(intervalId) };
        }
    },[]);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("accessToken"))) {
            const intervalCheck = setTimeout(() => {
                dispatch(refreshToken(navigate));
            }, 2000);
            return () => { clearInterval(intervalCheck) };
        }
    },[]);

    useEffect(() => {

        if (!location.pathname.includes("login")) {

            // Fonction pour réinitialiser le timer
            const resetTimer = () => {
                clearTimeout(timeout);
                startTimer(); // Redémarre le timer après chaque interaction
            };

            // Fonction pour démarrer le timer de 5 minutes
            const startTimer = () => {
                timeout = setTimeout(() => {
                setIsInactive(true);
                alert(props.t("inactive_session_warning"));
                navigate('/login'); // Redirection après inactivité
                }, 10 * 60 * 1000); // 5 minutes en millisecondes
            };

            // Écoute les événements d'interaction utilisateur
            window.onload = resetTimer;
            window.onmousemove = resetTimer;
            window.onmousedown = resetTimer; // Détecte les clics
            window.ontouchstart = resetTimer; // Détecte les interactions sur mobile
            window.onclick = resetTimer;
            window.onkeydown = resetTimer; // Détecte les touches du clavier
            window.onscroll = resetTimer; // Détecte le défilement

            // Démarre le timer à l'ouverture de la page
            startTimer();

            // Nettoyage à la fin
            return () => {
                clearTimeout(timeout);
                window.onload = null;
                window.onmousemove = null;
                window.onmousedown = null;
                window.ontouchstart = null;
                window.onclick = null;
                window.onkeydown = null;
                window.onscroll = null;
            };

        }

    }, [navigate]);

    return (
        <React.Fragment>
            <Routes>
                <Route>
                    {publicRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <NonAuthLayout>
                                    {route.component}
                                </NonAuthLayout>
                            }
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Route>

                <Route>
                    {authProtectedRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <AuthProtected>
                                    <VerticalLayout>
                                        {route.component}
                                    </VerticalLayout>
                                </AuthProtected>}
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(Index))
