import React from 'react'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../../Common/withRouter'

const MatrixCashierContent = (props) => {
  return ( 
        <div class="" 
            style={{ 
                fontFamily: "Courier, monospace", 
                backgroundColor: "#fefefe", 
                padding: "10px", 
                width: "max-content", 
                height: "max-content"
                }}
        >
            <div class="container">
                <div class="header">
                    <div class="reg-no" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "140px", margin: "0" }}>
                        <p style={{ fontFamily: "Courier, monospace", fontSize: "11px" }}>{props.t("reg_no")}</p>
                        <p style={{ padding: "0", fontSize: "22px", fontFamily: "Courier, monospace", margin: "0" }}>{props.t("tax_invoice")}</p>
                    </div>
                    <h4 style={{ textAlign: "center", fontFamily: "Courier, monospace", fontWeight: "600" }}>{props.t("hospital_name")}</h4>
                    <h6 style={{ textAlign: "center", fontFamily: "Courier, monospace" }}>{props.t("hospital_location")}</h6>
                    <div style={{ display: "flex", justifyContent: "center", gap: "15px", margin: "0", flexDirection: "row" }}>
                        <p style={{ fontFamily: "Courier, monospace"}}>{props.t("hospital_email")}</p>
                        <p style={{ fontFamily: "Courier, monospace"}}>{props.t("hospital_contact")}</p>
                    </div>
                </div>

                {props.section && props.section == "sale" ?
                    <>
                        <div class="section" style={{ marginBottom: "0" }}>
                            <div class="m-0">
                                <table class="table table-borderless m-0">
                                    <tbody style={{ borderTop: "1px solid black" }}>
                                        <tr>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("bill_no")}.</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>{props.sale && props.sale.orderRef}</td>

                                            <th style={{ fontFamily: "Courier, monospace"}}>{props.t("patient_id")}</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>{props.sale && props.sale.patient && props.sale.patient.patientRef}</td>

                                            <th style={{ fontFamily: "Courier, monospace"}}>{props.t("date")}</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>{props.sale && moment(props.sale.orderDate).format('DD / MM/ YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ fontFamily: "Courier, monospace"}}>{props.t("patient_name")}</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>{props.sale && props.sale.patient && props.sale.patient.firstName} {props.sale && props.sale.patient && props.sale.patient.lastName}</td>

                                            <th style={{ fontFamily: "Courier, monospace", textAlign: "center" }}>{props.t("gender")}</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>{props.sale && props.sale.patient && props.sale.patient.gender === "M" ? props.t("male") : props.t("female") }</td>

                                        </tr>
                                        <tr>
                                            <th style={{ fontFamily: "Courier, monospace"}}>{props.t("address")}</th>
                                            <td style={{ fontFamily: "Courier, monospace", fontSize: "12px" }}>
                                                {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.address ? props.sale.patient.address.address : ""}
                                                {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.city ? ", "+props.sale.patient.address.city : ""}
                                                {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.region ? ", "+props.sale.patient.address.region : ""}
                                            </td>

                                        
                                            {props.sale && props.sale.patientRecord && props.sale.patientRecord.bedRecords ?
                                                <>
                                                    <th style={{ fontFamily: "Courier, monospace"}}>{props.t("bed_no")}.</th>
                                                    <td style={{ fontFamily: "Courier, monospace"}}>VIP 02</td>
                                                </>
                                                :<></>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="section two" style={{ margin: "0" }}>
                            <div class="m-0">
                                <table class="table table-borderless mb-4">
                                    <tbody style={{ border: "none !important" }}>
                                        <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("s_no")}</th>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("ipd_service_details")}</th>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("qty")}</th>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("rate")}</th>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("discount")}</th>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("amount")}</th>
                                        </tr>

                                        {props.items && props.items.map((row, index) => (
                                            <tr key={index}>
                                                <td style={{ fontFamily: "Courier, monospace"}}>{index+1}</td>
                                                <td style={{ fontFamily: "Courier, monospace"}}>{row.label}</td>
                                                <td style={{ fontFamily: "Courier, monospace"}}>{row.quantity}</td>
                                                <td style={{ fontFamily: "Courier, monospace"}}>{row.unitPrice}</td>
                                                <td style={{ fontFamily: "Courier, monospace"}}>{row.discount}</td>
                                                <td style={{ fontFamily: "Courier, monospace"}}>{row.rowTotal}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="totals" style={{ margin: "0" }}>
                            <div class="m-0">
                                <table class="table table-borderless m-0">
                                    <tr>
                                        <td></td>
                                        <td style={{ visibility: "hidden" }}></td>
                                        <td></td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}></td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}></td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div class="section" style={{ marginTop: "0" }}>
                            <div class="m-0">
                                <table class="table table-borderless m-0">
                                    <tr style={{ borderTop: "1px solid black" }}>
                                        <td style={{ fontFamily: "Courier, monospace" }}>
                                        </td>
                                        <td style={{ textAlign: "left", fontFamily: "Courier, monospace" }} ></td>
                                        <td style={{ visibility: "hidden" }}></td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>
                                            <b> {props.t("total_amount")}.</b>
                                        </td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>
                                            : {props.totalAmount}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontFamily: "Courier, monospace" }}>
                                        </td>
                                        <td style={{ textAlign: "left", fontFamily: "Courier, monospace" }} ></td>
                                        <td style={{ visibility: "hidden" }}></td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>
                                            <b> {props.t("amount_recieved")}.</b>
                                        </td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>
                                            : {props.amountRecieved}
                                        </td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid black" }}>
                                        <td style={{ whiteSpace:"nowrap", fontFamily: "Courier, monospace" }}>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>
                                            <b>{props.t("amount_return")}.</b>
                                        </td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>
                                            : {props.amountRecieved > props.totalAmount ? props.amountRecieved - props.totalAmount : 0}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </>
                : props.section && props.section == "payment" ?
                    <>
                        <div class="section" style={{ marginBottom: "0" }}>
                            <div class="m-0">
                                <table class="table table-borderless m-0">
                                    <tbody style={{ borderTop: "1px solid black" }}>
                                        <tr>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("bill_no")}.</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>LAB-756</td>

                                            <th style={{ fontFamily: "Courier, monospace"}}>{props.t("patient_id")}</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>12334</td>

                                            <th style={{ fontFamily: "Courier, monospace"}}>{props.t("patient_id")}</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>09/02/2024</td>
                                        </tr>
                                        <tr>
                                            <th style={{ fontFamily: "Courier, monospace"}}>{props.t("patient_name")}</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>ALEX THOMAS</td>

                                            <th style={{ fontFamily: "Courier, monospace", textAlign: "center" }}>{props.t("gender")}</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>Male</td>

                                            <th style={{ fontFamily: "Courier, monospace"}}>{props.t("pr_by")}</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>Dr. S. WATSON</td>
                                        </tr>
                                        <tr>
                                            <th style={{ fontFamily: "Courier, monospace"}}>{props.t("address")}</th>
                                            <td style={{ fontFamily: "Courier, monospace", fontSize: "12px" }}>BIPINDI, CAMEROON, AFRICA</td>

                                            <th style={{ fontFamily: "Courier, monospace", textAlign: "center" }}>{props.t("age")}:</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>25</td>

                                            <th style={{ fontFamily: "Courier, monospace"}}>{props.t("bed_no")}.</th>
                                            <td style={{ fontFamily: "Courier, monospace"}}>VIP 02</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="section two" style={{ margin: "0" }}>
                            <div class="m-0">
                                <table class="table table-borderless mb-4">
                                    <tbody style={{ border: "none !important" }}>
                                        <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("s_no")}</th>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("ipd_service_details")}</th>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("qty")}</th>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("rate")}</th>
                                            <th style={{ fontFamily: "Courier, monospace" }}>{props.t("amount")}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ fontFamily: "Courier, monospace"}}>1</td>
                                            <td style={{ fontFamily: "Courier, monospace"}}>Operation Charge</td>
                                            <td style={{ fontFamily: "Courier, monospace"}}>1</td>
                                            <td style={{ fontFamily: "Courier, monospace"}}>256.81</td>
                                            <td style={{ fontFamily: "Courier, monospace"}}>256.81</td>
                                        </tr>
                                        <tr style={{ borderBottom: "none !important;" }}>
                                            <td style={{ fontFamily: "Courier, monospace"}}>2</td>
                                            <td style={{ fontFamily: "Courier, monospace"}}>Bed Charge (VIP WARD 02)</td>
                                            <td style={{ fontFamily: "Courier, monospace"}}>1</td>
                                            <td style={{ fontFamily: "Courier, monospace"}}>85.07</td>
                                            <td style={{ fontFamily: "Courier, monospace"}}>85.07</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="totals" style={{ margin: "0" }}>
                            <div class="m-0">
                                <table class="table table-borderless m-0">
                                    <tr style={{ borderTop: "1px solid black" }}>
                                        <td style={{ fontFamily: "Courier, monospace" }}>{props.t("incl_taxes_details")}: </td>
                                        <td style={{ fontFamily: "Courier, monospace", width: "60%" }}>203.20x12%= 24.38,<br/>GST 0% AMT: 80.10</td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>{props.t("vat")}: <b>$ 12.19</b></td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>{props.t("gros")} <br/>{props.t("less_dis")}</td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>: $ 341.88 <br/>: $ 33.87</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ visibility: "hidden" }}></td>
                                        <td></td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}></td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}></td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div class="section" style={{ marginTop: "0" }}>
                            <div class="m-0">
                                <table class="table table-borderless m-0">
                                    <tr style={{ borderTop: "1px solid black" }}>
                                        <td style={{ fontFamily: "Courier, monospace" }}>E.& O.E. &nbsp; MATHEW &nbsp;12:26pm </td>
                                        <td style={{ textAlign: "left", fontFamily: "Courier, monospace" }} ></td>
                                        <td style={{ visibility: "hidden" }}></td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}><b> {props.t("total_amount")}.</b></td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>: $ 308.01</td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid black" }}>
                                        <td style={{ whiteSpace:"nowrap", fontFamily: "Courier, monospace" }}>USD: Three Hundred and Eight Only </td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>{props.t("net_amount")}. (R/O)</td>
                                        <td style={{ fontFamily: "Courier, monospace", whiteSpace: "nowrap" }}>: $ 308.00</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </>
                :
                    null
                }

                <div class="footer">
                    <p style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center", margin: "0", padding: "0", fontFamily: "Courier, monospace" }}>{props.t("slogan")}</p>
                    <p style={{ textAlign: "center", fontFamily: "Courier, monospace" }}>
                        {props.t("invoice_footer")} 
                        <span style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>+237 6 75 55 38 64</span>
                    </p>
                </div>
            </div>
        </div>

  )
}

export default withRouter(withTranslation()(MatrixCashierContent))
