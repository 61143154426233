import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import Select from "react-select"
import { Link } from "react-router-dom"
import makeAnimated from "react-select/animated"
import { Card, Col, Label, Row, Input, Modal, Form, ModalBody, ModalHeader, FormFeedback } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../components/Common/withRouter'
import {
  getUsers as onGetUsers,
  getAllRoles as onGetAllRoles,
  addUser as onAddUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
  searchUser as onSearchUser
} from "../../../../slice/thunks"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import ActionButton from '../../../../components/Global/ActionButton'
import * as Yup from "yup"
import { useFormik } from 'formik'
import DeleteModal from '../../../../components/Global/DeleteModal'
import TableContainer from '../../../../components/Common/TableContainer'
import GlobalLoader from '../../../../components/Common/GlobalLoader'

const Users = (props) => {
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings
  const SettingsProperties = createSelector(
    selectLayoutState,
    (setting) =>({
      users: setting.users,
      allroles: setting.allroles,
      user_success: setting.user_success,
      user: setting.user,
      error: setting.error
    })
  )
  const { users, user, allroles, user_success, error } = useSelector(SettingsProperties)

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [rolesOptions, setRolesOptions] = useState([])
  const [selectedRoles, setselectedRoles] = useState(null)
  const [errorSelectRole, setErrorSelectRole] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false)
  const [passwordConfirmShow, setPasswordConfirmShow] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [password, setPassword] = useState("")
  const [isPasswordMatch, setisPaswordMatch] = useState(true)
  const permission = JSON.parse(localStorage.getItem("perm"))
  const [isLoading, setIsLoading] = useState(true)
  const [lengthIsValid, setLengthIsValid] = useState(true)

  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (currentUser && currentUser.id) || null,
      username: (currentUser && currentUser.username) || "",
      fullName: (currentUser && currentUser.fullName) || "",
      password: (currentUser && currentUser.password) || "",
      updatePassword: (currentUser && currentUser.password) || "",
      email: (currentUser && currentUser.email) || "",
      phone: (currentUser && currentUser.phone) || "",
      roleId: (currentUser && currentUser.role.id) || ""
    },
    validationSchema: () => {
      return Yup.object({
        username: Yup.string()
        .min(3, 'Username is too short.')
        .required(props.t("you_need_provide_username")),
        fullName: Yup.string()
        .min(3, 'Username is too short.')
        .required(props.t("you_need_provide_fullname")),
        password: ((currentUser && currentUser.id) ?
        Yup.string().test('password', 'Password must have at least 6 characters.', (value) => {
          return !value || value.length >= 6;
        }) :
         Yup.string().min(6, 'Password must have at least 6 characters.')),
        confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match')
      });
    },
    onSubmit: (values) => {

      if (selectedRoles == null) {
        setErrorSelectRole(true)
      }

      if (isEdit) {
        const updateUserForm = {
          id: currentUser && currentUser.id,
          username: values.username,
          fullName: values.fullName,
          password: password,
          email: values.email,
          phone: values.phone,
          roleId: selectedRoles.value
        }
        const id = currentUser.id
        dispatch(onUpdateUser({ data: updateUserForm, id: id }))
        setIsEdit(false);
        setPassword('');
        setConfirmPassword('');
      } else {
        const newUserForm = {
          username: values.username,
          fullName: values.fullName,
          email: values.email,
          phone: values.phone,
          password: password,
          roleId: selectedRoles.value
        }
        dispatch(onAddUser(newUserForm))
        setPassword('');
        setConfirmPassword('');
      }

    },
  })

  const loadRoles = useCallback(() => {
    dispatch(onGetAllRoles())
  },[dispatch])

  const loadUsers = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetUsers({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetUsers())
    }
  },[dispatch])



  const toggle = useCallback(() => {
    if (modal) {
      setModal(false)
      setErrorSelectRole(false)
      validation.resetForm()
      setConfirmPassword("")
      setCurrentUser(null)
    } else {
      setModal(true);
    }
  }, [modal, validation])

  const searchUsers = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onSearchUser({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onSearchUser({term: term, page: 1, size: 10}))
    }
  },[dispatch])

  const handleSelectUser = useCallback((arg) => {
    const selectedUser = arg
    setCurrentUser({
      id: selectedUser.id,
      username: selectedUser.username,
      fullName: selectedUser.fullName,
      password: selectedUser.password,
      email: selectedUser.email,
      phone: selectedUser.phone,
      role: selectedUser.role,
    }, [])

    setselectedRoles({ label: selectedUser.role && selectedUser.role.roleName, value: selectedUser.role && selectedUser.role.id })
    setIsEdit(true);
    toggle();
  },[toggle, setIsEdit]);

  const onClickDelete = useCallback((role) => {
    setCurrentUser(role);
    setDeleteModal(true);
  },[setCurrentUser, setDeleteModal])

  useEffect(() =>{
    if (props.isActive) {
      loadRoles()
      loadUsers()
    }
  },[props.isActive, loadRoles, loadUsers])

  useEffect(() =>{
    if (allroles && allroles.length > 0) {
      let roles_options = []
      let roles = allroles.filter((role) => role.roleName !== 'DOCTOR')
      const roles_list = roles.map( role => {
        return { label: role.roleName, value: role.id }
      })
        let rowGroupe = { label: "groupe", options: roles_list}
        roles_options.push(rowGroupe);
      setRolesOptions(roles_list)
    }
  },[allroles])

  useEffect(() =>{
    if (props.isActive) {
      if (user_success && !error) {
        setCurrentUser(null)
        setselectedRoles(null)
        setConfirmPassword("")
        validation.resetForm()
        setModal(false)
        loadUsers()
      }
    }
  },[user_success, error, user])

  useEffect(() => {
    if (users && users.page) {
      setIsLoading(false)
    }
  },[users])

  useEffect(() => {
    if (selectedRoles && selectedRoles.label) {
      setErrorSelectRole(false)
    }
  },[selectedRoles])

  const matchPassword = (target) => {
    const id = target.id;
    const value = target.value;
    if (id === 'password-field' ) {
      validation.values.password = value;
      setPassword(value);
      setisPaswordMatch(value === passwordConfirmRef.current.props.value);
      validatePasswordLength(target);
    } else {

      setConfirmPassword(value);
      setisPaswordMatch(value === passwordRef.current.props.value);
    }
  }

  const validatePasswordLength = (target) => {
    const value = target.value;
    if(value && value.length < 6) {
      setLengthIsValid(false);
    } else {
      setLengthIsValid(true);
    }
  }

  const handleOpen = () => {
    setIsEdit(false)
    setselectedRoles()
    toggle()
  }

  function handleSelectPermissions(selectedRoles) {
    setselectedRoles(selectedRoles)
  }

  function handleDeleteUser() {
    if (currentUser) {
      dispatch(onDeleteUser({ data: currentUser, id: currentUser.id }))
      setDeleteModal(false)
    }
  }

  function capitalizeName(name) {
    if (!name) return '';
    return name
        .split(' ') 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
        .join(' '); 
  }

  const columns = useMemo(
    () => [
      {
        header: props.t("name"),
        accessorKey: "fullName",
        style: "button",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{capitalizeName(cell.row.original.fullName)}</span>
        }
      },
      {
        header: props.t("username"),
        accessorKey: "username",
        style: "button",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{capitalizeName(cell.row.original.username)}</span>
        }
      },
      {
        header: props.t("phone"),
        accessorKey: "phone",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("role"),
        accessorKey: "role",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{(cell.row.original.role !== null )? cell.row.original.role.roleName : null}</span>
        }
      },
      {
        header: props.t("action"),
        style: "text-end",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                  {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("UPDATE_USERS")) ?
                    <li className="list-inline-item edit" title="Edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => { const userData = cellProps.row.original; handleSelectUser(userData); }}
                      >
                        <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                      </Link>
                    </li>
                    :<></>
                  }
                  {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("DELETE_USERS")) ?
                    <li className="list-inline-item" title="Remove">
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => { const userData = cellProps.row.original; onClickDelete(userData); }}
                      >
                        <img src="../assets/images/delete.png" alt=""></img>
                      </Link>
                    </li>
                    :<></>
                  }
                </ul>
            </div>
          );
        },
      },
    ],
    [handleSelectUser, props, onClickDelete]
  );

  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <DeleteModal  show={deleteModal} onDeleteClick={handleDeleteUser} onCloseClick={() => setDeleteModal(false)}/>
          <Card>
            <div className="head d-flex justify-content-end p-2">
              {permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("ADD_USERS")) ?
                <ActionButton title={props.t("create_new_user")} img="../assets/images/user.png" action={handleOpen} />
                :<></>
              }
            </div>
            <div className="card-body pt-3">
              <div className='justify-content-center'>
                  <TableContainer
                    columns={columns}
                    data={((users && users.items) || [])}
                    isGlobalFilter={true}
                    customPageSize={10}
                    divClass="table-card-ward"
                    tableClass="align-middle"
                    theadClass="thead-light table-light"
                    tableSize={ users && users.size }
                    tablePage={ users && users.page }
                    tableTotal= { users && users.total_items }
                    tableTotalPage= { users && users.total_page }
                    onNextPage= {loadUsers}
                    onSearch= {searchUsers}
                    SearchPlaceholder={props.t("search_user")}
                  />
              </div>
            </div>

            <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
              <ModalHeader className="bg-light p-3" toggle={toggle}>
                {!!isEdit ? props.t("edit_user") : props.t("create_new_user")}
              </ModalHeader>
              <Form className="tablelist-form" autoComplete="new" onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}>
                <ModalBody>
                  <input type="hidden" id="id-field" />
                  <Row>
                    <Col xl={12} sm={12}>
                        <div className='mb-3'>
                          <Label
                            htmlFor="roles-field"
                            className="form-label"
                          >
                            {props.t("role")}<span className='text-danger'>*</span>
                          </Label>
                          <Select
                            name='roles'
                            id='roles-field'
                            value={selectedRoles}
                            isMulti={false}
                            isClearable={true}
                            onBlur={validation.handleBlur}
                            onChange={(e) => {
                                handleSelectPermissions(e);
                            }}
                            validate={{
                              required: { value: true },
                            }}
                            options={rolesOptions}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            invalid = {!errorSelectRole}
                          />
                          {errorSelectRole ?
                            <p className='text-danger' type="invalid" style={{ fontSize: "13px" }} >{props.t("select_user_role")}</p>
                            : <></>
                          }
                        </div>
                    </Col>
                    <Col xl={6} sm={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="username-field"
                          className="form-label"
                        >
                          {props.t("username")}<span className='text-danger'>*</span>
                        </Label>
                        <Input
                          name="username"
                          id="username-field"
                          className="form-control"
                          autoComplete='off'
                          placeholder={props.t("enter_username")}
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl={6} sm={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="fullname-field"
                          className="form-label"
                        >
                          {props.t("fullname")}<span className='text-danger'>*</span>
                        </Label>
                        <Input
                          name="fullName"
                          id="fullname-field"
                          className="form-control"
                          autoComplete='off'
                          placeholder={props.t("enter_fullname")}
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.fullName || ""}
                          invalid={
                            validation.touched.fullName && validation.errors.fullName ? true : false
                          }
                        />
                        {validation.touched.fullName && validation.errors.fullName ? (
                          <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl={6} sm={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="email-field"
                          className="form-label"
                        >
                          {props.t("email")}
                        </Label>
                        <Input
                          name="email"
                          id="email-field"
                          className="form-control"
                          placeholder={props.t("enter_email")}
                          autoComplete='off'
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl={6} sm={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="phone-field"
                          className="form-label"
                        >
                          {props.t("phone")}
                        </Label>
                        <Input
                          name="phone"
                          id="phone-field"
                          className="form-control"
                          placeholder={props.t("enter_phone")}
                          autoComplete='new'
                          type="number"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone ? true : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl={6} sm={6}>
                        <div data-mdb-input-init className="form-outline mb-1" style={{position: 'relative'}}>
                          <Label
                            htmlFor="password-field"
                            className="form-label"
                          >
                            {props.t("password")} {!isEdit ? (<span className='text-danger'>*</span>) : '' }
                          </Label>
                          <Input
                            id="password-field"
                            name="password"
                            type={passwordShow ? "text" : "password"}
                            className="form-control"
                            autoComplete="new-password"
                            placeholder={props.t("password")}
                            onChange={(e) =>{ matchPassword(e.target)}}
                            onBlur={validation.handleBlur}
                            // invalid = {!lengthIsValid}
                            ref={passwordRef}
                            value={password}
                          />
                          <i
                            className={passwordShow ? "fa fa-eye" : "fa fa-eye-slash"}
                            id="togglePassword"
                            onClick={() => setPasswordShow(!passwordShow)}
                            style={{ position: 'absolute', top: '49%', right: '30px', transform: 'translate(82%, 22%)', cursor: 'pointer', fontSize: '16px', color: '#64748b' }}
                          ></i>
                          { !lengthIsValid ? (
                            <p className='text-danger' style={{ fontSize: '12px' }} type="invalid">Password must have at least 6 characters</p>
                          ) : null}
                        </div>
                      </Col>
                    <Col xl={6} sm={6}>
                      <div data-mdb-input-init className="form-outline mb-1" style={{position: 'relative'}}>
                        <Label
                          htmlFor="password-conf-field"
                          className="form-label"
                        >
                          {props.t("confirm_password")} {!!isEdit ? "": <span className='text-danger'>*</span> }
                        </Label>
                        <Input
                          id="password-conf-field"
                          name="confirm_password"
                          type={passwordConfirmShow ? "text" : "password"}
                          className="form-control"
                          autoComplete="new-password"
                          placeholder={props.t("enter_password_again")}
                          onChange={(e) =>{ matchPassword(e.target)}}
                          // invalid = {!isPasswordMatch ? true : false }
                          ref={passwordConfirmRef}
                          value={confirmPassword}
                        />
                        <i
                          className={passwordConfirmShow ? "fa fa-eye" : "fa fa-eye-slash"}
                          id="togglePassword"
                          onClick={() => setPasswordConfirmShow(!passwordConfirmShow)}
                          style={{ position: 'absolute', top: '49%', right: '30px', transform: 'translate(82%, 22%)', cursor: 'pointer', fontSize: '16px', color: '#64748b' }}
                        ></i>

                      {!isPasswordMatch ? (
                          <p className='text-danger' style={{ fontSize: '12px' }} type="invalid">Password don't match</p>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </ModalBody>
                <div className='p-3'>
                  <Row className="align-items-center">
                    <div className="col-sm">
                      <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                    </div>
                    <div className="col-sm-auto">
                      <div className="hstack gap-2 justify-content-end">
                          <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); setIsEdit(false); setCurrentUser(null); setErrorSelectRole(false) }}>
                            {props.t("close")}
                          </button>
                          <button type="submit" className="btn btn-success" disabled={!isPasswordMatch || (!isEdit && !password)} onClick={() => { selectedRoles && selectedRoles.label ? setErrorSelectRole(false) : setErrorSelectRole(true) }} >
                            {!!isEdit ? props.t("update") : props.t("save")}
                          </button>
                      </div>
                    </div>
                  </Row>
                </div>
              </Form>
            </Modal>
          </Card>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Users))