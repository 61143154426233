import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from "yup"
import { useFormik } from 'formik'
import withRouter from '../../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate } from "react-router-dom"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { Col, Input, Label, Row, Form, FormFeedback, Modal, ModalBody, ModalHeader} from 'reactstrap'
import moment from 'moment'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import {
    getDoctorsAppointments as onGetDoctorsAppointments,
    updateAppointment as onUpdateAppointment,
    deleteAppointment as onDeleteAppointment,
    markAppointmentAttended as onMarkAppointmentAttended
} from "../../../../slice/thunks"
import TableContainer from '../../../../components/Common/TableContainer'
import DeleteModal from '../../../../components/Global/DeleteModal'
import GlobalLoader from '../../../../components/Common/GlobalLoader'


function Appointments(props) {
    const dispatch = useDispatch()
    const history = useNavigate()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Doctors;
    const OutputsProperties = createSelector(
      selectLayoutState,
      (outputs) =>({
        doctorAppointments: outputs.doctorAppointments,
        doctorAppointment: outputs.doctorAppointment,
        successUpdate: outputs.successUpdate,
        successDelete: outputs.successDelete,
        markAppointmentAttended: outputs.markAppointmentAttended,
        error: outputs.error
      })
    )
    const { doctorAppointments, doctorAppointment, successUpdate, successDelete, markAppointmentAttended, error } = useSelector(OutputsProperties)
    const [currentAppointment, setCurrentAppointment] = useState(null)
    const [modal, setModal] = useState(false)
    const [modalAttended, setModalAttended] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const loadAppointmentsList = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
            dispatch(onGetDoctorsAppointments({page: pageNumber, size: pageSize}))
        } else {
            dispatch(onGetDoctorsAppointments({page: 1, size: 10}))
        }
    })

    const markAsAttended = useCallback(() => {
        if (currentAppointment) {
            dispatch(onMarkAppointmentAttended(currentAppointment.id))
        }
    })

    const toggle = useCallback(() => {
        if (modal) {
          setModal(false);
          validation.resetForm();
          setCurrentAppointment(null);
        } else {
          setModal(true);
        }
    }, [modal])

    const toggleAttend = useCallback(() => {
        if (modalAttended) {
            setModalAttended(false);
            setCurrentAppointment(null);
        } else {
            setModalAttended(true);
        }
    }, [modalAttended])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
            appointmentDate: (currentAppointment && currentAppointment.appointmentDate) || '',
        },
        validationSchema: Yup.object({
            appointmentDate: Yup.string().required(props.t("you_need_provide_date")),
        }),
        onSubmit: (values) => {
            const updateAppointment = {
                id: currentAppointment.id,
                body: {appointmentDate: values.appointmentDate}
            }
            dispatch(onUpdateAppointment(updateAppointment))
        },
    })


    const handleSelectAppointment = (arg) => {
        setCurrentAppointment(arg)
        toggle();
    }

    const onClickDelete = (appointment) => {
        setCurrentAppointment(appointment);
        setDeleteModal(true);
    }

    useEffect(() => {
        loadAppointmentsList()
    },[dispatch])

    useEffect(() => {
        if (doctorAppointments && doctorAppointments.page) {
          setIsLoading(false)
        }
      },[doctorAppointments])

    useEffect(() =>{
        if (successUpdate && !error) {
          validation.resetForm()
          setModal(false)
          setCurrentAppointment(null)
          loadAppointmentsList()
        }
      },[successUpdate, error, doctorAppointment])

      useEffect(() => {
        if (successDelete) {
            loadAppointmentsList()
        }
      },[successDelete, error, doctorAppointment])

    useEffect(() =>{
        if (markAppointmentAttended && !error) {
          validation.resetForm()
          setModalAttended(false)
          setCurrentAppointment(null)
          loadAppointmentsList()
        }
    },[markAppointmentAttended, error, doctorAppointment])

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleMarkAttended = (arg) => {
        setCurrentAppointment(arg)
        toggleAttend();
    }

    function handleDeleteWards() {
        if (currentAppointment) {
          dispatch(onDeleteAppointment({id: currentAppointment.id, body: currentAppointment }))
          loadAppointmentsList()
          setDeleteModal(false)
        }
      }

    const columns = useMemo(
        () => [
          {
            header: props.t("patient_id"),
            accessorKey: "index",
            style: "button",
            enableColumnFilter: false,
            cell: (cellProps) => {
                return <span>#{cellProps.row.original.patient.patientRef}</span>;
            },
          },
          {
            header: props.t("patients_name"),
            accessorKey: "firstName",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                          <span >
                              {(cell.row.original.patient.lastName != null ) ? cell.row.original.patient.firstName+" "+cell.row.original.patient.lastName : cell.row.original.patient.firstName}
                          </span>
                  </div>
            }
          },
          {
            header: props.t("date"),
            accessorKey: "dob",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return <div className='d-flex align-items-center'>
                          <span>
                            {moment(cell.row.original.appointmentDate).format('DD / MM/ YYYY')}
                          </span>
                  </div>
            }
          },
          {
            header: props.t("attended"),
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                switch (cell.row.original.attended) {
                    case true:
                            return <div className='d-flex align-items-center'><span> {props.t('yes')} </span></div>
                        break;
                    case false:
                            return <div className='d-flex align-items-center'><span> {props.t('no')} </span></div>
                        break;
                
                    default:
                        return <div className='d-flex align-items-center'><span> - </span></div>
                        break;
                }
                
            }
          },
          {
            header: props.t("action"),
            style: "",
            cell: (cellProps) => {
              return (
                <div className="">
                    <ul className="list-inline hstack mb-0">
                        <li className="list-inline-item edit" title="Edit">
                            <Link 
                                to="#"
                                className="text-primary d-inline-block edit-item-btn"
                                onClick={() => { const appointmentData = cellProps.row.original; handleSelectAppointment(appointmentData); }}
                            >
                                <i className="fa-solid fa-pencil"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item edit" title={props.t("mark_attended")}>
                            { !cellProps.row.original.attended ?
                                <Link 
                                    to="#"
                                    className="text-success d-inline-block edit-item-btn"
                                    onClick={() => { const appointmentData = cellProps.row.original; handleMarkAttended(appointmentData); }}
                                >
                                    <i className="fa-solid fa-check"></i>
                                </Link>
                                :<Link
                                    to="#"
                                    className="text-success d-inline-block edit-item-btn">

                                </Link>
                            }
                        </li>
                        <li className="list-inline-item edit" title="Remove">
                            <Link 
                                to="#"
                                className="text-danger d-inline-block remove-item-btn"
                                onClick={() => { const appointmentData = cellProps.row.original; onClickDelete(appointmentData); }}
                            >
                                <i className="fa-solid fa-trash text-danger"></i>
                            </Link>
                        </li>
                    </ul>
                  
                  
                  
                </div>
              );
            },
          },
        ],
        [props]
    );

  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <div className="dashboard">
                    <div className="patient-appointment-list-sec">
                        <div className="container">
                            <div className="heading-parent-sec">
                                <Link to="/doctor/doctor-panel-see-patient" >
                                    <div className="heading-child-one-sec d-flex align-items-center">
                                            <i className="fa-solid fa-chevron-left"></i>
                                        <h6 className='mb-0'>{props.t("my_appointments")}</h6>
                                    </div>
                                </Link>
                                <div className="heading-child-two-sec">
                                    <div className="search-box-sec my-2 ps-2">
                                        <form>
                                            <div className="search-box d-flex">
                                                <div className="magnifying-glass-icon">
                                                    <i className="fa-solid fa-magnifying-glass"></i>
                                                </div>
                                                <div className="search-box-input">
                                                    <input className="form-control" type="search"
                                                        placeholder="Search name/tel no./ patient id" aria-label="Search" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="create-new-patient-btn my-2">
                                        <input type="text" id="datepicker" placeholder="25/04/2024" />
                                        <span><i className="fa-solid fa-calendar-days" id="calendar-icon"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="patient-appointment-list-main-sec pb-3">
                                <div className="consultations-book-tabs-sec">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel"
                                            aria-labelledby="nav-home-tab">
                                                <div className="p-3">
                                                    <TableContainer
                                                        columns={columns}
                                                        data={((doctorAppointments && doctorAppointments.items) || [])}
                                                        isGlobalFilter={false}
                                                        customPageSize={10}
                                                        divClass="card table-card table-responsive p-0"
                                                        tableClass="align-middle"
                                                        tdClass="py-0 text-start"
                                                        theadClass="thead-light table-light"
                                                        tableSize={ doctorAppointments && doctorAppointments.size }
                                                        tablePage={ doctorAppointments && doctorAppointments.page }
                                                        tableTotal= { doctorAppointments && doctorAppointments.total_items }
                                                        tableTotalPage= { doctorAppointments && doctorAppointments.total_page }
                                                        onNextPage= {loadAppointmentsList}
                                                        onSearch= {loadAppointmentsList}
                                                        SearchPlaceholder={props.t("search_patient")}
                                                    />
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DeleteModal show={deleteModal} onDeleteClick={handleDeleteWards} onCloseClick={() =>setDeleteModal(false)} />
                    <Modal id='showModal' size='md' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                        <ModalHeader className="bg-light p-3" toggle={toggle}>
                        {props.t("edit_appointment")}
                        </ModalHeader>
                        <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <ModalBody>
                                <input type="hidden" id="id-field" />
                                <Row>
                                    <Col xl={12} sm={12}>
                                        <div className='mb-2 mt-1'>
                                            <Label htmlFor="appointmentDate-field" className="form-label">{props.t("appointment_date")}<span className='text-danger'>*</span></Label><br/>
                                            <Input 
                                                name="appointmentDate" id='appointmentDate-field' className="form-control" type="date"
                                                validate={{ required: { value: true } }} min={getTodayDate()} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.appointmentDate || ""}
                                                autoComplete="off"
                                                invalid={
                                                    validation.touched.appointmentDate && validation.errors.appointmentDate ? true : false
                                                } 
                                            />
                                            {validation.touched.appointmentDate && validation.errors.appointmentDate ? (
                                                <FormFeedback type="invalid">{validation.errors.appointmentDate}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <div className='p-3'>
                                <Row className="align-items-center">
                                <div className="col-sm">
                                    <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm() }}> {props.t("close")} </button>
                                    <button type="submit" className="btn btn-success"> {props.t("update")} </button>
                                    </div>
                                </div>
                                </Row>
                            </div>
                        </Form>

                    </Modal>
                    <Modal id='modalAttended' size='md' isOpen={modalAttended || false} toggle={toggleAttend} backdrop={'static'} centered>
                            <ModalBody className="py-3 px-5">
                                <div className="mt-2 text-center">
                                    <div className="mt-4 pt-2 mx-4 mx-sm-5">
                                        <h4>{props.t("are_you_sure")}</h4>
                                        <span>{props.t("mark_this_appointment_as_attended")}</span>
                                    </div>
                                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                        <button
                                            type="button"
                                            className="btn w-sm btn-light"
                                            data-bs-dismiss="modal"
                                            onClick={() => { setModalAttended(false)}}
                                        >
                                            {props.t("close")}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn w-sm btn-success "
                                            id="delete-record"
                                            onClick={() => {markAsAttended()}}
                                        >
                                            <i className="fa fa-check"></i>
                                            {props.t("yes_mark_it")}
                                        </button>
                                    </div>

                                </div>
                            </ModalBody>
                    </Modal>
                </div>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Appointments))