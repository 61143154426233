import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import withRouter from '../../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
    getPatientAllVitals as onGetPatientAllVitals,
} from "../../../../slice/thunks"

function SeeVitals(props) {
    const { id, name, ref } = useParams()
    const dispatch = useDispatch()
    const history = useNavigate()
    const selectLayoutState = (state) => state.Doctors;
    const OutputsProperties = createSelector(
      selectLayoutState,
      (outputs) =>({
        patientVitalsHistory: outputs.patientVitalsHistory,
        error: outputs.error
      })
    )
    const { patientVitalsHistory, error } = useSelector(OutputsProperties)

    const loadConsultationList = useCallback((current_id) => {
        dispatch(onGetPatientAllVitals(current_id))
    })

    useEffect(() => {
        if (id) {
            loadConsultationList(id)
        } else {
            history('/doctor/doctor-panel-see-patient')
        }
    },[dispatch])

    useEffect(() => {
    },[patientVitalsHistory])

  return (
    <div className="dashboard">
        {/* <!-- Start doctor-panel-screen-ten --> */}
        <div className="doctor-panel-screen-ten-sec">
            <div className="container pt-3">
                <div className="heading-sec">
                    <a href="javascript:history.back()">
                        <div className="patient-details-heading-sec pb-3">
                            <div className="left-arrow">
                                <i className="fa-solid fa-chevron-left"></i>
                            </div>
                            <div className="heading align-middle">
                                <p className="mb-0">{props.t("patient_history")}</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="tabs-sec">
                    <ul className="nav nav-tabs nav-underline d-flex" id="myTab" role="tablist"
                        style={{ borderBottom: "none" }}>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="vitals-history-tab" data-bs-toggle="tab"
                                data-bs-target="#vitals-history" type="button" role="tab"
                                aria-controls="vitals-history" aria-selected="true">
                                <i className="fa fa-user ms-1 me-2"></i> {props.t("vital_history")}
                            </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="lab-history-tab" data-bs-toggle="tab"
                                data-bs-target="#lab-history" type="button" role="tab"
                                aria-controls="lab-history" aria-selected="false">
                                <i className="fa fa-user me-2"></i> Lab History
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pharmacy-history-tab" data-bs-toggle="tab"
                                data-bs-target="#pharmacy-history" type="button" role="tab"
                                aria-controls="pharmacy-history" aria-selected="false">
                                <i className="fa fa-user me-2" aria-hidden="true"></i> Pharmacy History
                            </button>
                        </li> */}
                    </ul>
                </div>

                <div className="doctor-panel-screen-ten-main-sec pb-3">
                    <div className="row py-2">
                        {/* <!-- TABS Content --> */}
                        <div className="tab-content" id="myTabContent">

                            {/* <!--==================== TAB 1 : vitals-history ===============--> */}

                            <div className="tab-pane fade show active" id="vitals-history" role="tabpanel"
                                aria-labelledby="vitals-history-tab">
                                <div className="doctor-panel-vitals-history-sec">
                                    <div className="row row-one-sec">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12 mb-2">
                                            <span className="">{props.t("patient_id")} - </span><span
                                                className="patient-id-no">{ref}</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-9 col-md-9 col-12 mb-2">
                                            <span className="">{props.t("patient_name")} - </span><span
                                                className="patient-id-no">{name}</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-0 col-md-0 col-0"></div>
                                        <div className="col-xl-3 col-lg-0 col-md-0 col-0"></div>
                                    </div>
                                    {patientVitalsHistory && patientVitalsHistory.map((row, index) =>{
                                        return <div className="row row-three-sec" key={index}>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-12 patient-lab-test pb-3">
                                                    {props.t("patient_vitals")} &nbsp;&nbsp; ( {moment(row.createdAt).format('DD / MM/ YYYY')} )</div>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-12 mb-3">
                                                    <span className="para-one">{props.t("heigth")}</span><br />
                                                    <span className="para-two">{row.height}</span>
                                                </div>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                    <span className="para-one">{props.t("weight")}</span><br />
                                                    <span className="para-two">{row.weight}</span>
                                                </div>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                    <span className="para-one">{props.t("bp")}</span><br />
                                                    <span className="para-two">{props.bp}</span>
                                                </div>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                                    <span className="para-one">{props.t("temperature")}</span><br />
                                                    <span className="para-two">{props.t("temperature")}</span>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                    <span className="para-one">{props.t("oxygen_saturation")} (%SPO²)</span><br />
                                                    <span className="para-two">{row.spo}</span>
                                                </div>
                                            </div>
                                        })

                                    }
                                    
                                </div>
                            </div>

                            {/* <!--=============== TAB 2 : lab-history ========================--> */}

                            {/* <div className="tab-pane fade" id="lab-history" role="tabpanel"
                                aria-labelledby="lab-history-tab">
                                <div className="doctor-panel-lab-history-sec">
                                    <div className="row row-one-sec">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12 mb-2">
                                            <span className="">Patient ID - </span><span
                                                className="patient-id-no">25865</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-9 col-md-9 col-12 mb-2">
                                            <span className="">Patient Name - </span><span
                                                className="patient-id-no">john Deo</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-0 col-md-0 col-0"></div>
                                        <div className="col-xl-3 col-lg-0 col-md-0 col-0"></div>
                                    </div>
                                    <div className="row row-three-sec">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 patient-lab-test pb-3">
                                            Patient Lab Test ( 25-03-2024 )</div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Lab</span><br />
                                            <span className="para-two">Pathology</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Test Name</span><br />
                                            <span className="para-two">Blood Test</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Issue Date</span><br />
                                            <span className="para-two">10/04/2020</span>
                                        </div>
                                    </div>
                                    <div className="row row-three-sec">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 patient-lab-test pb-3">
                                            Patient Lab Test ( 15-03-2024 )</div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Lab</span><br />
                                            <span className="para-two">Pathology</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Test Name</span><br />
                                            <span className="para-two">Blood Test</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Issue Date</span><br />
                                            <span className="para-two">10/04/2020</span>
                                        </div>
                                    </div>
                                    <div className="row row-three-sec">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 patient-lab-test pb-3">
                                            Patient Lab Test ( 01-03-2024 )</div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Lab</span><br />
                                            <span className="para-two">Pathology</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Test Name</span><br />
                                            <span className="para-two">Blood Test</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Issue Date</span><br />
                                            <span className="para-two">10/04/2020</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <!--===================== TAB 3 : pharmacy-history ==============--> */}

                            {/* <div className="tab-pane fade" id="pharmacy-history" role="tabpanel"
                                aria-labelledby="pharmacy-history-tab">
                                <div className="doctor-panel-pharmacy-history-sec">
                                    <div className="row row-one-sec">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12 mb-2">
                                            <span className="">Patient ID - </span><span
                                                className="patient-id-no">25865</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-9 col-md-9 col-12 mb-2">
                                            <span className="">Patient Name - </span><span
                                                className="patient-id-no">john Deo</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-0 col-md-0 col-0"></div>
                                        <div className="col-xl-3 col-lg-0 col-md-0 col-0"></div>
                                    </div>
                                    <div className="row row-two-sec">
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 py-2">
                                            <span className="patient-lab-test">Patient Lab Test ( 25-03-2024
                                                )</span>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                                            <span className="medicine-name">Medicine Name</span><br />
                                            <span className="medicine-quantity">Aspirin 300 mg, Caffeine 45
                                                mg...</span>
                                        </div>
                                        <div className="col-xl-4 col-lg-0 col-md-0 col-0"></div>
                                    </div>
                                    <div className="row row-three-sec">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Medicine Company</span><br />
                                            <span className="para-two">Cipla</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Medicine Name</span><br />
                                            <span className="para-two">Nicip</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Issue Date</span><br />
                                            <span className="para-two">10/04/2020</span>
                                        </div>
                                    </div>
                                    <div className="row row-three-sec">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 patient-lab-test pb-3">
                                            Patient Lab Test ( 15-03-2024 )</div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Medicine Company</span><br />
                                            <span className="para-two">Cipla</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Medicine Name</span><br />
                                            <span className="para-two">Nicip</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Issue Date</span><br />
                                            <span className="para-two">10/04/2020</span>
                                        </div>
                                    </div>
                                    <div className="row row-three-sec">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 patient-lab-test pb-3">
                                            Patient Lab Test ( 01-03-2024 )</div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Medicine Company</span><br />
                                            <span className="para-two">Cipla</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Medicine Name</span><br />
                                            <span className="para-two">Nicip</span>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                            <span className="para-one">Issue Date</span><br />
                                            <span className="para-two">10/04/2020</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
        {/* <!-- End doctor-panel-screen-ten --> */}
    </div>
  )
}

export default withRouter(withTranslation()(SeeVitals))