import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
// i18n
// import { t } from 'i18next'

import {
    // Permissions
    getPermission as getPermissionApi,
    getPermissions as getPermissionsApi,
    updatePermission as updatePermissionApi,
    // Roles
    getRoles as getRolesApi,
    getAllRoles as getAllRolesApi,
    addNewRole as addNewRoleApi,
    getRole as getRoleApi,
    updateRole as updateRoleApi,
    deleteRole as deleteRoleApi,
    searchRole as searchRoleApi,
    // Users
    getUser as getUserApi,
    getUsers as getUsersApi,
    loadUser as loadUserApi,
    addNewUser as addNewUserApi,
    updateUser as updateUserApi,
    deleteUser as deleteUserApi,
    searchUser as searchUserApi,
    // wards
    getWards as getWardsApi,
    loadWards as loadWardsApi,
    addNewWard as addNewWardApi,
    updateWard as updateWardApi,
    deleteWard as deleteWardApi,
    searchWard as searchWardApi,
    // Beds
    getBeds as getBedsApi,
    addNewBed as addNewBedApi,
    updateBed as updateBedApi,
    deleteBed as deleteBedApi,
    searchBed as searchBedApi,
    // Doctors
    getDoctors as getDoctorsApi,
    getDoctorTitle as getDoctorTitleApi,
    getDoctorInfo as getDoctorInfoApi,
    updateDocotor as updateDocotorApi,
    deleteDoctor as deleteDoctorApi,
    searchDoctor as searchDoctorApi,
    // Categories
    getCategories as getCategoriesApi,
    getAllCategories as getAllCategoriesApi,
    getCategory as getCategoryApi,
    createCategory as createCategoryApi,
    updateCategory as updateCategoryApi,
    deleteCategory as deleteCategoryApi,
    searchCategory as searchCategoryApi,
    // Classes Account
    getClassesAccounts as getClassesAccountsApi,
    getParentClassesAccount as getParentClassesAccountApi,
    getTypesClassesAccount as getTypesClassesAccountApi,
    getSubClassAccount as getSubClassAccountApi,
    createClassAccount as createClassAccountApi,
    getClassAccount as getClassAccountApi,
    updateClassAccount as updateClassAccountApi,
    deleteClassAccount as deleteClassAccountApi,
    searchClassAccount as searchClassAccountApi,
    getParentSubClassAccount as getParentSubClassAccountApi,
    // Warehouse Suppliers
    getSuppliers as getSuppliersApi,
    getAllSuppliers as getAllSuppliersApi,
    getSupplier as getSupplierApi,
    createSupplier as createSupplierApi,
    updateSupplier as updateSupplierApi,
    deleteSupplier as deleteSupplierApi,
    searchSupplier as searchSupplierApi,
    // Warehouse Products
    getProducts as getProductsApi,
    getProductsAndSearch as getProductsAndSearchApi,
    getProductsAndSearchStock as getProductsAndSearchStockApi,
    getProduct as getProductApi,
    createProduct as createProductApi,
    updateProduct as updateProductApi,
    searchProduct as searchProductApi,
    deleteProduct as deleteProductApi,
    importProducts as importProductsApi,
    // Warehouse Supplier-order
    getSuppliersOrder as getSuppliersOrderApi,
    getSupplierOrder as getSupplierOrderApi,
    addSupplierOrder as addSupplierOrderApi,
    updateSupplierOrder as updateSupplierOrderApi,
    confirmSupplierOrder as confirmSupplierOrderApi,
    deleteSupplierOrderItem as deleteSupplierOrderItemApi,
    // Warehouse Stock
    getWarehouseStock as getWarehouseStockApi,
    // Product discount
    getProductsDiscount as getProductsDiscountApi,
    getProductDiscount as getProductDiscountApi,
    createProductDiscount as createProductDiscountApi,
    updateProductDiscount as updateProductDiscountApi,
    deleteProductDiscount as deleteProductDiscountApi,
    // Transfert Order
    getTransfertOrders as getTransfertOrdersApi,
    getTransfertOrder as getTransfertOrderApi,
    createTransfertOrder as createTransfertOrderApi,
    updateTransfertOrder as updateTransfertOrderApi,
    confirmTransfertOrder as confirmTransfertOrderApi,
    deleteTransfertOrderItem as deleteTransfertOrderItemApi,
    // Pharmacy stock
    getPharmacyStock as getPharmacyStockApi,
    getPharmacyProductsAndSearch as getPharmacyProductsAndSearchApi,
    getSales as getSalesApi,
    getSale as getSaleApi,
    createSale as createSaleApi,
    // Associated service
    getAssociatedService as getAssociatedServiceApi,
    getInvoice as getInvoiceApi,
    getInvoices as getInvoicesApi,
    getPatientInvoice as getPatientInvoiceApi,
    getInvoiceStatus as getInvoiceStatusApi,
    getPaymentMode as getPaymentModeApi,
    // Payment
    makePayment as makePaymentApi,

    getFinancialYears as getFinancialYearsApi,
    getFinancialYear as getFinancialYearApi,
    createFinancialYear as createFinancialYearApi,
    closeFinancialYear as closeFinancialYearApi,
    openFinancialYear as openFinancialYearApi,
    getPatientAccountHistory as getPatientAccountHistoryApi,
    // Patient Account
    getPatientAccounts as getPatientAccountsApi,
    getPatientAccount as getPatientAccountApi,
    createPatientAccount as createPatientAccountApi,
    makeDeposit as makeDepositApi,
    // Payments
    getPayments as getPaymentsApi,
    getPayment as getPaymentApi,
    confirmPayment as confirmPaymentApi,
    // Deposits
    getDeposits as getDepositsApi,
    // CashDesk
    createCashDesk as createCashDeskApi,
    openCashDesk as openCashDeskApi,
    closeCashDesk as closeCashDeskApi,
    getCloseCashDesks as getCloseCashDesksApi,
    getCashDesks as getCashDesksApi,
    checkOpenCashdesk as checkOpenCashdeskApi,
    getAmountsCollectedGroupBySources as getAmountsCollectedGroupBySourcesApi,
    updateCashDesk as updateCashDeskApi,
    // Cash journal
    getCashJournal as getCashJournalApi,
    // Bank Account
    createBankAccount as createBankAccountApi,
    updateBankAccount as updateBankAccountApi,
    deleteBankAccount as deleteBankAccountApi,
    getBankAccounts as getBankAccountsApi,
    getAllBankAccounts as getAllBankAccountsApi,
    // Budgeting
    getBudgeting as getBudgetingApi,
    createBudgeting as createBudgetingApi,
    // Requisition
    getRequisitions as getRequisitionsApi,
    createRequisition as createRequisitionApi,
    getRequisition as getRequisitionApi,
    approveRequisition as approveRequisitionApi,
    rejectRequisition as rejectRequisitionApi,
    payRequisition as payRequisitionApi,
    cancelRequisition as cancelRequisitionApi,
    updateRequisition as updateRequisitionApi,
    getRequisitionStatus as getRequisitionStatusApi,
    // CashDrop
    getCashDrops as getCashDropsApi,
    createCashDrop as createCashDropApi,
    // OtherRevenue
    getOtherRevenues as getOtherRevenuesApi,
    createOtherRevenue as createOtherRevenueApi,
    // PrintSetting
    getPrintSetting as getPrintSettingApi,
    savePrintSetting as savePrintSettingApi,
    // Account summary
    getAccountSummary as getAccountSummaryApi,
    getAccountSummaryDetails as getAccountSummaryDetailsApi,
}
from "../../helpers/backend_helper";


/**
 * 
 */

export const resetReducer = createAsyncThunk("settings/resetReducer", async() => {
    
})

/**
 * Permissions
 */
export const getPermissons = createAsyncThunk("settings/getPermissions", async() => {
    try {
        const response = await getPermissionsApi()
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const getPermission = createAsyncThunk("settings/getPermission", async(id) => {
    try {
        const response = await getPermissionApi(id)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const updatePermission = createAsyncThunk("settings/updatePermissions", async(data) => {
    try {
        const response = await updatePermissionApi(data)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

/**
 *  Roles
 */
export const getRoles = createAsyncThunk("settings/getRoles", async(config) => {
    try {
        const response = await getRolesApi(config)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const getAllRoles = createAsyncThunk("settings/getAllRoles", async() => {
    try {
        const response = await getAllRolesApi()
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const getRole = createAsyncThunk("settings/getRole", async(id) => {
    try {
        const response = await getRoleApi(id)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const addRole = createAsyncThunk("settings/addRole", async(data) => {
    try {
        const response = await addNewRoleApi(data)
        toast.success(response.message, { autoClose: 5000 })
        getRoles()
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const updateRole = createAsyncThunk("settings/updateRole", async(config) => {
    try {
        const response = await updateRoleApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const deleteRole = createAsyncThunk("settings/deleteRole", async(config) => {
    try {
        const response = await deleteRoleApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const searchRole = createAsyncThunk("settings/searchRole", async(config) => {
    try {
        const response =  await searchRoleApi(config)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

/**
 * Users
 */
export const getProfile = createAsyncThunk("settings/getProfile", async() => {
    try {
        const response = await getUserApi()
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const getUsers = createAsyncThunk("settings/getUsers", async(config) => {
    try {
        const response = await getUsersApi(config)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const loadUser = createAsyncThunk("settings/loadUsers", async() => {
    try {
        const response = await loadUserApi()
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const addUser = createAsyncThunk("settings/addUser", async(data) => {
    try {
        const response = await addNewUserApi(data)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const updateUser = createAsyncThunk("settings/updateUser", async(config) => {
    try {
        const response = await updateUserApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const deleteUser =  createAsyncThunk("settings/deleteUsers", async(id) => {
    try {
        const response = await deleteUserApi(id)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const searchUser = createAsyncThunk("settings/searchUser", async(config) => {
    try {
        const response = await searchUserApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

/*
* Doctors
*/
export const getDoctors = createAsyncThunk("outputs/doctors", async(config) => {
    try {
        const response = await getDoctorsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getDoctorTitle = createAsyncThunk("outputs/doctor_title", async() => {
    try {
        const response = await getDoctorTitleApi()
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getDoctorInfo = createAsyncThunk("outputs/doctor_info", async(id) => {
    try {
        const response = await getDoctorInfoApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const addDoctor = createAsyncThunk("settings/addDoctor", async(data) => {
    try {
        const response = await addNewUserApi(data)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message? error.message: "Internal error", { autoClose: 5000 })
        return Promise.reject(error)
    }
})

export const updateDoctor = createAsyncThunk("outputs/updateDoctor", async(config) => {
    try {
        const response = await updateDocotorApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message? error.message: "Internal error", { autoClose: 5000 })
        return Promise.rejected(error)
    }
})

export const deleteDoctor =  createAsyncThunk("outputs/deleteDoctor", async(config) => {
    try {
        const response = await deleteDoctorApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message? error.message: "Internal error", { autoClose: 5000 })
        return Promise.rejected(error)
    }
})

export const searchDoctor = createAsyncThunk("settings/searchDoctor", async(config) => {
    try {
        const response =  await searchDoctorApi(config)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

/**
 * Wards
 */
export const getWards = createAsyncThunk("settings/getWards", async(config) => {
    try {
        const response = await getWardsApi(config)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const loadWards = createAsyncThunk("settings/loadWards", async() => {
    try {
        const response = await loadWardsApi()
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const addWard = createAsyncThunk("settings/addWard", async(data) => {
    try {
        const response = await addNewWardApi(data)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const updateWard = createAsyncThunk("settings/updateWard", async(config) => {
    try {
        const response = await updateWardApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const deleteWard =  createAsyncThunk("settings/deleteWard", async(config) => {
    try {
        const response = await deleteWardApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const searchWard = createAsyncThunk("settings/searchWard", async(config) => {
    try {
        const response = await searchWardApi(config)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

/**
 * Beds
 */
export const getBeds = createAsyncThunk("settings/getBeds", async(config) => {
    try {
        const response = await getBedsApi(config)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const addBed = createAsyncThunk("settings/addBed", async(data) => {
    try {
        const response = await addNewBedApi(data)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const updateBed = createAsyncThunk("settings/updateBed", async(config) => {
    try {
        const response = await updateBedApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const deleteBed =  createAsyncThunk("settings/deleteBed", async(id) => {
    try {
        const response = await deleteBedApi(id)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const searchBed = createAsyncThunk("settings/searchBed", async(config) => {
    try {
        const response = await searchBedApi(config)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

// Categories

export const getCategories = createAsyncThunk("output/getCategories", async(config) => {
    try {
        const response = await getCategoriesApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getAllCategories = createAsyncThunk("output/getAllCategories", async() => {
    try {
        const response = await getAllCategoriesApi()
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getCategory = createAsyncThunk("output/getCategory", async(id) => {
    try {
        const response = await getCategoryApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createCategory = createAsyncThunk("output/createCategory", async(data) => {
    try {
        const response = await createCategoryApi(data)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const updateCategory = createAsyncThunk("output/updateCategory", async(config) => {
    try {
        const response = await updateCategoryApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const deleteCategory = createAsyncThunk("output/deleteCategory", async(id) => {
    try {
        const response = await deleteCategoryApi(id)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const searchCategory = createAsyncThunk("output/searchCategory", async(config) => {
    try {
        const response = await searchCategoryApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})


// Classes Account

export const getClassesAccounts = createAsyncThunk("output/getClassesAccounts", async(config) => {
    try {
        const response = await getClassesAccountsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getParentClassesAccount = createAsyncThunk("output/getParentClassesAccount", async(config) => {
    try {
        const response = await getParentClassesAccountApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getParentSubClassAccount = createAsyncThunk("output/getParentSubClassAccount", async(config) => {
    try {
        const response = await getParentSubClassAccountApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getTypesClassesAccount = createAsyncThunk("output/getTypesClassesAccount", async(config) => {
    try {
        const response = await getTypesClassesAccountApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getSubClassAccount = createAsyncThunk("output/getSubClassAccount", async(config) => {
    try {
        const response = await getSubClassAccountApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createClassAccount = createAsyncThunk("output/createClassAccount", async(config) => {
    try {
        const response = await createClassAccountApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const getClassAccount = createAsyncThunk("output/getClassAccount", async(config) => {
    try {
        const response = await getClassAccountApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const updateClassAccount = createAsyncThunk("output/updateClassAccount", async(config) => {
    try {
        const response = await updateClassAccountApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const deleteClassAccount = createAsyncThunk("output/deleteClassAccount", async(id) => {
    try {
        const response = await deleteClassAccountApi(id)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const searchClassAccount = createAsyncThunk("output/searchClassAccount", async(config) => {
    try {
        const response = await searchClassAccountApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

// WAREHOUSE Supplier

export const getSuppliers = createAsyncThunk("output/getSuppliers", async(config) => {
    try {
        const response = await getSuppliersApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getSupplier = createAsyncThunk("output/getSupplier", async(config) => {
    try {
        const response = await getSupplierApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createSupplier = createAsyncThunk("output/createSupplier", async(config) => {
    try {
        const response = await createSupplierApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const updateSupplier = createAsyncThunk("output/updateSupplier", async(config) => {
    try {
        const response = await updateSupplierApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const deleteSupplier = createAsyncThunk("output/deleteSupplier", async(id) => {
    try {
        const response = await deleteSupplierApi(id)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const searchSupplier = createAsyncThunk("output/searchSupplier", async(config) => {
    try {
        const response = await searchSupplierApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})



// WAREHOUSE Products

export const getProducts = createAsyncThunk("output/getProducts", async(config) => {
    try {
        const response = await getProductsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getProductsAndSearch = createAsyncThunk("output/getProductsAndSearch", async(config) => {
    try {
        const response = await getProductsAndSearchApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getProductsAndSearchStock = createAsyncThunk("output/getProductsAndSearchStock", async(config) => {
    try {
        const response = await getProductsAndSearchStockApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})


export const getProduct = createAsyncThunk("output/getProduct", async(config) => {
    try {
        const response = await getProductApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createProduct = createAsyncThunk("output/createProduct", async(config) => {
    try {
        const response = await createProductApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const updateProduct = createAsyncThunk("output/updateProduct", async(config) => {
    try {
        const response = await updateProductApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const searchProduct = createAsyncThunk("output/searchProduct", async(config) => {
    try {
        const response = await searchProductApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const deleteProduct = createAsyncThunk("output/deleteProduct", async(id) => {
    try {
        const response = await deleteProductApi(id)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const importProducts = createAsyncThunk("output/importProducts", async(formData) => {
    try {
        const response = await importProductsApi(formData)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

// WAREHOUSE Suppliers orders
export const getSuppliersOrder = createAsyncThunk("output/getSuppliersOrder", async(config) => {
    try {
        const response = await getSuppliersOrderApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getSupplierOrder = createAsyncThunk("output/getSupplierOrder", async(id) => {
    try {
        const response = await getSupplierOrderApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getAllSuppliers= createAsyncThunk("output/getAllSuppliers", async(config) => {
    try {
        const response = await getAllSuppliersApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const addSupplierOrder = createAsyncThunk("output/addSupplierOrder", async(config) => {
    try {
        const response = await addSupplierOrderApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const updateSupplierOrder = createAsyncThunk("output/updateSupplierOrder", async(config) => {
    try {
        const response = await updateSupplierOrderApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})


export const confirmSupplierOrder = createAsyncThunk("output/confirmSupplierOrder", async(config) => {
    try {
        const response = await confirmSupplierOrderApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const deleteSupplierOrderItem = createAsyncThunk("output/deleteSupplierOrderItem", async(config) => {
    try {
        const response = await deleteSupplierOrderItemApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})


// Warehoue Stock
export const getWarehouseStock = createAsyncThunk("output/getWarehouseStock", async(config) => {
    try {
        const response = await getWarehouseStockApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

// Product Discount

export const getProductsDiscount = createAsyncThunk("settings/getProductsDiscount", async(config) => {
    try {
        const response = await getProductsDiscountApi(config)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const getProductDiscount = createAsyncThunk("settings/getProductDiscount", async(id) => {
    try {
        const response = await getProductDiscountApi(id)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const createProductDiscount = createAsyncThunk("settings/createProductDiscount", async(data) => {
    try {
        const response = await createProductDiscountApi(data)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const updateProductDiscount = createAsyncThunk("settings/updateProductDiscount", async(config) => {
    try {
        const response = await updateProductDiscountApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const deleteProductDiscount =  createAsyncThunk("settings/deleteProductDiscount", async(config) => {
    try {
        const response = await deleteProductDiscountApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

// Transfert orders

export const getTransfertOrders = createAsyncThunk("settings/getTransfertOrders", async(config) => {
    try {
        const response = await getTransfertOrdersApi(config)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const getTransfertOrder = createAsyncThunk("settings/getTransfertOrder", async(id) => {
    try {
        const response = await getTransfertOrderApi(id)
        return response
    } catch (error) {
        return Promise.reject(error)
    }
})

export const createTransfertOrder = createAsyncThunk("settings/createTransfertOrder", async(data) => {
    try {
        const response = await createTransfertOrderApi(data)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const updateTransfertOrder = createAsyncThunk("settings/updateTransfertOrder", async(config) => {
    try {
        const response = await updateTransfertOrderApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const confirmTransfertOrder =  createAsyncThunk("settings/confirmTransfertOrder", async(config) => {
    try {
        const response = await confirmTransfertOrderApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const deleteTransfertOrderItem = createAsyncThunk("output/deleteTransfertOrderItem", async(config) => {
    try {
        const response = await deleteTransfertOrderItemApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

// Pharmacy Stock
export const getPharmacyStock = createAsyncThunk("output/getPharmacyStock", async(config) => {
    try {
        const response = await getPharmacyStockApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getPharmacyProductsAndSearch = createAsyncThunk("output/getPharmacyProductsAndSearch", async(config) => {
    try {
        const response = await getPharmacyProductsAndSearchApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getSales = createAsyncThunk("output/getSales", async(config) => {
    try {
        const response = await getSalesApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getSale = createAsyncThunk("output/getSale", async(config) => {
    try {
        const response = await getSaleApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})


export const createSale = createAsyncThunk("settings/createSale", async(data) => {
    try {
        const response = await createSaleApi(data)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.reject(error)
    }
})

export const getAssociatedService = createAsyncThunk("output/getAssociatedService", async(config) => {
    try {
        const response = await getAssociatedServiceApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getInvoice = createAsyncThunk("output/getInvoice", async(config) => {
    try {
        const response = await getInvoiceApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getInvoices = createAsyncThunk("output/getInvoices", async(config) => {
    try {
        const response = await getInvoicesApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getPatientInvoice = createAsyncThunk("output/getPatientInvoice", async(config) => {
    try {
        const response = await getPatientInvoiceApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})


export const getInvoiceStatus = createAsyncThunk("output/getInvoiceStatus", async() => {
    try {
        const response = await getInvoiceStatusApi()
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getPaymentMode = createAsyncThunk("output/getPaymentMode", async(config) => {
    try {
        const response = await getPaymentModeApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

// Payment
export const makePayment = createAsyncThunk("output/makePayment", async(config) => {
    try {
        const response = await makePaymentApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})


// Financial year
export const getFinancialYears = createAsyncThunk("output/getFinancialYears", async(config) => {
    try {
        const response = await getFinancialYearsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getFinancialYear = createAsyncThunk("output/getFinancialYear", async(config) => {
    try {
        const response = await getFinancialYearApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createFinancialYear = createAsyncThunk("output/createFinancialYear", async(config) => {
    try {
        const response = await createFinancialYearApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const closeFinancialYear = createAsyncThunk("output/closeFinancialYear", async(config) => {
    try {
        const response = await closeFinancialYearApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const openFinancialYear = createAsyncThunk("output/openFinancialYear", async(config) => {
    try {
        const response = await openFinancialYearApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const getPatientAccountHistory = createAsyncThunk("output/getPatientAccountHistory", async(config) => {
    try {
        const response = await getPatientAccountHistoryApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

// Patient Account

export const getPatientAccounts = createAsyncThunk("output/getPatientAccounts", async(config) => {
    try {
        const response = await getPatientAccountsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getPatientAccount = createAsyncThunk("output/getPatientAccount", async(id) => {
    try {
        const response = await getPatientAccountApi(id)
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const createPatientAccount = createAsyncThunk("output/createPatientAccount", async(config) => {
    try {
        const response = await createPatientAccountApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const makeDeposit = createAsyncThunk("output/makeDeposit", async(config) => {
    try {
        const response = await makeDepositApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})



export const getPayments = createAsyncThunk("output/getPayments", async(id) => {
    try {
        const response = await getPaymentsApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getPayment = createAsyncThunk("output/getPayment", async(id) => {
    try {
        const response = await getPaymentApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const confirmPayment = createAsyncThunk("output/confirmPayment", async(config) => {
    try {
        const response = await confirmPaymentApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const getDeposits = createAsyncThunk("output/getDeposits", async(id) => {
    try {
        const response = await getDepositsApi(id)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})


export const createCashDesk = createAsyncThunk("output/createCashDesk", async(config) => {
    try {
        const response = await createCashDeskApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const openCashDesk = createAsyncThunk("output/openCashDesk", async(config) => {
    try {
        const response = await openCashDeskApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const closeCashDesk = createAsyncThunk("output/closeCashDesk", async(config) => {
    try {
        const response = await closeCashDeskApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const getCloseCashDesks = createAsyncThunk("output/getCloseCashDesks", async(config) => {
    try {
        const response = await getCloseCashDesksApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getCashDesks = createAsyncThunk("output/getCashDesks", async(config) => {
    try {
        const response = await getCashDesksApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const checkOpenCashdesk = createAsyncThunk("output/checkOpenCashdesk", async(config) => {
    try {
        const response = await checkOpenCashdeskApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getAmountsCollectedGroupBySources = createAsyncThunk("output/getAmountsCollectedGroupBySources", async(config) => {
    try {
        const response = await getAmountsCollectedGroupBySourcesApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const updateCashDesk = createAsyncThunk("output/updateCashDesk", async(config) => {
    try {
        const response = await updateCashDeskApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

// Get cash journal
export const getCashJournal = createAsyncThunk("output/getCashJournal", async(config) => {
    try {
        console.log(config);
        
        const response = await getCashJournalApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

// Bank Account
export const getBankAccounts = createAsyncThunk("output/getBankAccounts", async(config) => {
    try {
        const response = await getBankAccountsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getAllBankAccounts = createAsyncThunk("output/getAllBankAccounts", async(config) => {
    try {
        const response = await getAllBankAccountsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createBankAccount = createAsyncThunk("output/createBankAccount", async(config) => {
    try {
        const response = await createBankAccountApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const updateBankAccount = createAsyncThunk("output/updateBankAccount", async(config) => {
    try {
        const response = await updateBankAccountApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const deleteBankAccount = createAsyncThunk("output/deleteBankAccount", async(config) => {
    try {
        const response = await deleteBankAccountApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

// Budgeting

export const getBudgeting = createAsyncThunk("output/getBudgeting", async(config) => {
    try {
        const response = await getBudgetingApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createBudgeting = createAsyncThunk("output/createBudgeting", async(config) => {
    try {
        const response = await createBudgetingApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

// Requisition

export const getRequisitions = createAsyncThunk("output/getRequisitions", async(config) => {
    try {
        const response = await getRequisitionsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})


export const getRequisition = createAsyncThunk("output/getRequisition", async(config) => {
    try {
        const response = await getRequisitionApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})


export const getRequisitionStatus = createAsyncThunk("output/getRequisitionStatus", async(config) => {
    try {
        const response = await getRequisitionStatusApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createRequisition = createAsyncThunk("output/createRequisition", async(config) => {
    try {
        const response = await createRequisitionApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const approveRequisition = createAsyncThunk("output/approveRequisition", async(config) => {
    try {
        const response = await approveRequisitionApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const rejectRequisition = createAsyncThunk("output/rejectRequisition", async(id) => {
    try {
        const response = await rejectRequisitionApi(id)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const payRequisition = createAsyncThunk("output/payRequisition", async(config) => {
    try {
        const response = await payRequisitionApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const cancelRequisition = createAsyncThunk("output/cancelRequisition", async(config) => {
    try {
        const response = await cancelRequisitionApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

export const updateRequisition = createAsyncThunk("output/updateRequisition", async(config) => {
    try {
        const response = await updateRequisitionApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

// CashDrop
export const getCashDrops = createAsyncThunk("output/getCashDrops", async(config) => {
    try {
        const response = await getCashDropsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createCashDrop = createAsyncThunk("output/createCashDrop", async(config) => {
    try {
        const response = await createCashDropApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

// OtherRevenue
export const getOtherRevenues = createAsyncThunk("output/getOtherRevenues", async(config) => {
    try {
        const response = await getOtherRevenuesApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const createOtherRevenue = createAsyncThunk("output/createOtherRevenue", async(config) => {
    try {
        const response = await createOtherRevenueApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})


// PrintSetting
export const getPrintSetting = createAsyncThunk("output/getPrintSetting", async(config) => {
    try {
        const response = await getPrintSettingApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const savePrintSetting = createAsyncThunk("output/savePrintSetting", async(config) => {
    try {
        const response = await savePrintSettingApi(config)
        toast.success(response.message, { autoClose: 5000 })
        return response
    } catch (error) {
        toast.error(error.message, { autoClose: 10000 })
        return Promise.rejected(error)
    }
})

// Account summary
export const getAccountSummary = createAsyncThunk("output/getAccountSummary", async(config) => {
    try {
        const response = await getAccountSummaryApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

export const getAccountSummaryDetails = createAsyncThunk("output/getAccountSummaryDetails", async(config) => {
    try {
        const response = await getAccountSummaryDetailsApi(config)
        return response
    } catch (error) {
        return Promise.rejected(error)
    }
})

