import React from 'react'
import { withTranslation  } from 'react-i18next'
import withRouter from '../../components/Common/withRouter'

const Dashboard = (props) => {

  document.title = props.t("app_title")
  return (
    <>
            
    </>
  )
}

export default withRouter(withTranslation()(Dashboard));
