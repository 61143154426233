import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import makeAnimated from "react-select/animated"
import {
    getProducts as onGetProducts,
    getSubClassAccount as onGetSubClassAccount,
    getAllCategories as onGetAllCategories,
    getAssociatedService as onGetAssociatedService,
    createProduct as onCreateProduct,
    updateProduct as onUpdateProduct,
    deleteProduct as onDeleteProduct,
  } from "../../../slice/thunks"
import { Input, Modal, FormFeedback, Form, ModalBody, Row, Col } from 'reactstrap'
import DeleteModal from '../../../components/Global/DeleteModal'
import TableContainerWarehouse from '../../../components/Common/TableContainerWarehouse'
import GlobalLoader from '../../../components/Common/GlobalLoader'
import ImportProduct from '../../../components/App/Warehouse/ImportProduct'

const ItemRecord = (props) => {
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated()
  const selectLayoutState = (state) => state.Settings;
  const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
          subClassAccount: setting.subClassAccount,
          products: setting.products,
          product: setting.product,
          all_categories: setting.all_categories,
          associatedService: setting.associatedService,
          createProductSuccess: setting.createProductSuccess,
          updateProductSuccess: setting.updateProductSuccess,
          deleteProductSuccess: setting.deleteProductSuccess,
          importProductSuccess: setting.importProductSuccess,
          error: setting.error
      })
  )
  const { products, product, all_categories, associatedService, importProductSuccess, subClassAccount, createProductSuccess, updateProductSuccess, deleteProductSuccess, error } = useSelector(SettingsProperties)
  const [currentProduct, setCurrentProduct] = useState(null)
  const [subClassAccountOption, setSubClassAccountOption] = useState([])
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [selectedCategories, setselectedCategories] = useState(null)
  const [selectedClassAccount, setselectedClassAccount] = useState(null)
  const [selectedService, setSelectedService] = useState(null)
  const [selectedType, setSelectedType] = useState(null)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [serviceOption, setServiceOption ] = useState([])
  const [isImportCSV, setIsImportCSV] = useState(false)

  const typeOption = [
    {label: props.t("goods"), value: "Goods"},
    {label: props.t("service"), value: "Service"},
  ]

  const loadProducts = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetProducts({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetProducts())
    }
  })

  const loadSubClassAccount = useCallback(() => {
    dispatch(onGetSubClassAccount())
  })

  const loadCategories = useCallback(() => {
    dispatch(onGetAllCategories())
  })

  const loadServices = useCallback(() => {
    dispatch(onGetAssociatedService())
},[])

  const searchProduct = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
        dispatch(onGetProducts({term: term, page: pageNumber, size: pageSize}))
    } else {
        dispatch(onGetProducts({term: term, page: 1, size: 10}))
    }
  },[])

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      validation.resetForm();
      setCurrentProduct(null);
      setselectedCategories(null);
      setselectedClassAccount(null);
      setSelectedService(null);
    } else {
      setModal(true);
    }
  }, [modal])

  // close import
  const handleImportClose = () => {
    setIsImportCSV(false);
  }

  useEffect(() => {
    if (products && products.page > 0) {
        setIsLoading(false)
    }
  },[products])

  useEffect(() => {
    if (createProductSuccess && !error) {
      setIsEdit(false)
      validation.resetForm()
      setModal(false)
      setCurrentProduct(null)
      setselectedCategories(null);
      setselectedClassAccount(null);
      setSelectedService(null);
      loadProducts()
    }
  },[createProductSuccess, error, product])

  useEffect(() =>{
    if (importProductSuccess && !error) {
      setIsEdit(false)
      loadProducts()
    }
  },[importProductSuccess, error, product])

  useEffect(() => {
      if (updateProductSuccess && !error) {
        setIsEdit(false)
        validation.resetForm()
        setModal(false)
        setCurrentProduct(null)
        setselectedCategories(null);
        setselectedClassAccount(null);
        setSelectedService(null);
        loadProducts()
      }
  },[updateProductSuccess, error, product])

  useEffect(() => {
      if (deleteProductSuccess && !error) {
        setIsEdit(false)
        validation.resetForm()
        setModal(false)
        setCurrentProduct(null)
        setselectedCategories(null)
        setselectedClassAccount(null)
        setSelectedService(null)
        loadProducts()
      }
  },[deleteProductSuccess, error, product])

  useEffect(() => {
    loadProducts();
    loadSubClassAccount();
    loadCategories();
    loadServices()
  },[dispatch])

  useEffect(() =>{
    if (all_categories && all_categories.length > 0) {
      const categories_list = all_categories.map( category => {
        return { label: category.name, value: category.id }
      })
      setCategoriesOptions(categories_list)
    }
  },[all_categories]) 

  useEffect(() => {
    if (associatedService && associatedService.length > 0) {
        const service_list = associatedService.map( service => {
          return { label: service.title, value: service.value }
        })
        setServiceOption(service_list)
    }
},[associatedService])

  useEffect(() =>{
    if (subClassAccount && subClassAccount.length > 0) {
      const subClassAccount_list = subClassAccount.map( subclass => {
        return { label: subclass.name, value: subclass.id }
      })
      setSubClassAccountOption(subClassAccount_list)
    }
  },[subClassAccount])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (currentProduct && currentProduct.id) || '',
      name: (currentProduct && currentProduct.name) || '',
      description: (currentProduct && currentProduct.description) || '',
      purchasePrice: (currentProduct && currentProduct.purchasePrice) || '',
      sellingPrice: (currentProduct && currentProduct.sellingPrice) || '',
      productType: (currentProduct && currentProduct.productType) || '',
      associatedService: (currentProduct && currentProduct.associatedService) || '',
      minQuantity: (currentProduct && currentProduct.minQuantity) || '',
      categoryId: (currentProduct && currentProduct.categoryId) || '',
      subClassAccountId: (currentProduct && currentProduct.subClassAccountId) || '',
    },

    validationSchema: Yup.object({
      name: Yup.string().required(props.t("you_need_provide_name")),
    }),

    onSubmit: (values) => {

      if (isEdit) {
        const updateForm = {
            name: values.name,
            description: values.description,
            purchasePrice: selectedType && selectedType.value !== "Service" ? values.purchasePrice : null,
            sellingPrice: values.sellingPrice,
            productType: selectedType.value,
            minQuantity: values.minQuantity,
            associatedService: selectedService.value,
            categoryId: selectedCategories.value,
            subClassAccountId: selectedClassAccount && selectedClassAccount.value
        }
        const id = currentProduct.id

        dispatch(onUpdateProduct({ body: updateForm, id: id }))
      } else {
        const form = {
          name: values.name,
          description: values.description,
          purchasePrice: selectedType && selectedType.value !== "Service" ? values.purchasePrice : null,
          associatedService: selectedService && selectedService.value,
          sellingPrice: values.sellingPrice,
          productType: selectedType && selectedType.value,
          minQuantity: values.minQuantity,
          categoryId: selectedCategories && selectedCategories.value,
          subClassAccountId: selectedClassAccount && selectedClassAccount.value
        }

        dispatch(onCreateProduct({
          name: values.name,
          description: values.description,
          purchasePrice: selectedType && selectedType.value !== "Service" ? values.purchasePrice : null,
          associatedService: selectedService && selectedService.value,
          sellingPrice: values.sellingPrice,
          productType: selectedType && selectedType.value,
          minQuantity: values.minQuantity,
          categoryId: selectedCategories && selectedCategories.value,
          subClassAccountId: selectedClassAccount && selectedClassAccount.value
        }))
      }
      
    },

  })

  const handleOpen = () => {
    setIsEdit(false)
    toggle()
  }

  const handleSelectProduct = (arg) => {
    setCurrentProduct(arg)
    setselectedCategories({ label: arg.category.name, value: arg.category.id })
    setselectedClassAccount({ label: arg.classAccount.name, value: arg.classAccount.id })
    const service = serviceOption.find((row) => row.value == arg.associatedService)
    const type = typeOption.find((row) => row.value == arg.productType)
    setSelectedService(service)
    setSelectedType(type)
    setIsEdit(true);
    toggle();
  }

  function handleSelectCategory(selectedCategories) {
    setselectedCategories(selectedCategories)
  }

  function handleSelectClassAccount(selectedCategories) {
    setselectedClassAccount(selectedCategories)
  }

  function handleSelectService(selectedAServices) {
    setSelectedService(selectedAServices)
  }

  function handleSelectType(selectedAServices) {
    setSelectedType(selectedAServices)
  }

  const onClickDelete = (product) => {
      setCurrentProduct(product);
      setDeleteModal(true);
  }

  function handleDeleteProduct() {
      if (currentProduct) {
        dispatch(onDeleteProduct( currentProduct.id ))
        loadProducts()
        setDeleteModal(false)
        setCurrentProduct(null)
      }
  }

  const columns = useMemo(
    () => [
      
      {
        header: props.t("name"),
        accessorKey: "name",
        style: "cat",
        enableColumnFilter: false,
      },
      {
        header: props.t("Category"),
        accessorKey: "phone",
        style: "cat",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.category.name}</span>
        }
      },
      {
        header: props.t("Type"),
        accessorKey: "productType",
        style: "type",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.productType}</span>
        }
      },
      {
        header: props.t("sale_rate"),
        accessorKey: "sellingPrice",
        style: "sale",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.sellingPrice}</span>
        }
      },
      {
        header: props.t("purchase_rate"),
        accessorKey: "purchasePrice",
        style: "purchase",
        enableColumnFilter: false,
        cell: (cell) => {
          return <span>{cell.row.original.purchasePrice}</span>
        }
      },
      {
        header: props.t("action"),
        style: "action",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center">
                <ul className="list-inline hstack mb-0">
                  <li className="list-inline-item edit" title="Edit">
                    <Link
                      to="#"
                      className="text-primary d-inline-block edit-item-btn"
                      onClick={() => { const productData = cellProps.row.original; handleSelectProduct(productData); }}
                    >
                      <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                    </Link>
                  </li>
                  <li className="list-inline-item" title="Remove">
                    <Link
                      to="#"
                      className="text-danger d-inline-block remove-item-btn"
                      onClick={() => { const productData = cellProps.row.original; onClickDelete(productData); }}
                    >
                      <img src="../assets/images/delete.png" alt=""></img>
                    </Link>
                  </li>
                </ul>
            </div>
          );
        },
      },
    ],
    [handleSelectProduct]
  );

  return (
    <React.Fragment>
      {isLoading ?
          <GlobalLoader />
        :
        <>
          <DeleteModal show={deleteModal} onDeleteClick={handleDeleteProduct} onCloseClick={() =>setDeleteModal(false)} />
          <ImportProduct show={isImportCSV} onCloseClick={() => handleImportClose()} data={products} />
          <div className="patients-list-content">
              <div className="patients-list px-2 pb-5 ">
                  <div className="container-fluid ">

                      <div className="heading-parent-sec ">
                          <div className="heading-child-one-sec pt-4">
                              <h6>{props.t("item_record")}</h6>
                          </div>
                          <div className="heading-child-two-sec pt-4">
                            <div className='d-flex'>
                              <div className="create-new-patient-btn m-2 ps-2"
                                  data-bs-target="#exampleModalSec" onClick={() => setIsImportCSV(true)}>
                                  <div className="create-new-patient-btn-child-one">
                                      <i class="fa-solid fa-upload img-fluid text-white"></i>
                                  </div>
                                  <div className="create-new-patient-btn-child-two">
                                      <button className="btn" >{props.t("import_data")}</button>
                                  </div>
                              </div>
                              <div className="create-new-patient-btn my-2 ps-2"
                                  data-bs-target="#exampleModalSec" onClick={() =>{handleOpen()}}>
                                  <div className="create-new-patient-btn-child-one">
                                      <img src="../assets/images/new-patient/img-one.png" className="img-fluid" />
                                  </div>
                                  <div className="create-new-patient-btn-child-two">
                                      <button className="btn" >{props.t("add_item")}</button>
                                  </div>
                              </div>
                            </div>
                          </div>
                      </div>

                      <div className="card border-0 row p-2">
                              <div className="row">
                                    <TableContainerWarehouse
                                      columns={columns}
                                      data={((products && products.items) || [])}
                                      isGlobalFilter={true}
                                      customPageSize={10}
                                      divClass="card table-card table-warehouse table-responsive"
                                      tableClass="table"
                                      theadClass="thead-sec table-light"
                                      tdClass="td"
                                      tableSize={ products && products.size }
                                      tablePage={ products && products.page }
                                      tableTotal= { products && products.total_items }
                                      tableTotalPage= { products && products.total_page }
                                      onNextPage= {loadProducts}
                                      onSearch= {searchProduct}
                                      SearchPlaceholder={props.t("search_product")}
                                    />
                              </div>
                      </div>
                  </div>
              </div>
          </div>
          
          <Modal isOpen={modal || false} toggle={toggle} className="modal-sec"  tabIndex="-1" aria-labelledby="exampleModalLabel" style={{ backdropFilter: "blur(5px)" }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title mb-0">Item Details</h5>
                    <button type="button" className="btn-close" onClick={() => { toggle()}} ></button>
                </div>
                <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                }}>
                  <ModalBody className="modal-body">

                      <div className="row g-0 row-two-parent-sec">
                          {/* Left */}
                          <div className="col-xl-6 col-lg-6 col-md-6 col-6 col-child-one-sec">
                            {/* item_name */}
                              <div className="row row-two-child-one-sec">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-12 sale-rate">
                                  <p className="mb-0">{props.t("item_name")}<span className='text-danger'>*</span></p>
                                  <Input 
                                      name='name' 
                                      type="text" 
                                      id="name-field" 
                                      placeholder={props.t("name")}
                                      className="form-control" 
                                      validate={{
                                          required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.name || ""}
                                      invalid={
                                          validation.touched.name && validation.errors.name ? true : false
                                      }
                                  />
                                  {validation.touched.name && validation.errors.name ? (
                                      <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                  ) : null}
                                </div>
                              </div>
                              {/* purchase rate */}
                              <div className="row row-two-child-one-sec">
                                  <div className="col-xl-12 col-lg-12 col-md-12 col-12 sale-rate">
                                      <p className="mb-0">{props.t("purchase_rate")} {selectedType && selectedType.value !== "Service" ? <span className='text-danger'>*</span> : '' }</p>
                                      <Input
                                          name='purchasePrice'
                                          type="number"
                                          id="purchasePrice-field"
                                          placeholder=""
                                          className="form-control"
                                          validate={{
                                              required: { value: true },
                                          }}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.purchasePrice || ""}
                                          disabled= {selectedType && selectedType.value === "Service" ? true : false }
                                          invalid={
                                              validation.touched.purchasePrice && validation.errors.purchasePrice ? true : false
                                          }
                                      />
                                      {validation.touched.purchasePrice && validation.errors.purchasePrice ? (
                                          <FormFeedback type="invalid">{validation.errors.purchasePrice}</FormFeedback>
                                      ) : null}
                                  </div>
                              </div>
                              {/* sale rate */}
                              <div className="row row-two-child-one-sec">
                                  <div className="col-xl-12 col-lg-12 col-md-12 col-12 sale-rate">
                                      <p className="mb-0">{props.t("sale_rate")}</p>
                                      <Input 
                                          name='sellingPrice' 
                                          type="number" 
                                          id="sellingPrice-field" 
                                          placeholder=""  
                                          className="form-control" 
                                          validate={{
                                              required: { value: true },
                                          }}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.sellingPrice || ""}
                                          invalid={
                                              validation.touched.sellingPrice && validation.errors.sellingPrice ? true : false
                                          }
                                      />
                                      {validation.touched.sellingPrice && validation.errors.sellingPrice ? (
                                          <FormFeedback type="invalid">{validation.errors.sellingPrice}</FormFeedback>
                                      ) : null}
                                  </div>
                              </div>
                              {/* Threshold */}
                              <div className="row row-two-child-one-sec">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-child-sec">
                                    <p className="mb-0">
                                      {props.t("threshold")} 
                                      {/* {selectedType && selectedType.value !== "Service" ? <span className='text-danger'>*</span> : '' } */}
                                    </p>
                                    <Input
                                        name='minQuantity'
                                        type="number"
                                        id="minQuantity-field"
                                        placeholder=""
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.minQuantity || ""}
                                        disabled= {selectedType && selectedType.value === "Service" ? true : false }
                                        invalid={
                                            validation.touched.minQuantity && validation.errors.minQuantity ? true : false
                                        }
                                    />
                                    {validation.touched.minQuantity && validation.errors.minQuantity ? (
                                        <FormFeedback type="invalid">{validation.errors.minQuantity}</FormFeedback>
                                    ) : null}
                                </div>
                              </div>
                          </div>
                          {/* Right */}
                          <div className="col-xl-6 col-lg-6 col-md-6 col-6 col-child-two-sec">
                              {/* type */}
                              <div className="row row-two-child-one-sec">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-12 sale-rate">
                                    <p className="mb-0">{props.t("type")}<span className='text-danger'>*</span></p>
                                      <Select
                                            name='productType'
                                            className="basic-single"
                                            classNamePrefix="select"
                                            id='productType-field'
                                            value={selectedType}
                                            isMulti={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={true}
                                            onBlur={validation.handleBlur}
                                            onChange={(e) => {
                                              handleSelectType(e);
                                            }}
                                            options={typeOption}
                                            closeMenuOnSelect={true}
                                      />
                                    {validation.touched.productType && validation.errors.productType ? (
                                        <FormFeedback type="invalid">{validation.errors.productType}</FormFeedback>
                                    ) : null}
                                </div>
                              </div>
                              {/* Associated service */}
                              <div className="row row-two-child-one-sec">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-12 sale-rate">
                                    <p className="mb-0">{props.t("associated_service")}</p>
                                    <Select
                                      name='parentId'
                                      className="basic-single"
                                      classNamePrefix="select"
                                      id='parentId-field'
                                      value={selectedService}
                                      isMulti={false}
                                      isClearable={false}
                                      isRtl={false}
                                      isSearchable={true}
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleSelectService(e);
                                      }}
                                      options={serviceOption}
                                      closeMenuOnSelect={true}
                                    />
                                </div>
                              </div>
                              {/* Select category */}
                              <div className="row row-two-child-one-sec">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-child-sec">
                                    <p className="mb-0">Select Category</p>
                                    <Select
                                      name='parentId'
                                      id='parentId-field'
                                      className="dev_kevin"
                                      value={selectedCategories}
                                      isMulti={false}
                                      isClearable={false}
                                      autoComplete="off" 
                                      // className='p-0'
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleSelectCategory(e);
                                      }}
                                      options={categoriesOptions}
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                    />
                                </div>
                              </div>
                              {/* income head */}
                              <div className="row row-two-child-one-sec">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-child-sec">
                                    <p className="mb-0">Income Head</p>
                                    <Select
                                      name='parentId'
                                      id='parentId-field'
                                      value={selectedClassAccount}
                                      isMulti={false}
                                      isClearable={false}
                                      autoComplete="off" 
                                      className='p-0'
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleSelectClassAccount(e);
                                      }}
                                      options={subClassAccountOption}
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                    />
                                </div>
                              </div>
                          </div>
                      </div>
                      {/* Bottom */}
                      <div className="row row-three-parent-sec">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-child-sec">
                              <p className="mb-0">{props.t("description")}</p>
                              <Input 
                                  name='description' 
                                  type="text" 
                                  id="description-field" 
                                  placeholder=""  
                                  className="form-control" 
                                  validate={{
                                      required: { value: true },
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.description || ""}
                                  invalid={
                                      validation.touched.description && validation.errors.description ? true : false
                                  }
                              />
                              {validation.touched.description && validation.errors.description ? (
                                  <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                          </div>
                      </div>
                  </ModalBody>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => { toggle()}}>{props.t("cancel")}</button>
                    <button type="submit" className="btn btn-success">{ isEdit ? props.t("update") : props.t("save")}</button>
                </div>
                </Form>
            </div>
          </Modal>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(ItemRecord))
