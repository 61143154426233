import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../components/Common/withRouter'
import moment from 'moment'
import * as Yup from "yup"
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import {
    getPatientAllVitalsTable as onGetPatientAllVitals,
    addNewPatientVitals as onAddNewPatientVitals,
    updatePatientVitals as onUpdatePatientVitals,
    getPatient as OnGetPatientBytid
} from '../../../../slice/thunks'
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate } from "react-router-dom"
import GlobalLoader from '../../../../components/Common/GlobalLoader'
import TableContainer from '../../../../components/Common/TableContainer'
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'



const MainPatientVitals = (props) => {
    const { id, record } = useParams()
    const dispatch = useDispatch()
    const history = useNavigate()
    const permission = JSON.parse(localStorage.getItem("perm"))
    const selectLayoutState = (state) => state.Outputs;
    const OutputsProperties = createSelector(
        selectLayoutState,
        (outputs) =>({
            patient: outputs.patient,
            patientVitalsTable: outputs.patientVitalsTable,
            patientVitals: outputs.patientVitals,
            patient_vital_success: outputs.patient_vital_success,
            error: outputs.error
        })
    )
    const { patient, patientVitalsTable, patientVitals, patient_vital_success, error } = useSelector(OutputsProperties)
    const [isLoading, setIsLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [currentPatientVitals, setCurrentPatientVitals] = useState(null)

    const loadPatientvitals = useCallback((pageNumber, pageSize) => {
        if (pageNumber & pageSize) {
         dispatch(onGetPatientAllVitals({page: pageNumber, size: pageSize}))
        } else {
          dispatch(onGetPatientAllVitals({id: id}))
        }
    })

    const toggle = useCallback(() => {
        if (modal) {
          setModal(false);
          validation.resetForm();
          setCurrentPatientVitals(null);
        } else {
          setModal(true);
        }
    }, [modal])

    const loadPatientDetails = useCallback(() => {
        dispatch(OnGetPatientBytid({id: id}));
    })

    useEffect(() => {
        loadPatientDetails();
    },[dispatch])


    useEffect(() => {
        loadPatientvitals()
    },[dispatch])

    useEffect(() => {
        if (patientVitalsTable && patientVitalsTable.page ) {
            setIsLoading(false)
        }
    },[patientVitalsTable])

    useEffect(() => {
        if (patient_vital_success && !error) {
          setIsEdit(false)
          validation.resetForm()
          setModal(false)
          setCurrentPatientVitals(null)
          loadPatientvitals()
        }
      },[patient_vital_success, error, patientVitals, patient])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: (currentPatientVitals && currentPatientVitals.id) || null,
            patientRecordId: (currentPatientVitals && currentPatientVitals.patientRecordId) || '',
            height: (currentPatientVitals && currentPatientVitals.height) || '',
            weight: (currentPatientVitals && currentPatientVitals.weight) || '',
            spo: (currentPatientVitals && currentPatientVitals.spo) || '',
            bp: (currentPatientVitals && currentPatientVitals.bp) || '',
            temperature: (currentPatientVitals && currentPatientVitals.temperature) || '',
        },
        validationSchema: Yup.object({
            // height: Yup.string().matches(/^[0-9.]+$/, { message: props.t("provide_valid_height") }).required(props.t("provide_height")),
            // weight: Yup.string().required(props.t("provide_weight")),
            // spo: Yup.string().matches(/^[0-9.]+$/, { message: props.t("provide_valid_spo") }).required(props.t("provide_spo")),
            // bp: Yup.string().matches(/^[0-9.]+$/, { message: props.t("provide_valid_bp") }).required(props.t("provide_bp")),
            // temperature: Yup.string().matches(/^[0-9.]+$/, { message: props.t("provide_valid_temperature") }).required(props.t("provide_temperature")),
        }),
        onSubmit: (values) => {

            if (isEdit) {
                const newVitalForm = {
                    height: values.height,
                    weight: values.weight,
                    spo: values.spo,
                    bp: values.bp,
                    temperature: values.temperature
                }
                dispatch(onUpdatePatientVitals({ id: currentPatientVitals.id, data: newVitalForm }))
            } else {
                const newVitalForm = {
                    height: values.height,
                    weight: values.weight,
                    spo: values.spo,
                    bp: values.bp,
                    temperature: values.temperature,
                    patientRecordId: patient.patientRecord.id
                }
                dispatch(onAddNewPatientVitals(newVitalForm))
                setIsEdit(false)
            }
        },
    })

    const handleOpen = () => {
        setIsEdit(false)
        toggle()
    }

    const handleSelectVital = (arg) => {
        setCurrentPatientVitals(arg);
        setIsEdit(true)
        toggle();
    }

    const columns = useMemo(
        () => [
          {
            header: props.t("no"),
            accessorKey: "index",
            style: "button",
            enableColumnFilter: false,
            cell: (cellProps) => {
                return <span>{cellProps.row.index + 1}</span>;
            },
          },
          {
              header: props.t("date"),
              enableColumnFilter: false,
              cell: (cell) => {
                  return <span>
                      {moment(cell.row.original.createdAt).format('DD / MM/ YYYY HH:mm')}
                  </span>;
              },
          },
          {
            header: props.t("height"),
            accessorKey: "height",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{ cell.row.original.height }</span>
            }
          },
          {
            header: props.t("weight"),
            accessorKey: "weight",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{ cell.row.original.weight }</span>
            }
          },
          {
            header: props.t("bp"),
            accessorKey: "bp",
            style: "text-start",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span>{ cell.row.original.bp }</span>
            }
          },
          {
            header: props.t("spo"),
            accessorKey: "spo",
            style: "text-end",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span className='d-flex justify-content-end'>{ cell.row.original.spo }</span>
            }
          },
          {
            header: props.t("temperature"),
            accessorKey: "temperature",
            style: "text-center",
            enableColumnFilter: false,
            cell: (cell) => {
                return <span className='d-flex justify-content-center'>{ cell.row.original.temperature }</span>
            }
          },
          {
            header: props.t("action"),
            style: "text-center",
            cell: (cellProps) => {
              return (
                <td className="action d-flex justify-content-center px-2" onClick={() => { const vitalsData =  cellProps.row.original; handleSelectVital(vitalsData);}}>
                    <Link to="#" >
                        <i className="fa-solid fa-pencil"></i>
                    </Link>
                </td>
              );
            },
          },
        ],
        [props]
    );

  return (
    <>
    {isLoading ?
        <GlobalLoader/>
        :
        <>
            <div className='patients-list-content'>
                <div className="patients-list py-4 px-2  ">
                    <p></p>
                    <div className="patients-list-content p-0 m-0" id="table1">
                        <div className="container">
                            <div className="row d-flex align-items-center justify-content-between">
                                <div className="col-lg-4">
                                    <Link to="/patients" >
                                        <div className="heading-child-one-sec d-flex align-items-center">
                                                <i className="fa-solid fa-chevron-left"></i>
                                            <h6 className='mb-0'>{props.t("patient_vitals_history")}</h6>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-3 text-align-center">
                                    {(patient && patient.patientRecord) &&  permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("ADD_PATIENTS")) ?
                                        <Link to="#" onClick={() => {handleOpen()}}>
                                            <button
                                                className="btn btn-one"
                                                type="button">
                                                <i className="fas fa-user-md me-1"></i>{props.t("take_vitals")}
                                            </button>
                                        </Link>
                                        :<></>
                                    }
                                </div>
                            </div>
                            <div className="row ">
                                <div className="pt-1">
                                    <div className='justify-content-center'>
                                    <TableContainer
                                        columns={columns}
                                        data={((patientVitalsTable && patientVitalsTable.items) || [])}
                                        isGlobalFilter={false}
                                        customPageSize={10}
                                        divClass="table-card-ward"
                                        tableClass="align-middle"
                                        theadClass="thead-light table-light"
                                        tableSize={ patientVitalsTable && patientVitalsTable.size }
                                        tablePage={ patientVitalsTable && patientVitalsTable.page }
                                        tableTotal= { patientVitalsTable && patientVitalsTable.total_items }
                                        tableTotalPage= { patientVitalsTable && patientVitalsTable.total_page }
                                        onNextPage= {loadPatientvitals}
                                        onSearch= {loadPatientvitals}
                                        SearchPlaceholder={props.t("search_patient")}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal id='showModal' size='md' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>

                <ModalHeader className="bg-light p-3" toggle={toggle}>
                    {!!isEdit ? props.t("update_vitals") : props.t("take_vitals")}
                </ModalHeader>
                <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <ModalBody>
                        <Row>
                            <Col xl={4} sm={4}>
                                <div className="mb-3">
                                    <Label
                                        htmlFor="height-field"
                                        className="form-label"
                                    >
                                        {props.t("height")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                        name="height"
                                        id="height-field"
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder={props.t("height")}
                                        type="text"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.height || ""}
                                        invalid={
                                            validation.touched.height && validation.errors.height ? true : false
                                        }
                                    />
                                    {validation.touched.height && validation.errors.height ? (
                                        <FormFeedback type="invalid">{validation.errors.height}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <Col xl={4} sm={4}>
                                <div className="mb-3">
                                    <Label
                                        htmlFor="weight-field"
                                        className="form-label"
                                    >
                                        {props.t("weight")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                        name="weight"
                                        id="weight-field"
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder={props.t("weight")}
                                        type="text"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.weight || ""}
                                        invalid={
                                            validation.touched.weight && validation.errors.weight ? true : false
                                        }
                                    />
                                    {validation.touched.weight && validation.errors.weight ? (
                                        <FormFeedback type="invalid">{validation.errors.weight}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <Col xl={4} sm={4}>
                                <div className="mb-3">
                                    <Label
                                        htmlFor="spo-field"
                                        className="form-label"
                                    >
                                        {props.t("spo")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                        name="spo"
                                        id="spo-field"
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder={props.t("spo")}
                                        type="text"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.spo || ""}
                                        invalid={
                                            validation.touched.spo && validation.errors.spo ? true : false
                                        }
                                    />
                                    {validation.touched.spo && validation.errors.spo ? (
                                        <FormFeedback type="invalid">{validation.errors.spo}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <Col xl={4} sm={4}>
                                <div className="mb-3">
                                    <Label
                                        htmlFor="bp-field"
                                        className="form-label"
                                    >
                                        {props.t("bp")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                        name="bp"
                                        id="bp-field"
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder={props.t("bp")}
                                        type="text"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.bp || ""}
                                        invalid={
                                            validation.touched.bp && validation.errors.bp ? true : false
                                        }
                                    />
                                    {validation.touched.bp && validation.errors.bp ? (
                                        <FormFeedback type="invalid">{validation.errors.bp}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <Col xl={4} sm={4}>
                                <div className="mb-3">
                                    <Label
                                        htmlFor="temperature-field"
                                        className="form-label"
                                    >
                                        {props.t("temperature")}<span className='text-danger'>*</span>
                                    </Label>
                                    <Input
                                        name="temperature"
                                        id="temperature-field"
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder={props.t("temperature")}
                                        type="text"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.temperature || ""}
                                        invalid={
                                            validation.touched.temperature && validation.errors.temperature ? true : false
                                        }
                                    />
                                    {validation.touched.temperature && validation.errors.temperature ? (
                                        <FormFeedback type="invalid">{validation.errors.temperature}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <div className='p-3'>
                        <Row className="align-items-center">
                        <div className="col-sm">
                            <span className='text-danger align-middle'>* : {props.t("required_fields")}</span>
                        </div>
                        <div className="col-sm-auto">
                            <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setCurrentPatientVitals(null); setIsEdit(false) }}> {props.t("close")} </button>
                            <button type="submit" className="btn btn-success"> {!!isEdit ? props.t("update") : props.t("save")} </button>
                            </div>
                        </div>
                        </Row>
                    </div>
                </Form>
            </Modal>
        </>
    }
    </>
  )
}

export default withRouter(withTranslation()(MainPatientVitals))
