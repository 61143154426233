import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Col, Input, Label, Row } from 'reactstrap'

import withRouter from '../../Common/withRouter'

const CashDeskRow = (props) => {

    const [amount, setAmount] = useState(props.row.amount)

    const handleBlur = (e, setValue) => {
        if (e.target.value === '' || e.target.value <= 1) {
            setValue(0);
        }
    };

    useEffect(() => {
        let object = {
            label: props.row.label,
            source: props.row.source,
            amount: props.sourcesAndAmounts ? props.sourcesAndAmounts[props.row.source] : amount,
        }
        props.updateRow(object)
    }, [amount]);

  return (
    <>
        <Row className='m-2'>
            <Col xl={4} sm={6} style={{ alignContent: "center" }}>
                <Label> {props.row.label}<span className='text-danger'>*</span>: </Label>
            </Col>
            <Col xl={8} sm={6}>
                <Input
                    name={props.row.source.toLowerCase()}
                    id={props.row.source}
                    className="form-control" type="number"
                    placeholder={props.t("enter_amount")}
                    value={props.sourcesAndAmounts ? props.sourcesAndAmounts[props.row.source] :  props.row.amount}
                    onChange={(e) => setAmount(Number(props.sourcesAndAmounts ? props.sourcesAndAmounts[props.row.source] : e.target.value))}
                    onBlur={(e) => handleBlur(e, setAmount)}
                />
            </Col>
        </Row>
    </>
  )
}

export default withRouter(withTranslation()(CashDeskRow))
