import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../components/Common/withRouter'
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import moment from 'moment'

import {
    getConsultationList as onGetConsultationList,
} from "../../../../slice/thunks"
import TableContainer from '../../../../components/Common/TableContainer'

const ConsultationList = (props) => {
    const dispatch = useDispatch()
    const outputLayoutState = (state) => state.Outputs
    const OutputsProperties = createSelector(
        outputLayoutState,
        (output) =>({
            consultationList: output.consultationList,
            error: output.error
        })
    )
    const { consultationList, error } = useSelector(OutputsProperties)

    const loadConsultationList = useCallback((pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
            dispatch(onGetConsultationList({page: pageNumber, size: pageSize}))
        } else {
            dispatch(onGetConsultationList({page: 1, size: 10}))
        }
    })

    useEffect(() => {
        if (props.isActive) {
            loadConsultationList()
        }
    },[dispatch, props.isActive])

    const columns = useMemo(
        () => [
            {
                header: props.t("patient_id"),
                accessorKey: "patientRef",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span> {(cell.row.original.patient !== null )? "#"+cell.row.original.patient.patientRef : ''} </span>
                }
            },
            {
                header: props.t("patients_name"),
                accessorKey: "firstName",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span> {(cell.row.original.patient !== null )? cell.row.original.patient.firstName+" "+cell.row.original.patient.lastName : ''} </span>
                }
            },
            {
                header: props.t("doctor_consultant"),
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span> {(cell.row.original.doctor !== null )? "Dr."+cell.row.original.doctor.fullName : ''} </span>
                }
            },
            {
                header: props.t("date"),
                accessorKey: "consultedAt",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return (
                        <span>
                            { moment(cell.row.original.consultedAt).format('DD/MM/YYYY') }
                        </span>
                        )
                }
            },
            {
                header: props.t("status"),
                accessorKey: "status",
                style: "button",
                enableColumnFilter: false,
                cell: (cell) => {
                    return (
                        // <div className="d-flex justify-content-end mr-5">
                            <span className='text-bold'>
                                
                                    {(cell.row.original.status == false )? props.t("not_consulted") : props.t("consulted")}
                                
                            </span>
                        // </div>
                    )
                }
            },
        ],
        []
      );
 
  return (
    <div className="send-patient-to-doctor-sec">
        <div className="container-fluid">
            <div className="send-patient-to-doctor-main-sec pb-3">
                <TableContainer
                    columns={columns}
                    data={((consultationList && consultationList.items) || [])}
                    isGlobalFilter={false}
                    customPageSize={10}
                    divClass="table-card-ward"
                    tableClass="align-middle"
                    theadClass="thead-light table-light"
                    tableSize={ consultationList && consultationList.size }
                    tablePage={ consultationList && consultationList.page }
                    tableTotal= { consultationList && consultationList.total_items }
                    tableTotalPage= { consultationList && consultationList.total_page }
                    onNextPage= {loadConsultationList}
                    onSearch= {loadConsultationList}
                    SearchPlaceholder={props.t("search")}
                />
            </div>
        </div>
    </div>
  )
}

export default withRouter(withTranslation()(ConsultationList))
