import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from "react-router-dom"
import { createSelector } from "reselect"
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import moment from 'moment'
import { Row, Col,Label, Input } from 'reactstrap'
import {
    getRequisitions as onGetRequisitions,
    getRequisitionStatus as onGetRequisitionStatus,
    approveRequisition as onApproveRequisition,
    rejectRequisition as onRejectRequisition,
    cancelRequisition as onCancelRequisition,
} from "../../../slice/thunks"
import withRouter from '../../../components/Common/withRouter'
import DeleteModal from "../../../components/Global/DeleteModal"
import GlobalLoader from "../../../components/Common/GlobalLoader"
import TableContainerWarehouse from "../../../components/Common/TableContainerWarehouse"
import ApproveModal from "../../../components/Global/ApproveModal"
import CancelModal from "../../../components/Global/CancelModal"


const Requisitions = (props) => {
    const dispatch = useDispatch()
    const animatedComponents = makeAnimated()
    const selectLayoutState = (state) => state.Settings;
    const SettingsProperties = createSelector(
      selectLayoutState,
      (setting) =>({
        requisitions: setting.requisitions,
        requisitionStatus: setting.requisitionStatus,
        approveRequisitionSuccess: setting.approveRequisitionSuccess,
        rejectRequisitionSuccess: setting.rejectRequisitionSuccess,
        cancelRequisitionSuccess: setting.cancelRequisitionSuccess,
        error: setting.error
      })
    )
    const {
        requisitions,
        requisitionStatus,
        approveRequisitionSuccess,
        rejectRequisitionSuccess,
        cancelRequisitionSuccess,
        error
    } = useSelector(SettingsProperties)

    const [isLoading, setIsLoading] = useState(true)
    const [currentRequisition, setCurrentRequisition] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [statusOption, setStatusOption] = useState([])
    const [searchInput, setSearchInput] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [approveModal, setApproveModal] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)

    useEffect(() => {
        loadRequesitionStatus()
        loadRequesitions()
    },[dispatch])

    useEffect(() => {
        if (requisitions && requisitions.page >0 ) {
            setIsLoading(false)
        }
    },[requisitions])

    useEffect(() => {
        const status_lists = [ 
            { label: 'All', value: ''}
        ]
        if (requisitionStatus && requisitionStatus.length > 0) {
            const data_lists = requisitionStatus.map(row => ({
                label: row.title,
                value: row.value
            }));
            setStatusOption([...status_lists, ...data_lists]);
        }
    },[requisitionStatus])

    useEffect(() => {
        if (selectedStatus && selectedStatus.label) {
            if (searchInput && (searchInput.length > 2) ) {
                loadRequesitions(1, 10, searchInput)
            } else {
                loadRequesitions(1, 10)
            }
        }
    },[selectedStatus])

    useEffect(() => {
        if (searchInput) {
            if (searchInput.length >= 2) {
                loadRequesitions(1, 10, searchInput)
            }else if (searchInput != null) {
                loadRequesitions(1, 10)
            }
        }
    },[searchInput])

    useEffect(() => {
        if (approveRequisitionSuccess && !error) {
            setApproveModal(false)
            loadRequesitions()
        }
    },[approveRequisitionSuccess])

    useEffect(() => {
        if (rejectRequisitionSuccess && !error) {
            setDeleteModal(false)
            loadRequesitions()
        }
    },[rejectRequisitionSuccess])

    useEffect(() => {
        if (cancelRequisitionSuccess && !error) {
            setCancelModal(false)
            loadRequesitions()
        }
    },[cancelRequisitionSuccess])

    const loadRequesitionStatus = useCallback(() => {
        dispatch(onGetRequisitionStatus())
    })

    const loadRequesitions = useCallback((pageNumber, pageSize, term) => {
        if (pageNumber && pageSize) {
            if (term) {
                dispatch(onGetRequisitions({page: pageNumber, size: pageSize, status: selectedStatus ? selectedStatus.value : null, term: searchInput}))
            }
            else {
                dispatch(onGetRequisitions({page: pageNumber, size: pageSize, status: selectedStatus ? selectedStatus.value : null}))
            }
        } else {
            if (term) {
                dispatch(onGetRequisitions({page: 1, size: 10, status: selectedStatus ? selectedStatus.value : null, term: searchInput}))
            }
            else {
                dispatch(onGetRequisitions({page: 1, size: 10, status: selectedStatus ? selectedStatus.value : null }))
            }
        }
    })

    const searchRequesitions = useCallback((term, pageNumber, pageSize) => {
        if (pageNumber && pageSize) {
          dispatch(onGetRequisitions({ term: term, status: selectedStatus.value, page: pageNumber, size: pageSize }))
        } else {
          dispatch(onGetRequisitions({ term: term, status: selectedStatus.value, page: 1, size: 10 }))
        }
    })


    function handleDeleteRequisition() {
        if (currentRequisition) {
          dispatch(onRejectRequisition(currentRequisition.id))
          loadRequesitions()
          setDeleteModal(false)
        }
    }

    function handleApproveRequisition() {
        if (currentRequisition) {
          dispatch(onApproveRequisition(currentRequisition.id))
          loadRequesitions()
          setDeleteModal(false)
        }
    }

    function handleCancelRequisition() {
        if (currentRequisition) {
          dispatch(onCancelRequisition(currentRequisition.id))
          loadRequesitions()
          setCancelModal(false)
        }
    }

    const onClickDelete = (req) => {
        setCurrentRequisition(req);
        setDeleteModal(true);
    }

    const onClickCancel = (req) => {
        setCurrentRequisition(req);
        setCancelModal(true);
    }

    const onClickApprove = (req) => {
        setCurrentRequisition(req);
        setApproveModal(true);
    }

    function handleSearch () {
        loadRequesitions(1, 10, searchInput)
    }

    const columns = useMemo(
        () => [
            {
                header: props.t("no"),
                style: "cat",
                enableColumnFilter: false,
                cell: (cellProps) => {
                  return <span>
                      {requisitions.page == 1 ? (cellProps.row.index + 1) : (((requisitions.page-1)*10)+cellProps.row.index + 1) }
                  </span>;
                },
            },
            {
                header: props.t("ref_no"),
                accessorKey: "requisitionRef",
                style: "cat",
                enableColumnFilter: false,
            },
            {
                header: props.t("date"),
                accessorKey: "createdAt",
                style: "cat",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span>
                        {moment(cell.row.original.createdAt).format('DD / MM/ YYYY HH:mm')}
                    </span>;
                },
            },
            {
                header: props.t("subject"),
                accessorKey: "subject",
                style: "cat",
                enableColumnFilter: false,
                cell: (cell) => {
                    const truncateText = (text, maxLength) => {
                        if (text.length > maxLength) {
                            return text.substring(0, maxLength) + '...';
                        }
                        return text;
                    };
                
                    return <span>{truncateText(cell.row.original.subject, 20)}</span>;
                }
            },
            {
                header: props.t("beneficiary"),
                accessorKey: "beneficiary",
                style: "cat",
                enableColumnFilter: false,
            },
            {
                header: props.t("amount"),
                accessorKey: "amount",
                style: "cat",
                enableColumnFilter: false,
            },
            {
                header: props.t("paid_by"),
                accessorKey: "paidBy",
                style: "cat",
                enableColumnFilter: false,
            },
            {
                header: props.t("action"),
                style: "name",
                cell: (cellProps) => {
                return (
                    <div className="d-flex justify-content-start">
                       <ul className="list-inline hstack mb-0">
                            <li className="list-inline-item edit" title={props.t("view")}>
                                <>
                                    <Link
                                        to={`/accounting/requisitions/create/view/${cellProps.row.original.id}`}
                                        className="btn save-del btn-light " style={{fontSize: "11px", border: "1px solid #808080" }}
                                    >
                                        {props.t("view") }
                                    </Link>
                                </>
                            </li>
                            {cellProps.row.original.status != 'Approved' &&  cellProps.row.original.status != 'Paid'?
                                <li className="list-inline-item edit" title={props.t("edit")}>
                                    <>
                                        <Link
                                            to={`/accounting/requisitions/create/edit/${cellProps.row.original.id}`}
                                            className="btn save-del btn-light " style={{fontSize: "11px", border: "1px solid #808080" }}
                                        >
                                            {props.t("edit") }
                                        </Link>
                                    </>
                                </li>
                                :
                                <></>
                            }
                            {cellProps.row.original.approvedBy != null ?
                                <></>
                                :
                                <li className="list-inline-item edit" title={props.t("approve")}>
                                    <>
                                        <Link 
                                            to="#" 
                                            className="btn save-del btn-primary " style={{fontSize: "11px" }}
                                            onClick={() => { const rowData = cellProps.row.original; onClickApprove(rowData); }}
                                        >
                                            {props.t("approve")}
                                        </Link>
                                    </>
                                </li>
                            }
                            {cellProps.row.original.status != 'Approved' ?
                                <></>
                                :
                                <li className="list-inline-item edit" title={props.t("cancel")}>
                                    <>
                                        <Link
                                            to="#"
                                            className="btn save-del btn-warning" style={{ fontSize: "11px" }}
                                            onClick={() => { const rowData = cellProps.row.original; onClickCancel(rowData); }}
                                        >
                                            {props.t("cancel") }
                                        </Link>
                                    </>
                                </li>
                            }
                            {cellProps.row.original.status != 'Approved' &&  cellProps.row.original.status != 'Paid'?
                                <li className="list-inline-item edit" title={props.t("reject")}>
                                    <>
                                        <Link
                                            to="#"
                                            className="btn save-del btn-danger " style={{ fontSize: "11px" }}
                                            onClick={() => { const rowData = cellProps.row.original; onClickDelete(rowData); }}
                                        >
                                            {props.t("reject") }
                                        </Link>
                                    </>
                                </li>
                                :<></>
                            }
                        </ul>
                    </div>
                );
                },
            },
        ],
        [requisitions ]
    );


  return (
    <React.Fragment>
        {isLoading ?
            <GlobalLoader />
            :
            <>
                <DeleteModal show={deleteModal} onDeleteClick={handleDeleteRequisition} onCloseClick={() =>setDeleteModal(false)} />
                <ApproveModal show={approveModal} onConfirmClick={handleApproveRequisition} onCloseClick={() =>setApproveModal(false)} />
                <CancelModal show={cancelModal} onCancelClick={handleCancelRequisition} onCloseClick={() =>setCancelModal(false)} />
                
                <div className="patients-list-content">
                    <div className="patients-list px-2 pb-5 ">
                        <div className="container-fluid ">
                            <div className="heading-parent-sec ">
                                <div className="heading-child-one-sec pt-4">
                                    <h6>{props.t("list_of_requisitions")}</h6>
                                </div>
                                <div className="heading-child-two-sec pt-4">
                                    <div className="create-new-patient-btn my-2 ps-2">
                                        <div className="create-new-patient-btn-child-one">
                                            <img src="../assets/images/new-patient/img-one.png" className="img-fluid" />
                                        </div>
                                        <div className="create-new-patient-btn-child-two">
                                            <Link to="/accounting/requisitions/create" className="btn" >{props.t("create_requisition")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <Row>
                                <Col xl={4} sm={4}>
                                    <div className='mb-2'>
                                        <Label htmlFor="status-field" className="form-label">{props.t("status")}</Label><br/>
                                        <Select
                                            name='status'
                                            id='status-field'
                                            value={selectedStatus}
                                            isMulti={false}
                                            isClearable={true}
                                            onChange={(e) => {
                                                setSelectedStatus(e);
                                                if (!e) {
                                                    loadRequesitions()
                                                }
                                            }}
                                            options={statusOption}
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                        />
                                    </div>
                                </Col>
                                <Col xl={4} sm={4}>
                                    <div className='mb-2'>
                                        <Label htmlFor="status-field" className="form-label">{props.t("search_requisition")}</Label><br/>
                                        <div className="d-flex align-items-center">
                                            <div className="search-box me-2 mb-1 col-10">
                                                <Input
                                                    className="custom-border form-control"
                                                    value={searchInput || ''}
                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                />
                                            </div>
                                            <button type="button" onClick={() => handleSearch()} className="btn btn-primary">
                                                <i className="fa fa-search p-0"></i>
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="card border-0 row p-2">
                                    <div className="row">
                                            <TableContainerWarehouse
                                            columns={columns}
                                            data={((requisitions && requisitions.items) || [])}
                                            isGlobalFilter={false}
                                            customPageSize={10}
                                            divClass="card table-card table-warehouse table-responsive"
                                            tableClass="table"
                                            theadClass="thead-sec table-light"
                                            tdClass="td"
                                            tableSize={ requisitions && requisitions.size }
                                            tablePage={ requisitions && requisitions.page }
                                            tableTotal= { requisitions && requisitions.total_items }
                                            tableTotalPage= { requisitions && requisitions.total_page }
                                            onNextPage= {loadRequesitions}
                                            onSearch= {searchRequesitions}
                                            SearchPlaceholder={props.t("search_requisition")}
                                            />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Requisitions))
