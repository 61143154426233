import React, { useCallback, useEffect, useMemo, useState } from 'react'
import withRouter from '../../../components/Common/withRouter'
import { withTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import * as Yup from "yup"
import { useFormik } from 'formik'
import { Link } from "react-router-dom"
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader } from 'reactstrap'
import { createSelector } from "reselect"
import {
  getLabSamples as onGetLabSamples,
  createLabTestSample as onCreateLabTestSample,
  updateLabTestSample as onUpdateLabTestSample,
  deleteLabTestSample as onDeleteLabTestSample,
} from '../../../slice/thunks'
import GlobalLoader from '../../../components/Common/GlobalLoader'
import TableContainer from '../../../components/Common/TableContainer'
import DeleteModal from '../../../components/Global/DeleteModal'
import SinpleActionButton from '../../../components/Global/SinpleActionButton'

const Samples = (props) => {
  const dispatch = useDispatch()
  const selectLayoutState = (state) => state.Laboratory;
  const LabProperties = createSelector(
    selectLayoutState,
    (outputs) =>({
      labSamples: outputs.labSamples,
      labTestSample: outputs.labTestSample,
      createLabTestSampleSuccess: outputs.createLabTestSampleSuccess,
      updateLabTestSampleSuccess: outputs.updateLabTestSampleSuccess,
      deleteLabTestSampleSuccess: outputs.deleteLabTestSampleSuccess,
      error: outputs.error
    })
  )
  const { labSamples, labTestSample, createLabTestSampleSuccess, updateLabTestSampleSuccess, deleteLabTestSampleSuccess, error } = useSelector(LabProperties)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentRemark, setCurrentRemark] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const permission = JSON.parse(localStorage.getItem("perm"))
  const role = JSON.parse(localStorage.getItem("role"));


  const loadRemarks = useCallback((pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetLabSamples({page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetLabSamples())
    }
  })

  const searchRemark = useCallback((term, pageNumber, pageSize) => {
    if (pageNumber && pageSize) {
      dispatch(onGetLabSamples({term: term, page: pageNumber, size: pageSize}))
    } else {
      dispatch(onGetLabSamples({term: term, page: 1, size: 10}))
    }
  },[])

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      validation.resetForm();
      setCurrentRemark(null);
    } else {
      setModal(true);
    }
  }, [modal])


  useEffect(() => {
    loadRemarks()
  }, [dispatch])

  useEffect(() => {
    if (labSamples && labSamples.page) {
      setIsLoading(false)
    }
  },[labSamples])

  useEffect(() =>{
    if (createLabTestSampleSuccess && !error) {
      setIsEdit(false)
      validation.resetForm()
      setModal(false)
      setCurrentRemark(null)
      loadRemarks()
    }
  },[createLabTestSampleSuccess, error, labTestSample])

  useEffect(() =>{
    if (updateLabTestSampleSuccess && !error) {
      setIsEdit(false)
      validation.resetForm()
      setModal(false)
      setCurrentRemark(null)
      loadRemarks()
    }
  },[updateLabTestSampleSuccess, error, labTestSample])

  useEffect(() =>{
    if (deleteLabTestSampleSuccess && !error) {
      setIsEdit(false)
      validation.resetForm()
      setModal(false)
      setCurrentRemark(null)
      loadRemarks()
    }
  },[deleteLabTestSampleSuccess, error, labTestSample])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (currentRemark && currentRemark.id) || '',
      name: (currentRemark && currentRemark.name) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("you_need_provide_name")),
    }),
    onSubmit: (values) => {

      if (isEdit) {
        const updateForm = {
          id: currentRemark && currentRemark.id,
          name: values.name,
        }
        const id = currentRemark.id
        dispatch(onUpdateLabTestSample({ data: updateForm, id: id }))
      } else {
        const newForm = {
          name: values.name
        }
        dispatch(onCreateLabTestSample(newForm))
      }
    },
  })

  const handleOpen = () => {
    setIsEdit(false)
    toggle()
  }

  const handleSelectRemark = (arg) => {
    const selectedRemark = arg
    setCurrentRemark({
      id: selectedRemark.id,
      name: selectedRemark.name,
    })
    setIsEdit(true);
    toggle();
  }

  const onClickDelete = (remark) => {
    setCurrentRemark(remark);
    setDeleteModal(true);
  }

  function handleDeleteRemark() {
    if (currentRemark) {
      dispatch(onDeleteLabTestSample({ data: currentRemark, id: currentRemark.id }))
      loadRemarks()
      setDeleteModal(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        header: props.t("serial_number"),
        accessorKey: "index",
        style: "button",
        enableColumnFilter: false,
        cell: (cellProps) => {
          return <span>{cellProps.row.index + 1}</span>;
        },
      },
      {
        header: props.t("name"),
        accessorKey: "name",
        style: "button",
        enableColumnFilter: false,
      },
      {
        header: props.t("action"),
        style: "text-end p-4",
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                <ul className="list-inline hstack mb-0">
                  { (role === 'LAB_TECHNICIAN') || (permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("UPDATE_LAB_REMARKS"))) ?
                    <li className="list-inline-item edit" title="Edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => { const remarkData = cellProps.row.original; handleSelectRemark(remarkData); }}
                      >
                        <img src="../assets/images/edit.png" alt="" className="me-2"></img>
                      </Link>
                    </li>
                    :<></>
                  }
                  { (role === 'LAB_TECHNICIAN') || (permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("DELETE_LAB_REMARKS"))) ?
                    <li className="list-inline-item" title="Remove">
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => { const remarkData = cellProps.row.original; onClickDelete(remarkData); }}
                      >
                        <img src="../assets/images/delete.png" alt=""></img>
                      </Link>
                    </li>
                    :<></>
                  }
                </ul>
            </div>
          );
        },
      },
    ],
    [handleSelectRemark, role, permission, props]
  );

  return (
    <React.Fragment>
      {isLoading ?
        <GlobalLoader />
        :
        <>
          <DeleteModal show={deleteModal} onDeleteClick={handleDeleteRemark} onCloseClick={() =>setDeleteModal(false)} />

          <div className="container-fluide px-4">
              <div className="row list-patients d-flex  justify-content-between py-4">
                  <div className="col-lg-5">
                      <h6 className="ps-2"><i className="fa-solid fa-chevron-left"></i>&nbsp;<b>{props.t("samples")}</b></h6>
                  </div>
                  <div className="col-lg-2 new-mode-btn">
                    { (role === 'LAB_TECHNICIAN') || (permission && (permission.includes("ALL_PERMISSIONS") || permission.includes("ADD_LAB_SAMPLES"))) ?
                      <SinpleActionButton title={props.t("create_new_sample")} img="../assets/images/user.png" action={handleOpen} />
                      :<></>
                    }
                  </div>
              </div>

              <div className="tab-pane fade show active px-5" id="new-patient" role="tabpanel" aria-labelledby="new-patient-tab">
                  <div className="patients-list-content p-0 m-0 pt-1" id="new-patient">
                      <div className="container-fluide">
                          <div className="row pb-4 pe-5">
                            <div className="card table-card">
                              <TableContainer
                                columns={columns}
                                data={((labSamples && labSamples.items) || [])}
                                isGlobalFilter={true}
                                customPageSize={10}
                                divClass="table-card-ward"
                                tableClass="align-middle"
                                theadClass="thead-light table-light"
                                tableSize={ labSamples && labSamples.size }
                                tablePage={ labSamples && labSamples.page }
                                tableTotal= { labSamples && labSamples.total_items }
                                tableTotalPage= { labSamples && labSamples.total_page }
                                onNextPage= {loadRemarks}
                                onSearch= {searchRemark}
                                SearchPlaceholder={props.t("search_lab_sample")}
                              />
                            </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>

          <Modal id='showModal' size='lg' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
            <ModalHeader className="bg-light p-3" toggle={toggle}>
              {!!isEdit ? props.t("edit_sample") : props.t("create_new_sample")}
            </ModalHeader>
            <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}>
              <ModalBody>
                <input type="hidden" id="id-field" />
                <Row>
                  <Col xl={12} sm={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="wards-field"
                        className="form-label"
                      >
                        {props.t("name")}<span className='text-danger'>*</span>
                      </Label>
                      <Input
                        name="name"
                        id="name-field"
                        className="form-control"
                        autoComplete="off"
                        placeholder={props.t("name")}
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name ? true : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <div className='p-3'>
                <Row className="align-items-center">
                  <div className="col-sm">
                    <span className='text-danger align-middle'>* :{props.t("required_fields")}</span>
                  </div>
                  <div className="col-sm-auto">
                    <div className="hstack gap-2 justify-content-end">
                      <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); validation.resetForm(); setCurrentRemark(null); setIsEdit(false) }}> {props.t("close")} </button>
                      <button type="submit" className="btn btn-success"> {!!isEdit ? props.t("update") : props.t("save")} </button>
                    </div>
                  </div>
                </Row>
              </div>
            </Form>
          </Modal>
        </>
      }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Samples))