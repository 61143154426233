import React from 'react'
import moment from 'moment'
import { Modal, ModalHeader } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../../Common/withRouter'

const ThermalCashierContent = (props) => {
  return (
    <Modal id='showModal' isOpen={props.isOpen || false} toggle={props.toggleCashier} centered
        className="modal-dialog modal-dialog-centered modal-sm therm-lab-rep-modal"
    >
      <ModalHeader className="bg-light p-3" toggle={props.toggleCashier}>
      </ModalHeader>
      <div class="modal-body therm-lab-rep-body">
        <div class="therm-lab-rep-print">
            <div class="text-center">
                <img src={"../assets/images/HMS-Logo-1.png"} class="img-fluid" alt="HMS Logo" style={{ maxWidth: "50px" }} />
                <h4 class="therm-lab-rep-title">BIAKA HOSPITAL</h4>
                <p class="therm-lab-rep-subtitle m-0 p-0" style={{ color: "#45a049" }}>BOKOKO-BUEA</p>
                <div class="">
                    <p class="therm-lab-rep-email m-0 p-0" style={{ fontSize: "12px" }}>Email: St.veronicamedicalcenter@yahoo.com</p>
                    <p class="therm-lab-rep-contact mb-1 p-0">Contact No: +237 6 75 55 38 64</p>
                </div>
              </div>

            {props.section && props.section == "sale" ?
                <>
                    <div class="therm-lab-rep-info">
                        <table class="therm-lab-rep-table">
                            <tr>
                                <td>{props.t("bill_no")}.</td>
                                <td class="text fw-bold">{props.sale && props.sale.orderRef}</td>
                            </tr>
                            <tr>
                                <td>{props.t("patient_id")}.</td>
                                <td class="text fw-bold">{props.sale && props.sale.patient && props.sale.patient.patientRef}</td>
                            </tr>
                            <tr>
                                <td>{props.t("date")}</td>
                                <td class="text fw-bold">{props.sale && moment(props.sale.orderDate).format('DD / MM/ YYYY')}</td>
                            </tr>
                            <tr>
                                <td>{props.t("patient_name")}</td>
                                <td class="text fw-bold">{props.sale && props.sale.patient && props.sale.patient.firstName} {props.sale && props.sale.patient && props.sale.patient.lastName}</td>
                            </tr>
                            <tr>
                                <td>{props.t("address")}:</td>
                                <td class="text fw-bold">
                                    {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.address ? props.sale.patient.address.address : ""}
                                    {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.city ? ", "+props.sale.patient.address.city : ""}
                                    {props.sale && props.sale.patient && props.sale.patient.address && props.sale.patient.address.region ? ", "+props.sale.patient.address.region : ""}
                                </td>
                            </tr>
                        </table>
                    </div>

                    <table class="therm-lab-rep-table therm-lab-rep-border-top">
                        <tr>
                            <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                            <th style={{ width: "50%" }}>{props.t("ipd_service_details")}</th>
                            <th style={{ width: "10%" }}>{props.t("qty")}</th>
                            <th style={{ width: "10%" }}>{props.t("rate")}</th>
                            <th style={{ width: "10%" }}>{props.t("discount")}</th>
                            <th style={{ width: "10%" }}>{props.t("amount")}</th>
                        </tr>

                        {props.items && props.items.map((row, index) => (
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{row.label.substring(0, 17) + '...' }</td>
                                <td>{row.quantity}</td>
                                <td>{row.unitPrice}</td>
                                <td>{row.discount}</td>
                                <td>{row.rowTotal}</td>
                            </tr>
                        ))}
                    </table>

                    <table class="therm-lab-rep-table therm-lab-rep-border-top">
                        <tr>
                        </tr>
                        <tr>
                            <td>
                            </td>
                            <td class="text-end">
                                <b>{props.t("total_amount")}.</b>
                                : {props.totalAmount}
                            </td>
                        </tr>
                        <tr>
                            <td>
                            </td>
                            <td class="text-end">
                                <b>{props.t("amount_recieved")}.</b>
                                : {props.amountRecieved}
                            </td>
                        </tr>
                        <tr>
                            <td>
                            </td>
                            <td class="text-end">
                                {props.t("amount_return")}.
                                : {props.amountRecieved > props.totalAmount ? props.amountRecieved - props.totalAmount : 0}
                            </td>
                        </tr>
                    </table>
                </>
                : props.section && props.section == "payment" ?
                <>
                    <div class="therm-lab-rep-info">
                        <table class="therm-lab-rep-table">
                            <tr>
                                <td>{props.t("reg_no")}.</td>
                                <td class="text fw-bold">STVERON1234</td>
                            </tr>
                            <tr>
                                <td>{props.t("bill_no")}.</td>
                                <td class="text fw-bold">LAB-756</td>
                            </tr>
                            <tr>
                                <td>{props.t("date")}</td>
                                <td class="text fw-bold">09/02/2024</td>
                            </tr>
                            <tr>
                                <td>{props.t("patient_name")}</td>
                                <td class="text fw-bold">ALEX THOMAS</td>
                            </tr>
                            <tr>
                                <td>{props.t("pr_by")}</td>
                                <td class="text fw-bold">Dr. S. WATSON</td>
                            </tr>
                            <tr>
                                <td>{props.t("address")}:</td>
                                <td class="text fw-bold">BIPINDI, CAMEROON, AFRICA</td>
                            </tr>
                        </table>
                    </div>

                    <table class="therm-lab-rep-table therm-lab-rep-border-top">
                        <tr>
                            <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                            <th style={{ width: "50%" }}>{props.t("ipd_service_details")}</th>
                            <th style={{ width: "10%" }}>{props.t("qty")}</th>
                            <th style={{ width: "15%" }}>{props.t("rate")}</th>
                            <th style={{ width: "15%" }}>{props.t("amount")}</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Operation Charge</td>
                            <td>1</td>
                            <td>256.81</td>
                            <td>256.81</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Bed Charge (VIP WARD 02)</td>
                            <td>1</td>
                            <td>85.07</td>
                            <td>85.07</td>
                        </tr>
                    </table>

                    <table class="therm-lab-rep-table therm-lab-rep-border-top">
                        <tr>
                            <td style={{ width: "50%" }}>INCL TAXES DETAILS: 203.20x12%= 24.38, GST 0% AMT: 80.10</td>
                            <td style={{ width: "50%", textAlign: "right" }}>{props.t("vat")}: $ 12.19</td>
                        </tr>
                        <tr>
                            <td>E.& O.E. &nbsp; MATHEW  &nbsp;12:26pm</td>
                            <td class="text-end"><b>{props.t("total_amount")}.</b>: $ 308.01</td>
                        </tr>
                        <tr>
                            <td>USD: Three Hundred and Eight Only </td>
                            <td class="text-end">{props.t("net_amount")}. (R/O): $ 308.00</td>
                        </tr>
                    </table>
                </>
                :
                <>
                    <div class="therm-lab-rep-info">
                        <table class="therm-lab-rep-table">
                            <tr>
                                <td>{props.t("reg_no")}.</td>
                                <td class="text fw-bold">STVERON1234</td>
                            </tr>
                            <tr>
                                <td>{props.t("bill_no")}.</td>
                                <td class="text fw-bold">LAB-756</td>
                            </tr>
                            <tr>
                                <td>{props.t("date")}</td>
                                <td class="text fw-bold">09/02/2024</td>
                            </tr>
                            <tr>
                                <td>{props.t("patient_name")}</td>
                                <td class="text fw-bold">ALEX THOMAS</td>
                            </tr>
                            <tr>
                                <td>{props.t("pr_by")}</td>
                                <td class="text fw-bold">Dr. S. WATSON</td>
                            </tr>
                            <tr>
                                <td>{props.t("address")}:</td>
                                <td class="text fw-bold">BIPINDI, CAMEROON, AFRICA</td>
                            </tr>
                        </table>
                    </div>

                    <table class="therm-lab-rep-table therm-lab-rep-border-top">
                        <tr>
                            <th style={{ width: "10%" }}>{props.t("s_no")}</th>
                            <th style={{ width: "50%" }}>{props.t("ipd_service_details")}</th>
                            <th style={{ width: "10%" }}>{props.t("qty")}</th>
                            <th style={{ width: "15%" }}>{props.t("rate")}</th>
                            <th style={{ width: "15%" }}>{props.t("amount")}</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Operation Charge</td>
                            <td>1</td>
                            <td>256.81</td>
                            <td>256.81</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Bed Charge (VIP WARD 02)</td>
                            <td>1</td>
                            <td>85.07</td>
                            <td>85.07</td>
                        </tr>
                    </table>

                    <table class="therm-lab-rep-table therm-lab-rep-border-top">
                        <tr>
                            <td style={{ width: "50%" }}>INCL TAXES DETAILS: 203.20x12%= 24.38, GST 0% AMT: 80.10</td>
                            <td style={{ width: "50%", textAlign: "right" }}>{props.t("vat")}: $ 12.19</td>
                        </tr>
                        <tr>
                            <td>E.& O.E. &nbsp; MATHEW  &nbsp;12:26pm</td>
                            <td class="text-end"><b>{props.t("total_amount")}.</b>: $ 308.01</td>
                        </tr>
                        <tr>
                            <td>USD: Three Hundred and Eight Only </td>
                            <td class="text-end">{props.t("net_amount")}. (R/O): $ 308.00</td>
                        </tr>
                    </table>
                </>
            }
                <div class="therm-lab-rep-signature">
                    <p style={{ textAlign: "center", margin: "0", padding: "0" }}>We Care We Protect</p>
                    <p class="therm-lab-rep-booking" style={{ textAlign: "center", margin: "0", padding: "0", fontSize: "8px" }}>
                    Book Your Free Home Blood Sample Collection On:
                    <span class="text-primary">+237 6 75 55 38 64</span>
                    </p>
                </div>
          </div>
      </div>
    </Modal>
  )
}

export default withRouter(withTranslation()(ThermalCashierContent))
