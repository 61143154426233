import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Yup from "yup"
import moment from 'moment'
import { useFormik } from 'formik'
import { withTranslation } from 'react-i18next'
import withRouter from '../../../../components/Common/withRouter'
import {
    getBillDischarge as onGetBillDischarge,
    releasePatient as onReleasePatient
} from "../../../../slice/thunks"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from 'reselect'
import GlobalLoader from '../../../../components/Common/GlobalLoader'
import { Card, Form, FormFeedback, Modal, ModalBody, Row, Col,Label, Input, ModalHeader, Collapse, CardBody } from 'reactstrap'
import TableContainerWarehouse from '../../../../components/Common/TableContainerWarehouse'



const BillDischarge = (props) => {
    const dispatch = useDispatch()
    const history = useNavigate()
    const selectLayoutState = (state) => state.Outputs;
    const OutputsProperties = createSelector(
      selectLayoutState,
      (outputs) =>({
        billDischarge: outputs.billDischarge,
        release_patient_success: outputs.release_patient_success,
        error: outputs.error
      })
    )
    const { billDischarge, release_patient_success, error } = useSelector(OutputsProperties)
    const [isLoading, setIsLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [currentBill, setCurrentBill] = useState()



    const loadBillDischarge = useCallback((pageNumber, pageSize) => {
        if (pageNumber & pageSize) {
          dispatch(onGetBillDischarge({page: pageNumber, size: pageSize}))
        } else {
          dispatch(onGetBillDischarge({page: 1, size: 10}))
        }
    })

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            validation.resetForm()
        } else {
            setModal(true);
        }
    }, [modal])

    useEffect(() => {
        loadBillDischarge()
    },[dispatch])

    useEffect(() => {
        if (billDischarge && billDischarge.page > 0) {
            setIsLoading(false)
        }
    },[billDischarge])

    useEffect(() => {
        if (release_patient_success && !error) {
            loadBillDischarge()
            toggle()
          }
    },[release_patient_success])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
  
        initialValues: {
            releaseDate: (currentBill && currentBill.releaseDate) || '',
            description: (currentBill && currentBill.description) || '',
        },
        validationSchema: Yup.object({
            releaseDate: Yup.string().required(props.t("you_need_provide_date")),
            description: Yup.string().required(props.t("you_need_provide_description")),
        }),
        onSubmit: (values) => {
            const leaveForm = {
              description: values.description,
              releaseDate: values.releaseDate,
            }
            const id = currentBill.id
            dispatch(onReleasePatient({id: id, data: leaveForm}))
        },
    })

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleSelectPatient = useCallback((row) => {
        setCurrentBill(row)
        toggle()
    },[toggle])

    
    function capitalizeName(name) {
        if (!name) return '';
        return name
            .split(' ') 
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
            .join(' '); 
    }

    const columns = useMemo(
        () => [
            {
                header: props.t("patient_id"),
                style: "text-start",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span>#{ cell.row.original.patientId }</span>
                }
            },
            {
                header: props.t("patient_name"),
                style: "text-start",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span>#{ capitalizeName(cell.row.original.fullName) }</span>
                }
            },
            {
                header: props.t("ward_name"),
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span>{cell.row.original.wardName} </span>;
                },
            },
            {
                header: props.t("bed_no"),
                style: "text-start",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span>{ cell.row.original.bedNumber }</span>
                }
            },
            {
                header: props.t("visit_date"),
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span>
                        {moment(cell.row.original.visitDate).format('DD / MM/ YYYY')}
                    </span>;
                },
            },
            {
                header: props.t("bill"),
                style: "text-start",
                enableColumnFilter: false,
                cell: (cell) => {
                    return <span>{ cell.row.original.totalAmount }</span>
                }
            },
            {
                header: props.t("discharge_patient"),
                style: "text-center",
                cell: (cellProps) => {
                return (
                    <td>
                        <Link to="#" className="action d-flex justify-content-center px-2" onClick={() => handleSelectPatient() }>
                            <button className="btn send-btn px-4">
                                <i className="fa-solid fa-user text-white"></i>&nbsp;Discharge
                            </button>
                        </Link>
                    </td>
                    // <td className="action d-flex justify-content-center px-2" onClick={() => { const patient =  cellProps.row.original; handleSelectPatient(patient);}}>
                    //     <Link to="#" >
                    //         <i className="fa-solid fa-pencil"></i>
                    //     </Link>
                    // </td>
                );
                },
            },
        ],
        [props]
    );


  return (
    <React.Fragment>
        {!isLoading ?
            <GlobalLoader />
        :   <>
                <div className="patients-list py-4 px-2">
                    <div className="container">
                        <div className="patient-bill-and-discharge-sec">
                            <div className="container">
                                <div className="heading-sec">
                                    <h6>{props.t("patient_bill_discharge")}</h6>
                                </div>
                                <div className="patient-bill-and-discharge-main-sec pb-3">
                                    <div className="row">
                                            <TableContainerWarehouse
                                            columns={columns}
                                            data={((billDischarge && billDischarge.items) || [])}
                                            isGlobalFilter={false}
                                            customPageSize={10}
                                            divClass="card table-card table-warehouse table-responsive"
                                            tableClass="table"
                                            theadClass="thead-sec table-light"
                                            tdClass="td"
                                            tableSize={ billDischarge && billDischarge.size }
                                            tablePage={ billDischarge && billDischarge.page }
                                            tableTotal= { billDischarge && billDischarge.total_items }
                                            tableTotalPage= { billDischarge && billDischarge.total_page }
                                            onNextPage= {loadBillDischarge}
                                            onSearch= {loadBillDischarge}
                                            SearchPlaceholder={props.t("search_requisition")}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal id="showModal" size='md' isOpen={modal || false} toggle={toggle} backdrop={'static'} centered>
                    <ModalHeader className='bg-light p-3' toggle={toggle}>
                        {props.t("release_patients")}
                    </ModalHeader>
                    <Form className="tablelist-form" autoComplete="off" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <ModalBody>
                            <Row>
                                <Col xl={12} sm={12}>
                                    <div className='mb-2 mt-1'>
                                        <Label htmlFor="releaseDate-field" className="form-label">
                                            {props.t("release_date")}<span className='text-danger'>*</span>
                                        </Label><br/>
                                        <Input 
                                            name="releaseDate" id='releaseDate-field' className="form-control" type="date"
                                            validate={{ required: { value: true } }} max={getTodayDate()} onChange={validation.handleChange}
                                            onBlur={validation.handleBlur} value={validation.values.releaseDate || ""}
                                            autoComplete="off"releaseDate
                                            invalid={
                                                validation.touched.releaseDate && validation.errors.releaseDate ? true : false
                                            } 
                                        />
                                        {validation.touched.releaseDate && validation.errors.releaseDate ? (
                                            <FormFeedback type="invalid">{validation.errors.releaseDate}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col xl={12} sm={12}>
                                    <div>
                                        <Label htmlFor="description-field" className="form-label">
                                            {props.t("description")}
                                            <span className='text-danger'>*</span>
                                        </Label>
                                        <textarea 
                                            name="description"
                                            className="form-control" 
                                            id="description-field" 
                                            placeholder={props.t("enter_description")}
                                            rows={3}
                                            validate={{
                                                required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.description || ""}
                                            invalid={
                                                validation.touched.description && validation.errors.description ? true : false
                                            }
                                        >
                                        </textarea>
                                        {validation.touched.description && validation.errors.description ? (
                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <div className='p-3'>
                            <div className="col-sm-auto">
                                <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-secondary" onClick={() => { toggle() }}> {props.t("close")} </button>
                                <button type="submit" className="btn btn-success"> {props.t("save")}</button>
                                </div>
                            </div>
                            {/* </Row> */}
                        </div>
                    </Form>
                </Modal>
            </>
        }
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(BillDischarge))
